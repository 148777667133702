import React from 'react';
import { Card } from 'antd';
import { formatFullPrice } from '@/utils';
import './index.scss';

interface SummaryCardProps {
  title: string;
  value?: string;
  isCount?: boolean;
  subItems: Array<{
    label: string;
    value: string;
    showPlus?: boolean;
  }>;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  value,
  isCount,
  subItems
}) => {
  const formatValue = (value: string, showPlus?: boolean) => {
    if (isCount) return value;
    return formatFullPrice(value);
  };

  return (
    <Card className="summary-card">
      <div className="summary-card-header">
        <h3>{title}</h3>
        <div className="main-value">
          {formatValue(value)}
        </div>
      </div>
      <div className="summary-card-content">
        {subItems.map((item, index) => (
          <div key={index} className="sub-item">
            <div className="label">{item.label}</div>
            <div className="value">
              {formatValue(item.value, item.showPlus)}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default React.memo(SummaryCard);