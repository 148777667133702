import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs';
import { TimePicker } from 'antd'
import { timeStringToMilliseconds, secondsToTimeString } from '@/utils'


type TimeRangePickerProps = {
  value?: number | null,
  onChange?: (value: number) => void,
  disabled?: boolean,
  format?: 'hh:mm A' | 'HH:mm',
  use12Hours?: boolean,
  endTimeSet?: boolean,
}

const PepprTimePicker = (props: TimeRangePickerProps) => {
  const { value = null, onChange, disabled = false, format = 'hh:mm A', use12Hours = true, } = props;

  const handleChangeHours = (timeString) => {
    onChange(timeStringToMilliseconds(timeString, use12Hours));
  }
  return (
    <TimePicker
      value={value !== null ? dayjs(secondsToTimeString(value, use12Hours), format) : null}
      disabled={disabled}
      inputReadOnly
      format={format}
      onChange={ (value, timeString) => handleChangeHours(timeString) }
      allowClear={ false }
      needConfirm={ false }
      use12Hours={use12Hours}
    />
  );
}
export default PepprTimePicker;