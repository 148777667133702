import React, { useEffect, useState } from 'react';
import { Table, Flex, Input, Popover, Button, Modal, Select, message } from 'antd'
import { globalState } from '@/stores';
import useGetState from '@/hooks/useGetState';
import { SearchOutlined, PieChartOutlined } from '@ant-design/icons';
import Columns from '@/Components/Columns/Columns'
import { queryMenuItemList, queryMenuGroupList, archiveMenu, restoreMenuArchive, viewArchiveUsage } from '@/request/Menus/MenuDatabase'
import Edit from '../component/Edit'
import ArchivedChange from '../component/ArchivedChange'
import UsagePop from '../component/UsagePop'
import { formatTime, secondsToTimeString, debounce } from '@/utils/index'
import { IPage, IFilter, ISorter, IGetList, IMenuItemListParams, IComponentPage, IOrderBy, IMenuItem } from './types';
import { DAYS_MAP } from '../enum'

export default function Item ({ isActive, archiveType, sticky }) {
  const { i18n } = globalState;
  const [activeTitle, setActiveTitle] = useState([])
  const [tableData, setTableData] = useState([])
  const [popTableData, setPopTableData] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchArchived, setSearchArchived] = useState<boolean[]>([true, false])

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [subMenuList, setSubMenuList] = useState<any>([])
  const [selectSubMenu, setSelectSubmenu] = useState<string>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [archived, setArchived] = useState<boolean>()
  const [popLoading, setPopLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [sortedInfo, setSortedInfo] = useState<ISorter>({});

  const tableColumns = [{
    dataIndex: 'name',
    key: 'name',
    title: i18n.t('menuDatabaseList_pc_name'),
    width: 200,
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
    // render: (name: string, row: IMenuItem) => {
    //   return <a style={{ color: '#252525' }}>{name}</a>
    // }
  }, {
    dataIndex: 'uuid',
    key: 'uuid',
    title: i18n.t('menuDatabaseList_pc_id'),
    width: 100,
    hide: true,
  }, {
    dataIndex: 'updated',
    key: 'updated',
    title: i18n.t('pc_menudatabase_updated'),
    width: 100,
    hide: true,
    render: (data) => {
      return data && formatTime(data, 'MM/dd/yyyy')
    }
  }, {
    dataIndex: 'basePrice',
    key: 'basePrice',
    title: i18n?.t('price'),
    width: 100,
    render: (data) => {
      if (data === null) return '-';
      const price = typeof data === 'number' ? data.toFixed(2) : data;
      return `$${price}`;
    }
  }, {
    dataIndex: 'priceConfig',
    key: 'priceConfig',
    title: 'Time-based Price Override',
    width: 370,
    render: (data) => {
      if (data && data.usePriceConfig) {
        return data.priceDetails?.map((item, index) => {
          const days = item.days.map(item => DAYS_MAP[item]).join(',')
          return <div key={index}>{`$${Number(item.value).toFixed(2)},${secondsToTimeString(item.startTime, true)}-${secondsToTimeString(item.endTime, true)},${days}`}</div>
        })
      } else {
        return '-'
      }
    }
  }, {
    dataIndex: 'taxes',
    key: 'taxes',
    title: i18n?.t('taxes'),
    width: 300,
    render: (data) => {
      return (data ?? []).join(', ') || '-'
    }
  }, {
    dataIndex: 'preStations',
    key: 'preStations',
    title: i18n?.t('print_gearOpening_bill_pc_prepStation'),
    width: 100,
    render: (data) => {
      return (data ?? []).join(', ') || '-'
    }
  }, {
    dataIndex: 'archived',
    key: 'archived',
    title: i18n.t('menuDatabaseList_pc_status'),
    width: 100,
    filters: [
      { text: i18n.t('active'), value: false },
      { text: i18n.t('archived'), value: true },
    ],
    render: (data, row: IMenuItem) => <ArchivedChange data={data} onChange={(val) => editStatus(val, row)} />
  },
  {
    dataIndex: 'reason',
    key: 'reason',
    title: i18n.t('menuDatabaseList_pc_actions'),
    width: 100,
    render: (data: string, row: IMenuItem) => {
      return (
        row.archived ? <Button type='link' disabled icon={<i className="iconfont icon-ellipsis actions-btn" />}></Button> :
          <Popover
            content={<Button icon={<PieChartOutlined />} onClick={() => getUsage(row)} type="link">{i18n.t('usage')}</Button>}
          >
            <Button type='link' icon={<i className="iconfont icon-ellipsis actions-btn" />}></Button>
          </Popover>
      )
    }
  }]
  const [open, setOpen] = useState(false);
  const [openUsagePop, setOpenUsagePop] = useState(false);
  const [itemName, setItemName] = useState('');
  const [usageData, setUsageData] = useState([]);

  // const gotoEdit = (uuid) => {
  //   navigate(`/menus/createItem?itemId=${uuid}`)
  // };
  const showModal = (val: boolean) => {
    if (!val) {
      return
    }
    setOpen(true);
  };

  const hideModal = () => {
    setPopTableData([])
    setPopLoading(false)
    setOpen(false);
  };
  const okModal = async () => {
    const list = popTableData.map(item => {
      return {
        archiveUUID: item.uuid,
        archiveType: archiveType
      }
    })
    setPopLoading(true)
    let res: any
    if (archived) {
      res = await archiveMenu({ params: { archiveList: list } })
    } else {
      res = await restoreMenuArchive({ params: { restoreArchive: list } })
    }
    if (res.code == 0) {
      getMenuList({})
    }
    setPopLoading(false)
    hideModal()
  };
  const [pager, setPager, getPager] = useGetState<IComponentPage>({
    current: 1, pageSize: 20, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const editStatus = (val, row: IMenuItem) => {
    if (val === row.archived) {
      return
    }
    setPopTableData([row])
    setArchived(val)
    showModal(true)
  }
  const getUsage = async (row: IMenuItem) => {
    setItemName(row.name)
    await getViewArchiveUsage(row)
    setOpenUsagePop(true)
  }
  const getViewArchiveUsage = async (row: IMenuItem) => {
    const archive = {
      archiveType: archiveType,
      archiveUUID: row.uuid
    }
    const res = await viewArchiveUsage({ params: { archive } })
    if (res.code === 0) {
      setUsageData(res.data)
    }
  }
  const onChangeColumns = (list) => {
    setActiveTitle(list)
  }
  const handleTableChange = (pagination: IPage, filters: IFilter, sorter: ISorter) => {
    const page = {
      ...pager,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }
    const { archived = null } = filters;
    setSearchArchived(archived)
    setSortedInfo(sorter);
    getMenuList({ page, filters, sorter })
  }
  const changeSearch = (e: any) => {
    debounceSearch(e.target.value)
  }
  const onPressEnter = (value) => {
    const page = {
      ...pager,
      current: 1,
    }
    setSearchValue(value)
    getMenuList({ value, page })
  }
  const debounceSearch = debounce(onPressEnter, 500);
  const changeEdit = (val) => {
    const arr = selectedRows.filter((item: any) => {
      return item.archived !== val
    })
    setArchived(val)
    setPopTableData(arr)
    showModal(arr.length > 0)
  }
  const getMenuList = async ({ value = null, page = pager, filters = {}, sorter = {} }: IGetList) => {
    const { archived = searchArchived } = filters;
    const { field, order } = sorter;
    const orderBy: IOrderBy = field
      ? {
        field,
        order: order ? order === 'ascend' ? 'asc' : 'desc' : null,
      }
      : {
        field: 'name',
        order: null,
      };
    const params: IMenuItemListParams = {
      current: page.current,
      pageSize: page.pageSize,
      orderBy,
      request: {
        keyWord: value !== null ? value : searchValue,
        archived: archived === null || archived.length > 1 ? null : archived[0],
        uuid: selectSubMenu,
      },
    };
    try {
      setLoading(true);
      const data = await queryMenuItemList(params);
      if (data.code === 0) {
        setTableData(data.data);
        setSelectedRows([]);
        setSelectedRowKeys([]);
        setPager((prevPager) => ({
          ...prevPager,
          pageSize: page.pageSize,
          total: data.total,
          current: data.current,
        }));
      }
    } catch (error) {
      // 处理可能的错误
      console.error('Failed to fetch menu list:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys)
  };
  const getSubMenuList = async () => {
    const params = {
      current: 1,
      pageSize: 9999,
      orderBy: null,
      request: {
        keyWord: null,
        archived: null,
      }
    }
    setLoading(true)
    const res = await queryMenuGroupList(params)
    setLoading(false)
    if (res.code === 0) {
      const list = res.data.map(item => ({
        value: item.uuid,
        label: item.name
      }))
      setSubMenuList(list)
    }
  }
  const selectSubmenu = (value) => {
    setSelectSubmenu(value)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    if (isActive) {
      setActiveTitle(tableColumns.map(item => {
        if (item.hide !== true) return item.dataIndex
      }))
      getMenuList({})
      getSubMenuList()
    }
  }, [isActive])
  useEffect(() => {
    getMenuList({})
  }, [selectSubMenu])
  return (
    <div>
      <Flex className="top">
        <Flex align="center" flex="1" gap="large" justify="space-between" className="search-box">
          <Flex gap="middle">
            <Input
              prefix={<SearchOutlined style={{ color: '#BEBEBE' }} />}
              className="search"
              placeholder={`${i18n.t('search')} ${i18n.t('menuDatabaseList_pc_items')}`}
              onChange={changeSearch}
              maxLength={100}
              allowClear
            />
            <Select
              onChange={selectSubmenu}
              allowClear
              style={{ width: '300px' }}
              options={subMenuList}
              placeholder={`${i18n.t('orderList_pc_all')} ${i18n.t('menuDatabaseList_pc_menuGroups')}`}
            ></Select>
          </Flex>
          <Flex gap="middle">
            <Edit onChange={changeEdit} disable={selectedRows.length == 0}></Edit>
            <Columns value={activeTitle} options={tableColumns.map(item => ({ label: item.title, value: item.dataIndex, hide: item.hide ? false : item.hide }))} onChange={onChangeColumns} />
          </Flex>
        </Flex>
      </Flex>
      <Table
        columns={tableColumns.filter(x => {
          return activeTitle.includes(x.dataIndex)
        })}
        rowSelection={rowSelection}
        dataSource={tableData}
        pagination={pager}
        onChange={handleTableChange}
        loading={loading}
        rowKey="uuid"
        scroll={{ y: sticky }}
      />
      <Modal
        className="ArchivePop"
        title={archived ? i18n.t('archive') + ' item?' : i18n.t('active') + ' item?'}
        open={open}
        onOk={okModal}
        onCancel={hideModal}
        okText={i18n.t('confirm')}
        confirmLoading={popLoading}
        cancelText={i18n.t('cancel')}
      >
        <p className="text">{archived ? i18n.t('pc_archivepop') : i18n.t('pc_Activepop')}</p>
        <Table
          columns={[{
            dataIndex: 'name',
            key: 'name',
            title: i18n.t('menuDatabaseList_pc_name'),
          }]}
          dataSource={popTableData}
          pagination={false}
          scroll={{ y: sticky }}
        />
      </Modal>
      <UsagePop
        itemName={itemName}
        data={usageData}
        open={openUsagePop}
        subMenu
        gotoDetail
        hidePop={(rel: boolean) => setOpenUsagePop(rel)}
      />
    </div>
  )
}