import React from 'react';
import { Table } from 'antd';
import type { FilterItem } from '@/Components/FilterContainer/types';
import { globalState } from '@/stores';
import type { TableColumns, Pagination, TableSorter } from '@/types/table';
import { TableToolbar } from '@/Components/TableToolbar';

import './index.scss';
interface GenericTablePageProps<T> {
  columns: TableColumns<T>;
  filters: FilterItem[];
  initialFilterValues?: Record<string, any>;
  tableProps: {
    data: T[];
    loading: boolean;
    pagination: any;
    showColumnsList: string[];
    isShowEmptyTable: boolean;
  };
  handlers: {
    onFilterChange?: (values: Record<string, any>) => void;
    onRefresh?: () => void;
    onChangeColumns?: (list: string[]) => void;
    onTableChange: (pagination: Pagination, filters:  Record<string, any>, sorter: TableSorter) => void;
  };
  extraActions?: React.ReactNode;
  exportOptions?: {
    exportApi?: string;
    exportParams?: Record<string, any>
  }
}

/**
 * 通用表格
 * @param columns 列配置
 * @param filters 过滤器
 * @param tableProps 表格属性
 * @param handlers 处理函数
 * @param extraActions 额外操作
 */
export const GenericTablePage = <T, >({
  columns,
  filters,
  initialFilterValues,
  exportOptions,
  tableProps,
  handlers,
  extraActions
}: GenericTablePageProps<T>) => {
  const { i18n } = globalState;
  const {
    data,
    loading,
    pagination,
    showColumnsList,
    isShowEmptyTable
  } = tableProps;

  const {
    onFilterChange,
    onRefresh,
    onChangeColumns,
    onTableChange
  } = handlers;

  return (
    <div className='table-page'>
      <TableToolbar
        filters={filters}
        initialFilterValues={initialFilterValues}
        columns={columns}
        showColumnsList={showColumnsList}
        onFilterChange={onFilterChange}
        onRefresh={onRefresh}
        onChangeColumns={onChangeColumns}
        extraActions={extraActions}
        exportOptions={exportOptions}
      />
      <Table
        columns={columns.filter(x => showColumnsList.includes(x.dataIndex))}
        dataSource={isShowEmptyTable ? [] : data}
        pagination={{
          ...pagination,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => i18n.t('table_total_items', { num: total }),
        }}
        scroll={ { x: '100%' } }
        loading={loading}
        onChange={onTableChange}
      />
    </div>
  );
}

export default React.memo(GenericTablePage);