import { MenuProps } from 'antd';

export const defaultItems: MenuProps['items'] = [
  {
    key: 'XLS',
    label: '.xls',
  },
  {
    key: 'CSV',
    label: '.csv',
  },
];


export const defaultType = 'button'


export const defaultButtonName = 'Export'


export const defaultDisabled = false


export const defaultWidth = 14.5