import React, { useState } from 'react'
import TextLineNew from '../component/TextLineNew';
import DashedLine from '../component/DashedLine';
import './index.scss'
const CashPaymentDetail = () => {
  return (
    <div className='cash-payment-detail'>
      <div className="cash-payment-detail-item">
        <TextLineNew
          text="CASH PAYMENT DETAILS"
          style={{
            fontWeight: 'bold',
          }}
        />
      </div>
      <DashedLine gap={{ margin: '8px 0px' }} />
      <div className="cash-payment-detail-check">
        <TextLineNew
          text="Check #001"
          style={{
            fontWeight: 'bold',
          }}
        />
        <div className="cash-payment-detail-check-info">
          <TextLineNew text="Cash paid" />
          <TextLineNew text="$5.00" />
        </div>
        <div className="cash-payment-detail-check-info">
          <TextLineNew text="Check total" />
          <TextLineNew text="$10.00" />
        </div>
      </div>
      <div className="cash-payment-detail-check">
        <TextLineNew
          text="Check #002"
          style={{
            fontWeight: 'bold',
          }}
        />
        <div className="cash-payment-detail-check-info">
          <TextLineNew text="Cash paid" />
          <TextLineNew text="$5.00" />
        </div>
        <div className="cash-payment-detail-check-info">
          <TextLineNew text="Check total" />
          <TextLineNew text="$5.00" />
        </div>
      </div>
    </div>
  );
}
export default CashPaymentDetail