import React from 'react';
import useRouteListener from '@/hooks/useRouteListener';
import AmplitudeWrapper from '@/utils/amplitudeWrapper';

interface RouteListenerProps {
  amplitude: AmplitudeWrapper;
}

const RouteListener: React.FC<RouteListenerProps> = ({ amplitude }) => {
  useRouteListener(amplitude);

  return null; // 不需要渲染任何UI
};

export default RouteListener;
