import { useTable } from '@/hooks/useTable';
import { useState, useEffect, useCallback } from 'react';
import type { TableColumns, Pagination, TableSorter, TableResponse } from '@/types/table';
import { formatDate } from '@/utils';

interface UseGenericTableProps<T> {
  apiUrl: string;
  defaultSorter?: Sorter;
  sorterMap?: Record<string, string>;
  columns: TableColumns<T>;
  defaultFilters?: Record<string, any>;
}

interface Sorter {
  field: string | null;
  order: 'ASC' | 'DESC' | null;
}

/**
 * 通用表格
 * @param apiUrl 请求地址
 * @param defaultSorter 默认排序
 * @param sorterMap 排序映射
 * @param columns 列配置
 */
export const useGenericTable = <T>({
  apiUrl,
  defaultSorter,
  sorterMap = {},
  columns,
  defaultFilters
}: UseGenericTableProps<T>) => {
  const [showColumnsList, setShowColumnsList] = useState<string[]>([]);
  const [isShowEmptyTable, setIsShowEmptyTable] = useState(false);

  const {
    data,
    loading,
    pagination,
    params,
    handleTableChange: originalHandleTableChange,
    setParams,
    refresh
  } = useTable<T>(apiUrl, {
    defaultSorter,
    defaultFilters
  });

  useEffect(() => {
    setShowColumnsList(columns.filter(x => !x.hide).map(x => x.dataIndex as string));
  }, [columns]);

  const handleTableChange = (
    newPagination: Pagination,
    filters: Record<string, any>,
    sorter: TableSorter
  ) => {
    originalHandleTableChange(newPagination, filters, {
      ...sorter,
      field: sorterMap[sorter.field] || sorter.field,
    });
  };

  const handleChangeColumns = (list: string[]) => {
    setIsShowEmptyTable(list.length === 0);
    setShowColumnsList(list);
  };

  const setFilters = useCallback((filters: Record<string, any>) => {
    setParams({
      request: filters
    });
  }, [setParams]);

  return {
    data,
    loading,
    pagination,
    params,
    setParams: setFilters,
    refresh,
    handleTableChange,
    showColumnsList,
    isShowEmptyTable,
    handleChangeColumns
  };
};