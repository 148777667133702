import { useState, useEffect, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import type { FilterItem, SelectOption, SelectFilterItem } from './types';

export const useFilterOptions = (filters: FilterItem[]) => {
  const [optionsMap, setOptionsMap] = useState<Record<string, SelectOption[]>>({});
  const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({});
  const [errorMap, setErrorMap] = useState<Record<string, string>>({});

  // 使用 useMemo 缓存需要获取选项的过滤器
  const selectFilters = useMemo(() =>
    filters.filter(filter => filter.type === 'select' && filter.fetchOptions),
  [filters]
  );

  // 使用 useCallback 缓存获取选项的函数
  const fetchOptions = useCallback(async (filter: FilterItem) => {
    if (filter.type !== 'select') return;
    const selectFilter = filter as SelectFilterItem;
    if (!selectFilter.fetchOptions) return;

    setLoadingMap(prev => ({ ...prev, [filter.name]: true }));
    setErrorMap(prev => ({ ...prev, [filter.name]: '' }));

    try {
      const options = await selectFilter.fetchOptions();
      setOptionsMap(prev => ({
        ...prev,
        [filter.name]: options,
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : '获取选项失败';
      setErrorMap(prev => ({ ...prev, [filter.name]: errorMessage }));
      console.error(`Failed to fetch options for ${filter.name}:`, error);
    } finally {
      setLoadingMap(prev => ({ ...prev, [filter.name]: false }));
    }
  }, []);

  // 防抖处理
  const debouncedFetchOptions = useMemo(
    () => debounce(fetchOptions, 300),
    [fetchOptions]
  );

  // 刷新特定过滤器的选项
  const refreshOptions = useCallback((filterName: string) => {
    const filter = filters.find(f => f.name === filterName) as SelectFilterItem;
    if (filter?.type === 'select' && filter.fetchOptions) {
      fetchOptions(filter);
    }
  }, [filters, fetchOptions]);

  useEffect(() => {
    const fetchAllOptions = async () => {
      await Promise.all(selectFilters.map(filter => fetchOptions(filter)));
    };

    fetchAllOptions();

    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [selectFilters, debouncedFetchOptions]);

  return {
    optionsMap,
    loadingMap,
    errorMap,
    refreshOptions,
    debouncedFetchOptions,
  };
};