/**
 * 函数解释：更新左侧拖拽后,实时根须右侧预览顺序
 *
*/

import printOutDecorationStore from '../store';
import { toJS } from 'mobx';

export const updatePreviewOrderAfterLeftDrag = (activeIdAndOverId) => {
  const previewData = toJS(printOutDecorationStore.getPreviewData);
  const { activeId, overId } = activeIdAndOverId;

  // 找到 activeId 和 overId 对应的索引
  const activeIndex = previewData.findIndex(item => item.id === activeId);
  const overIndex = previewData.findIndex(item => item.id === overId);

  if (activeIndex !== -1 && overIndex !== -1) {
    // 取出 activeId 对应的对象
    const [activeItem] = previewData.splice(activeIndex, 1);
    // 将 activeId 对应的对象放到 overId 后面
    previewData.splice(overIndex, 0, activeItem);
  }

  printOutDecorationStore.savePreviewData(previewData);
};
