import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd'
import { globalState } from '@/stores';
import Menus from './Menus';
import Item from './Item';
import SubMenus from './SubMenus';
import ModifierGroups from './ModifierGroups';
import ModifiersOptions from './ModifiersOptions';
import { getQuerys } from '@/utils';
import { useNavigate } from 'react-router-dom';
import './index.scss'
export default function MenuDatabase (props) {
  const { i18n } = globalState;
  const [sticky, setSticky] = useState(500);
  const [tabValue, setTabValue] = useState('3')
  const tabsItems = [
    {
      key: '1',
      label: i18n.t('menuDatabaseList_pc_menu'),
      children: <Menus sticky={sticky} isActive={tabValue == '1'} archiveType="Menu"/>
    },
    {
      key: '2',
      label: i18n.t('menuDatabaseList_pc_menuGroups'),
      children: <SubMenus sticky={sticky} isActive={tabValue == '2'} archiveType="MenuGroup" />
    },
    {
      key: '3',
      label: i18n.t('menuDatabaseList_pc_items'),
      children: <Item sticky={sticky} isActive={tabValue == '3'} archiveType="MenuItem" />
    },
    {
      key: '4',
      label: i18n.t('menuDatabaseList_pc_modifierGroups'),
      children: <ModifierGroups sticky={sticky} isActive={tabValue == '4'} archiveType="MenuOptionGroup"/>
    },
    {
      key: '5',
      label: i18n.t('menuDatabaseList_pc_modifiers'),
      children: <ModifiersOptions sticky={sticky} isActive={tabValue == '5'} archiveType="MenuOption" />
    }
  ]
  const navigate = useNavigate();
  const changeTab = (value) => {
    setTabValue(value);
    navigate(`?tab=${value}`, { replace: true }); // 点击tab切换时，url参数也切换
  }
  const tabBarExtraContent = {
    left: <div className="main-tab-title">{ i18n.t('overseas_peppr_entrance_menu_menuDatabase') }</div>,
  }

  const handleScroll = () => {
    const offset = window.innerHeight - 315;
    setSticky(offset);
  };
  useEffect(()=> {
    getQuerys(location.href).tab && setTabValue(getQuerys(location.href).tab)
    handleScroll()
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('resize', handleScroll);
    };
  }, [])
  return (
    <div className="menuDatabase">
      <Tabs tabBarExtraContent={ tabBarExtraContent } activeKey={tabValue} onChange={changeTab} items={tabsItems}></Tabs>
      {/* <Settings tabValue={tabValue} /> */}
    </div>
  )
}