import React, { useState, useEffect } from 'react';
import { Modal, Typography, Select, InputNumber, Space, Col, Row, Flex } from 'antd';
import { ADJUST_OPERATE_TYPE } from './interface';
import './index.scss';
import { CloseOutlined } from '@ant-design/icons';
import { globalState } from '@/stores';

const { Option } = Select;
const { Text } = Typography;

interface IProps {
  open: boolean;
  currentBalance?: number;
  onClose: () => void;
  onConfirm: (adjustType: ADJUST_OPERATE_TYPE, amount: string) => void;
  confirmLoading: boolean;
}

const MAX_SINGLE_ADJUST_AMOUNT = 100000; // 单次调整上限
const currencySymbol = '$';

const AdjustBalanceModal: React.FC<IProps> = ({ open, currentBalance = 0, onClose, onConfirm, confirmLoading }) => {
  const { i18n } = globalState
  const maxReductionAmount = Math.min(currentBalance, MAX_SINGLE_ADJUST_AMOUNT); // 最多可减去的值
  const [adjustOperate, setAdjustOperate] = useState<ADJUST_OPERATE_TYPE>(ADJUST_OPERATE_TYPE.increment);
  const [adjustmentAmount, setAdjustmentAmount] = useState<number>(0);
  const [newBalance, setNewBalance] = useState<number>(currentBalance);
  // 确保精度为两位小数
  const updateAdjustmentAmount = (num: number) => {
    setAdjustmentAmount(Number(num.toFixed(2)))
  }
  const updateNewBalance = (num: number) => {
    setNewBalance(Number(num.toFixed(2)))
  }
  useEffect(() => {
    const newBalance = operations[adjustOperate](currentBalance, adjustmentAmount)
    if (newBalance < 0) {
      updateNewBalance(currentBalance)
      updateAdjustmentAmount(0)
    } else {
      updateNewBalance(operations[adjustOperate](currentBalance, adjustmentAmount));
    }
  }, [adjustOperate, adjustmentAmount, currentBalance]);

  const operations = {
    [ADJUST_OPERATE_TYPE.increment]: (balance: number, amount: number) => balance + amount,
    [ADJUST_OPERATE_TYPE.decrement]: (balance: number, amount: number) => balance - amount,
  };

  const handleAdjustOperatorChange = (value: ADJUST_OPERATE_TYPE) => {
    setAdjustOperate(value);
  };

  const handleAdjustAmountChange = (value: number) => {
    const limit = adjustOperate === ADJUST_OPERATE_TYPE.increment ? MAX_SINGLE_ADJUST_AMOUNT : maxReductionAmount;
    const adjustAmount = Math.min(value, limit);
    updateAdjustmentAmount(adjustAmount);
  };

  const handleNewBalanceChange = (value: number) => {
    const gap = Math.abs(value - currentBalance);
    const operationType = value >= currentBalance ? ADJUST_OPERATE_TYPE.increment : ADJUST_OPERATE_TYPE.decrement;
    setAdjustOperate(operationType);
    updateAdjustmentAmount(Math.min(gap, operationType === ADJUST_OPERATE_TYPE.increment ? MAX_SINGLE_ADJUST_AMOUNT : maxReductionAmount));
  };

  const selectBefore = (
    <Select value={adjustOperate} onChange={handleAdjustOperatorChange}>
      <Option value={ADJUST_OPERATE_TYPE.increment}>+</Option>
      <Option value={ADJUST_OPERATE_TYPE.decrement}>-</Option>
    </Select>
  );

  const handleConfirm = () => {
    if (adjustmentAmount === 0) {
      onClose();
    } else {
      onConfirm(adjustOperate, String(adjustmentAmount));
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      okText={i18n.t('save')}
      cancelText={i18n.t('cancel')}
      onOk={handleConfirm}
      destroyOnClose = {true}
      confirmLoading={confirmLoading}
      okButtonProps={{ style: { padding: '0 16px', minWidth: 0 } }}
      cancelButtonProps={{ disabled: confirmLoading, style: { padding: '0 16px', minWidth: 0 } }}
      closeIcon={null}
      styles={{ content: { padding: '16px 24px' } }}
    >
      <div>
        <Flex justify='space-between' align='center' style={{ marginBottom:'16px' }}>
          <Text strong className='heading2'>{i18n.t('PC_GIFTCARD_ADJUST_BALANCE')}</Text>
          <CloseOutlined style={{ width: '32px', height: '32px', cursor: 'pointer' }} onClick={onClose} />
        </Flex>
        <Space direction="vertical" size={0}>
          <Text strong className='heading5'>{i18n.t('PC_GIFTCARD_CURRENT_BALANCE')}</Text>
          <Text>{currencySymbol + currentBalance}</Text>
        </Space>
        <Row style={{ paddingTop: '16px' }}>
          <Col span={12} style={{ paddingRight: '16px' }}>
            <Text strong className='heading5'>{i18n.t('PC_GIFTCARD_ADJUST_BALANCE')}</Text>
            <InputNumber
              prefix={currencySymbol}
              min={0}
              style={{ width: '100%' }}
              addonBefore={selectBefore}
              value={adjustmentAmount}
              onChange={handleAdjustAmountChange}
            />
          </Col>
          <Col span={12}>
            <Text strong className='heading5'>{i18n.t('PC_GIFTCARD_NEW_BALANCE')}</Text>
            <InputNumber
              min={0}
              prefix={currencySymbol}
              style={{ width: '100%' }}
              value={newBalance}
              onChange={handleNewBalanceChange}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AdjustBalanceModal;
