import React, { useEffect, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Table, Space, Form, Radio, Modal, Input, message, Spin, Checkbox } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { globalState } from '@/stores';
import {
  ApiQueryRemarkVO,
  ApiChangeSwitch,
  ApiBatchSaveNotes,
  ApiSortNotes,
  ApiRemarkEdit,
  ApiDeleteAndReorder
} from '@/request/api'
import { toRes } from '@/utils';

interface DataType {
    id: number;
    createdBy: string;
    lastModifiedBy: string;
    displayOrder: number;
    createdTime: number;
    content: string
}

interface modalTextArea {
  content: string;
  id: number;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}
const Row = React.memo(({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            // @ts-expect-error ignore
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
});

export default function Settings ({ tabValue }) {
  const { label:displayName, key:value } = tabValue
  const { i18n } = globalState;
  const [dataSource, setDataSource] = useState([]);
  const columns: ColumnsType<DataType> = [
    {
      key: 'sort',
      width: 40,
    },
    {
      title: i18n.t('frontOfHouse_reason'),
      dataIndex: 'content',
    },
    {
      title: i18n.t('table_actions'),
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Button type="link" icon={<EditOutlined />} onClick={() => showModal(record)}></Button>
          <Button type="link" icon={<DeleteOutlined />} onClick={() => deletReorder(record.id)}></Button>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalText, setModalText] = useState<modalTextArea>({ content: '', id: null });
  const { TextArea } = Input;

  const onChangeTextArea = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setModalText(prevState => ({ ...prevState, content: e.target.value }))
  };
  const showModal = (record) => {
    setOpen(true);
    record.content && setModalText({ content: record.content, id: record.id })
  };
  const handleOk = async () => {
    const modalInput = modalText.content.trim()
    if (modalInput === '') {
      setModalText(prevState => ({
        ...prevState,
        content: ''
      }))
      return
    }
    setConfirmLoading(true); // 开始加载
    let params = {};
    let result = null;
    let error = null;
    if (modalText.id !== null) {
      params = modalText;
      [error, result] = await toRes(ApiRemarkEdit({ params }));
    } else {
      params = {
        notes: [{
          content: modalInput,
          noteType: {
            displayName,
            value
          }
        }]
      };
      [error, result] = await toRes(ApiBatchSaveNotes({ params }));
    }
    setConfirmLoading(false);
    if (!error) {
      setOpen(false);
      setModalText({ content: '', id: null });
      await getData();
      message.success('success', 2);
    }
  };


  const handleCancel = () => {
    setModalText({ content: '', id: null });
    setOpen(false);
  };
  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((i) => i.id === active.id);
      const overIndex = dataSource.findIndex((i) => i.id === over?.id);
      setDataSource((previous) => {
        const newDataSource = arrayMove(previous, activeIndex, overIndex);
        sortNotes(newDataSource[activeIndex], newDataSource[overIndex].displayOrder);
        return newDataSource;
      });
    }
  };
  const sortNotes = async (active, displayOrder) => {
    const params = {
      note: {
        ...active
      },
      displayOrder
    };
    const [error] = await toRes(ApiSortNotes({ params }));
    if (!error) {
      message.success('success', 2);
      getData();
    }
  }
  const getData = async () => {
    setLoading(true)
    const params = {
      type: {
        displayName,
        value
      }
    }

    try {
      const [error, data] = await toRes(ApiQueryRemarkVO({ params }));
      !error && form.setFieldsValue({
        value: data?.switchType?.chooseType?.value || '1',
      });
      setDataSource(data?.remarkList || [])
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }
  const deletReorder = async (id:number) => {
    const [error, data] = await toRes(ApiDeleteAndReorder({ params:{ id } }))
    !error && await getData()
    message.success('success', 2)
  }
  const changeSwitch = async (item) => {
    const params = {
      chooseType:{
        ...item
      },
      noteType: {
        displayName,
        value
      }
    }
    await toRes(ApiChangeSwitch({ params }))
  }


  useEffect(() => {
    setDataSource([])
    getData()
  }, [tabValue])
  const onValuesChange = (values) => {
    console.log(values.value)
    reasonList.forEach(item => {
      if (item.value == values.value) {
        changeSwitch(item)
      }
    })
  }
  const reasonList = [
    {
      displayName: i18n.t('optional'),
      value: '1'
    }, {
      displayName: i18n.t('required'),
      value: '2'
    }
  ]
  return (
    <div>
      <Spin spinning={loading}>
        <div className="notes-actions-wrap">
          <Button type="primary" onClick={ showModal }>{ i18n.t('add_new') }</Button>
        </div>
        <Form
          className="form-box"
          form={form}
          initialValues={{
            displayName: i18n.t('optional'),
            value: '1'
          }}
          onValuesChange={onValuesChange}>
          <Form.Item label={i18n.t('frontOfHouse_pc_selecting_notes')} name="value">
            <Radio.Group>
              {reasonList.map(item => <Radio value={item.value} key={item.value}>{item.displayName}</Radio>)}
            </Radio.Group>
          </Form.Item>
        </Form>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              pagination={false}
              rowKey="id"
              columns={columns}
              dataSource={dataSource}
            />
          </SortableContext>
        </DndContext>
      </Spin>

      {/* 弹窗 */}
      <Modal
        title={i18n.t(modalText.id ? 'frontOfHouse_pc_edit_note' : 'frontOfHouse_pc_add_note')}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {i18n.t('cancel')}
          </Button>,
          <Button key="submit" type="primary" disabled={modalText.content == ''} loading={confirmLoading} onClick={handleOk}>
            {i18n.t('save')}
          </Button>
        ]}
      >
        <TextArea
          showCount
          maxLength={100}
          value={modalText.content}
          onChange={onChangeTextArea}
          style={{ height: 120, resize: 'none', marginBottom: '20px' }}
        />
      </Modal>
    </div>
  )
}
