import { ApiUpdatePickupShortUrl } from '@/request/api';
import { CloseOutlined } from '@ant-design/icons';
import { Form, Input, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import './PickupUrlDialog.scss';

interface PickupUrlDialogProps {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  url: string;
  onUrlChange: (url: string) => void;
  i18n: any;
  onClose?: () => void,
}

const PickupUrlDialog: React.FC<PickupUrlDialogProps> = ({
  isOpen,
  onOk,
  onCancel,
  url,
  onUrlChange,
  i18n,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [editableValue, setEditableValue] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (url) {
      const lastSlashIndex = url.lastIndexOf('/');
      const newEditableValue = url.substring(lastSlashIndex + 1);
      setBaseUrl(url.substring(0, lastSlashIndex + 1));
      setEditableValue(url.substring(lastSlashIndex + 1));
      form.setFieldsValue({ pickup_online_order_url: newEditableValue });
      setErrorMessage('');
    }
  }, [url, form]);

  const handleConfirm = async () => {
    const newShortUrl = editableValue;  // 获取修改后的部分

    // 前端验证空url
    if (!newShortUrl) {
      setErrorMessage(i18n.t('PC_OO_URL_Required') || 'URL required');
      return;
    }

    // 前端验证url合法性（只包含英文、数字和中横杠）
    const regex = /^[a-zA-Z0-9-]+$/;
    if (!regex.test(newShortUrl)) {
      setErrorMessage(i18n.t('PC_OO_URL_Format_Invalid') || 'URL can only contain English characters, numbers, and \'-\'');
      return;
    }
    const res = await ApiUpdatePickupShortUrl({ shortUrl: newShortUrl });

    if (res.code === 0) {
      // 更新成功
      message.success(i18n.t('message_success'));
      const newUrl = baseUrl + newShortUrl;  // 合并新的完整 URL
      onUrlChange(newUrl);  // 更新父组件的 URL
      onOk();
    } else if (res.code === 1011004) {
      // URL 重复错误
      setErrorMessage(i18n.t('PC_OO_URL_Already_In_Use') || 'This URL is already in use.');
    } else {
      // 其他错误
      message.error(res.message || i18n.t('message_error'));
    }
  };

  const handleCancel = () => {
    if (url) {
      const lastSlashIndex = url.lastIndexOf('/');
      const originalValue = url.substring(lastSlashIndex + 1);
      setEditableValue(url.substring(lastSlashIndex + 1)); // 重置为原始值
      form.setFieldsValue({ pickup_online_order_url: originalValue });
    }
    setErrorMessage('');
    onCancel();
  };

  const handleInputChange = (value: string) => {
    setEditableValue(value);
    setErrorMessage('');
  };

  const handleClose = () => {
    handleCancel();
    onClose?.();
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      okText={i18n.t('PC_OO_URL_Dialog_Confirm') || 'Confirm'}
      cancelText={i18n.t('PC_OO_URL_Dialog_Cancel') || 'Cancel'}
      destroyOnClose={true}
      okButtonProps={{ style: { padding: '0 16px', minWidth: 0 } }}
      cancelButtonProps={{ style: { padding: '0 16px', minWidth: 0 } }}
      closeIcon={null}
      styles={{ content: { padding: '32px 24px' } }}
      width={560}
    >
      <Form
        form={form}
        initialValues={{
          pickup_online_order_url: url ? url.substring(url.lastIndexOf('/') + 1) : ''
        }}
      >
        <div className='pickup-url-dialog'>
          <div className='dialog-header'>
            <p className='title'>
              {i18n.t('PC_OO_URL_Dialog_Update_Online_Ordering_URL') || 'Update Online Ordering URL'}
            </p>
            <CloseOutlined className='close-icon' onClick={handleClose} />
          </div>
          <p className='warning-text'>
            {i18n.t('PC_OO_URL_Dialog_Updating_URL_Notes') || 'Updating your online ordering URL will make all previous URLs invalid.'}
          </p>
          <div className='url-input-container'>
            <span className='base-url'>
              {baseUrl}
            </span>
            <Form.Item
              name='pickup_online_order_url'
              className='editable-url'
            >
              <Input
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder={i18n.t('PC_OO_URL_Placeholder')}
                maxLength={50}
              />
            </Form.Item>
          </div>
          <div className="err-message">
            {errorMessage && <div className="err-text">{errorMessage}</div>}
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default PickupUrlDialog;
