import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

/**
 * @description 监控静态资源加载失败
 */
export default function AssetsMonitor () {
  useEffect(() => {
    // 监听静态资源加载错误
    const handleResourceError = (event: any) => {
      if (
        event.target.tagName === 'SCRIPT' ||
        event.target.tagName === 'LINK' ||
        event.target.tagName === 'IMG' ||
        event.target.tagName === 'STYLE'
      ) {
        console.log(`Page Load Error: ${event.target.src || event.target.href}`)
        Sentry.captureException(`Page Load Error: ${event.target.src || event.target.href}`);
      }
    };

    // 添加事件监听器
    window.addEventListener('error', handleResourceError, true);

    // 清理函数
    return () => {
      window.removeEventListener('error', handleResourceError, true);
    };
  }, []);
  useEffect(() => {
    // 检查浏览器是否支持性能 API
    if (!window.performance || !window.performance.getEntriesByType) {
      Sentry.captureMessage('Performance API not supported');
      return;
    }

    const handleResourceError = () => {
      const resources: any = performance.getEntriesByType('resource');
      let isPageLoadFail = false;

      // 遍历资源条目以检查加载状态
      for (const resource of resources) {
        if (['img', 'script', 'link', 'css'].includes(resource.initiatorType)) {
          if (resource.responseEnd === 0) {
            isPageLoadFail = true;
            break; // 一旦发现加载失败，立即退出循环
          }
        }
      }

      // 记录页面加载状态
      const logMessage = isPageLoadFail
        ? 'Page Load Error'
        : 'Page Load Success';
      Sentry.captureMessage(logMessage);
    };

    handleResourceError();
  }, []);

  return null;
}