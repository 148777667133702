import React, { useEffect, useState, useRef } from 'react';
import { Table, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs'
import { globalState } from '@/stores';
import { ReactComponent as FeeIcon } from '@/assets/svg/fee.svg'
import { ApiQueryFeeSummary, ApiQueryFeeGroupStatistics, ApiPageQueryCreditCardStatementsRequest, ApiDownloadCreditCardStatement } from '@/request/api';
import PepprDatePicker from '@/Components/DatePicker';
import { getToday, formatFullPrice, formatTime } from '@/utils';
import useGetState from '@/hooks/useGetState';
import { downloadFile, isArrayEmpty, formatPreCent } from '@/utils';
import './index.scss'
import SummaryContent from '@/Pages/FeeReport/SummaryContent';
import ReactDOMServer from 'react-dom/server';
import { echarts } from '@/utils/echarts';
import ReactEChartsCore from 'echarts-for-react/lib/core';

interface iParamsState {
  date: [Dayjs, Dayjs];
}

interface AmountGroupStatistic {
  brand: string;
  value: number;
  proportion: number;
}

interface TransactionGroupStatistic {
  brand: string;
  value: number;
  proportion: number;
}

export interface SummaryInfo {
  totalAmount: number;
  totalFees: number;
  totalTransactionCounts: number;
  amountGroupStatistics: AmountGroupStatistic[];
  transactionGroupStatistics: TransactionGroupStatistic[];
}

interface Statement {
  statementId: string;
  statementDate: string;
  periodStartDate: string;
  periodEndDate: string;
}

const colorRange = ['#DDEBF7', '#C6DBEF', '#9ECAE0', '#6AAED6', '#4292C6', '#2271B5', '#09519C', '#0A306B'];

export default function FeeReport () {
  const { i18n } = globalState;
  const channelColumns = [
    { title: i18n.t('fee_statement_date'), dataIndex: 'statementDate', width: 150, render: (val) => formatTime(val, 'MM/dd/yyyy', 'MM/dd/yyyy') },
    { title: i18n.t('fee_peried_start'), dataIndex: 'periodStartDate', width: 150, render: (val) => formatTime(val, 'MM/dd/yyyy', 'MM/dd/yyyy') },
    { title: i18n.t('fee_peried_end'), dataIndex: 'periodEndDate', width: 150, render: (val) => formatTime(val, 'MM/dd/yyyy', 'MM/dd/yyyy') },
    {
      title: i18n.t('action'), dataIndex: 'action', width: 150, render: (_, record) => (
        <div className="action" onClick={ () => {
          downloadCreditCardStatement(record.statementId)
        } }><span><FeeIcon width="24" height="24"/></span></div>
      ),
    },
  ]

  const [pageSize, setPageSize] = useState(10)
  const [pageNum, setPageNum] = useState(1)
  const [total, setTotal] = useState(0)
  const [summaryInfo, setSummaryInfo] = useState<SummaryInfo>()
  const [statements, setStatements] = useState<Statement[]>([]);
  const [params, setParams, getParams] = useGetState<iParamsState>({
    date: [dayjs(), dayjs()]
  })
  const [paymentTypeInfo, setPaymentTypeInfo] = useState([])
  const [transactionInfo, setTransactionInfo] = useState([])
  const [hasPaymentData, setPaymentHasData] = useState(false)
  const [hasTransactionData, setTransactionHasData] = useState(false)
  const [displayTimeRange, setDisplayTimeRange] = useState<Array<string>>(['', ''])
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [amountActiveKey, setAmountActiveKey] = useState(0)
  const [transactionActiveKey, setTransactionActiveKey] = useState(0)
  const isFirstRender = useRef(true);

  const getLastMonthFirstDay = (_today) => {
    const now = _today ? _today.toDate() : new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    // 获取上个月的 1 号
    const firstDayLastMonth = new Date(year, month - 1, 1);
    // 格式化日期
    const yearStr = firstDayLastMonth.getFullYear();
    const monthStr = String(firstDayLastMonth.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
    const dayStr = String(firstDayLastMonth.getDate()).padStart(2, '0');
    // 返回格式化的日期字符串
    return `${ yearStr }-${ monthStr }-${ dayStr }`;
  }
  const init = async () => {
    const _today = await getToday();
    setToday(_today);
    setParams({ date: [dayjs.tz(getLastMonthFirstDay(_today)), _today.subtract(1, 'day')] });
    setDisplayTimeRange([dayjs.tz(getLastMonthFirstDay(_today)).format('MM/DD/YYYY'), _today.subtract(1, 'day').format('MM/DD/YYYY')])
    queryFeeSummary();
    queryTotalAmount();
    queryTotalTransaction();
    queryCreditCardStatements()
  }
  useEffect(() => {
    init()
  }, [])
  useEffect(() => {
    queryCreditCardStatements()
  }, [pageSize, pageNum])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    queryTotalAmount();
  }, [amountActiveKey])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    queryTotalTransaction();
  }, [transactionActiveKey])

  const queryFeeSummary = () => {
    const params = {
      beginTime: getParams().date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: getParams().date[1].format('YYYY-MM-DD 23:59:59')
    }
    ApiQueryFeeSummary(params).then((res) => {
      setSummaryInfo(() => {
        return {
          ...res.data
        };
      });
    })
  }


  const queryTotalAmount = () => {
    const params = {
      beginTime: getParams().date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: getParams().date[1].format('YYYY-MM-DD 23:59:59'),
      saleChannel: amountActiveKey,
      indicator: 1
    }
    ApiQueryFeeGroupStatistics(params).then((res) => {
      const amountGroupStatistics = res?.data?.groupStatistics || []
      if (isArrayEmpty(amountGroupStatistics)) {
        setPaymentHasData(false)
      } else {
        setPaymentTypeInfo(() => {
          setPaymentHasData(true)
          return (amountGroupStatistics || []).map((item) => {
            return {
              name: `${ item.brand } ${ formatFullPrice(item.value) } (${ formatPreCent(item.proportion) })`,
              tips: `${ item.brand }\n${ formatFullPrice(item.value) } (${ formatPreCent(item.proportion) })`,
              value: item.value
            }
          })
        })
      }
    })
  }

  const queryTotalTransaction = () => {
    const params = {
      beginTime: getParams().date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: getParams().date[1].format('YYYY-MM-DD 23:59:59'),
      saleChannel: transactionActiveKey,
      indicator: 2
    }
    ApiQueryFeeGroupStatistics(params).then((res) => {
      const transactionGroupStatistics = res?.data?.groupStatistics || []
      if (isArrayEmpty(transactionGroupStatistics)) {
        setTransactionHasData(false)
      } else {
        setTransactionInfo(() => {
          setTransactionHasData(true)
          return (transactionGroupStatistics || []).map((item) => {
            return {
              name: `${ item.brand } ${ item.value } (${ formatPreCent(item.proportion) })`,
              tips: `${ item.brand }\n${ item.value } (${ formatPreCent(item.proportion) })`,
              value: item.value
            }
          })
        })
      }
    })
  }


  const handleTabClick = (key, scene) => {
    if (scene === 'amount') {
      setAmountActiveKey(key)
    } else {
      setTransactionActiveKey(key)
    }
  }

  const handleChangeDate = (values) => {
    setParams({ date: values })
    const yesterday = today.subtract(1, 'day')
    setPageNum(1)
    if (values[0].isAfter(yesterday)) {
      setDisplayTimeRange(['', ''])
    } else if (values[1].isAfter(yesterday)) {
      setDisplayTimeRange([values[0].format('MM/DD/YYYY'), yesterday.format('MM/DD/YYYY')])
    } else {
      setDisplayTimeRange([values[0].format('MM/DD/YYYY'), values[1].format('MM/DD/YYYY')])
    }
    queryFeeSummary();
    queryTotalAmount();
    queryTotalTransaction();
    queryCreditCardStatements()
  }


  const queryCreditCardStatements = () => {
    const _params = getParams();
    const result = {
      request: {
        beginTime: _params.date[0].format('YYYY-MM-DD 04:00:00'),
        endTime: _params.date[1].format('YYYY-MM-DD 03:59:59'),
      },
      current: pageNum,
      pageSize
    }
    ApiPageQueryCreditCardStatementsRequest(result).then((res) => {
      const list = res?.data?.list ?? []; //使用 ?? 运算符以空数组代替 null 或 undefined 的值
      setStatements([...list]);
      setTotal(res.data.total);
    });
  }

  const downloadCreditCardStatement = (statementId) => {
    ApiDownloadCreditCardStatement({ statementId }).then((res) => {
      if (res?.data?.url) {
        downloadFile(res.data.url)
      } else {
        message.error(i18n.t('fee_download_fail'))
      }
    }).catch(() => {
      message.error(i18n.t('fee_download_fail'))
    })
  }

  const chartConfig = (data) => ({
    dataset: {
      dimensions: ['name', 'value'],
      source: data
    },
    tooltip: {
      trigger: 'item',
      formatter: (params, ticket, callback) => {
        return ReactDOMServer.renderToString(
          <div className="tooltip-warp">
            <div className="tooltip-point" style={ { background: params.color } }/>
            <div className="tooltip-text">{ params.data.tips }</div>
          </div>
        )
      }
    },
    legend: {
      icon: 'circle',
      type: 'scroll',
      orient: 'vertical',
      left: 300,
      itemWidth: 10,
      top: 'center',
      bottom: 20,
      textStyle: {
        width: 300,
        overflow: 'breakAll',
        lineHeight: 20
      },
      inactiveBorderColor: 'white'
    },
    series: [
      {
        type: 'pie',
        radius: ['60%', '90%'],
        center: ['30%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          borderWidth: 2,
          color: (params) => colorRange[params.dataIndex % colorRange.length]
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false
        }
      }
    ]
  })

  const chartZeroConfig = () => ({
    dataset: {
      dimensions: ['name', 'value'],
      source: [
        { name: 'Visa(-)', value: 1 },
        { name: 'MC(-)', value: 0 },
        { name: 'AMEX(-)', value: 0 },
      ]
    },
    tooltip: { show: false },
    legend: {
      icon: 'circle',
      type: 'scroll',
      orient: 'vertical',
      left: 300,
      itemWidth: 10,
      top: 'center',
      bottom: 20,
      textStyle: {
        width: 300,
        overflow: 'breakAll',
        lineHeight: 20
      },
      selectedMode: false,
      inactiveBorderColor: 'white',
    },
    series: [
      {
        type: 'pie',
        radius: ['60%', '90%'],
        padAngle: 0,
        center: ['30%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          color: (params) => ['#F0F0F0', '#BDBDBD', '#636363'][params.dataIndex % colorRange.length]
        },
        emphasis: { disabled: true },
        cursor: 'default',
        label: {
          show: true,
          position: 'center',
          formatter: 'No Data',
          fontSize: 15,
          fontWeight: 'bold',
          color: '#BEBEBE'
        },
        labelLine: { show: false }
      }
    ]
  })

  const tabList = [{
    name: i18n.t('dashboard_reports_fee_reports_total_amount'),
    key: 0
  }, {
    name: i18n.t('dashboard_reports_fee_reports_card_not_present'),
    key: 1
  }, {
    name: i18n.t('dashboard_reports_fee_reports_in_person'),
    key: 2
  }]

  return (
    <div className="fee-wrap">
      <div className="m-title">
        <div className="title">{ i18n.t('fee_report') }</div>
      </div>
      <div className="bg-content">
        <div className="filter-wrap">
          <PepprDatePicker value={ params.date as [Dayjs, Dayjs] } onChange={ handleChangeDate } today={ today }/>
        </div>
        <div className="time-info">{ `${ i18n.t('pc_employee_report_time_range') }: ${ displayTimeRange[0] } - ${ displayTimeRange[1] }` }</div>
        <SummaryContent item={ summaryInfo }/>
        <div className="chart-wrap">
          <div className="chart-item">
            <p>{ i18n.t('pc_sales_summary_total_amount') }</p>
            <div className='tab'>
              { tabList.map((item, index) => (
                <div className={`tab-item ${ amountActiveKey === item.key ? 'active' : ''}`} key={ item.key } onClick={()=>{handleTabClick(item.key, 'amount')}}>{item.name }</div>
              )) }
            </div>
            <div className="chart-content">
              { hasPaymentData && <ReactEChartsCore echarts={ echarts } option={ chartConfig(paymentTypeInfo) } opts={ { width: 500, height: 250 } }/> }
              { !hasPaymentData && <ReactEChartsCore echarts={ echarts } option={ chartZeroConfig() } opts={ { width: 500, height: 250 } } className="chart-default"/> }
            </div>
          </div>
          <div className="chart-item">
            <p>{ i18n.t('fee_total_transaction') }</p>
            <div className='tab'>
              { tabList.map((item, index) => (
                <div className={`tab-item ${ transactionActiveKey === item.key ? 'active' : ''}`} key={ item.key } onClick={()=>{handleTabClick(item.key, 'transaction')}}>{item.name }</div>
              )) }
            </div>
            <div className="chart-content">
              { hasTransactionData && <ReactEChartsCore echarts={ echarts } option={ chartConfig(transactionInfo) } opts={ { width: 500, height: 250 } }/> }
              { !hasTransactionData && <ReactEChartsCore echarts={ echarts } option={ chartZeroConfig() } opts={ { width: 500, height: 250 } } className="chart-default"/> }
            </div>
          </div>
        </div>
        <div className="table-wrap">
          <div className="table-title-wrap">
            <span className="label">{ i18n.t('fee_credit_card_statement') }</span>
          </div>
          <div className="table-content no-data">
            <Table
              columns={ channelColumns }
              dataSource={ (statements || []).map((x, i) => ({ ...x, key: i })) }
              rowKey={ 'key' }
              pagination={ {
                total,
                current: pageNum,
                pageSize: pageSize, // 每页显示条数
                onChange: (page, pageSize) => {
                  setPageNum(page)
                  setPageSize(pageSize)
                },
                showSizeChanger: true, showQuickJumper: true,
                showTotal: () => {
                  return i18n.t('table_total_items', { num: total });
                },
              } }
            />
          </div>
        </div>
      </div>
    </div>
  )
}