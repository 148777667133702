
import { Modal } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react'
import CreateModifier from '../CreateModifier'
import { globalState } from '@/stores';
import './ModalAddModifierGroup.scss'

interface IProps {
  title?: string
  isModalOpen: boolean
  subMenuId?: string
  modifierGroupId: string
  sourceType?: string,
  handleOk: (params: Array<any>) => void
  handleCancel:  () => void
}

export default function ModalAddModifierGroup (props: IProps) {
  const { i18n } = globalState;
  const refCreateModifier = useRef(null);
  const {
    title = 'Add New Modifier Group',
    isModalOpen,
    subMenuId,
    modifierGroupId,
    handleOk,
    handleCancel
  } = props
  const [confirmLoading, setConfirmLoading] = useState(false);
  const getModifierGroupList = async () => {
    setConfirmLoading(true)
    try {
      const res = await refCreateModifier.current.save()
      if (res) {
        handleOk(res)
      }
    } catch (error) {
      console.log(error)
    }
    setConfirmLoading(false)
  }

  const onCancel = () => {
    if (!modifierGroupId) {
      refCreateModifier.current?.resetData();
    }
    handleCancel();
  }
  return (
    <Modal
      title={title}
      wrapClassName='modal-add-modifier-group'
      width={1000}
      open={isModalOpen}
      confirmLoading={confirmLoading}
      onOk={getModifierGroupList}
      onCancel={onCancel}
      cancelText={i18n?.t('cancel')}
      okText={i18n.t('confirm')}
      maskClosable={false}
    >
      <CreateModifier
        ref={refCreateModifier}
        sourceType={props.sourceType}
        pageMode='popup'
        modifierGroupId={modifierGroupId}
        subMenuId={subMenuId}
      />
    </Modal>

  )
}