import React, {  useEffect, useMemo, useState } from 'react'
import { Drawer, Spin, Flex, Typography, Row, Col, Table, TableColumnsType, Button } from 'antd'
import GuestInfoForm from './GuestInfoForm';
import { IGuestTableRow } from '../interface';
import { ApiQueryOrderReportViewList } from '@/request/api'
import GlobalDrawer from '@/utils/GlobalDrawer';
import { useNavigate } from 'react-router-dom';
import OrderDetail from '@/Pages/OrdersReport/OrderDetail';
import { formatCurrency, formatTimestampWithoutTimeZone } from '@/utils';
import { globalState } from '@/stores';
import * as Sentry from '@sentry/react'
import './index.scss'
import dayjs from 'dayjs';
import moment from 'moment-timezone';

const { Text } = Typography

interface IProps {
    open: boolean,
    onClose: () => void,
    dataSource: IGuestTableRow
}
const CURRENCY_SYMBOL = '$'


const GuestInfo: React.FC<IProps> = (props) => {
  const { open, onClose, dataSource } = props
  const navigate = useNavigate()
  const { i18n } = globalState
  const initialPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
    showSizeChanger: true, // 启用 pageSize 选择器
  }
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false)
  const [tableList, setTableList] = useState([])
  const [pagination, setPagination] = useState(initialPagination)

  const orderDetailOptions = useMemo(() => {
    if (!dataSource) return [];
    return [
      {
        label: i18n.t('PC_GUEST_LAST_ORDER'),
        text: dataSource?.lastOrderTime ? moment(dataSource?.lastOrderTime, 'YYYY-MM-DD hh:mm:ss A').format('MMM DD') : '-'
      },
      { label: i18n.t('PC_GUEST_TOTAL_ORDERS'), text: dataSource?.numberOfOrder },
      { label: i18n.t('PC_GUEST_AVG_SPENT'), text: `${CURRENCY_SYMBOL}${dataSource?.avgSpend}` },
      { label: i18n.t('PC_GUEST_TOTAL_SPENT'), text: `${CURRENCY_SYMBOL}${dataSource?.lifetimeSpend}` }
    ] }, [dataSource]);


  const columns = [
    {
      key: 'closeTime',
      title: i18n.t('PC_GUEST_CLOSED_TIME'),
      dataIndex: 'standardCloseTime',
      render: (text) => (formatTimestampWithoutTimeZone(text, { format: 'MM/DD/YYYY hh:mm:ss A' }))
    },
    {
      key: 'server.fullName',
      title: i18n.t('pc_reports_orders_server'),
      dataIndex: 'server',
      render: (server) => server.fullName ?? 'N/A',
    },
    {
      key: 'posDisplayNo',
      title: i18n.t('PC_GIFTCARD_ADJUST_CHECK'),
      dataIndex: 'posDisplayNo',
      // 展示使用posDisplayNo, 跳转使用checkNo
      render: (value: string, record) => {
        return value ? <Button type="link" className="body-base" style={{ padding:0, height:'auto' }} onClick={ () => {
          GlobalDrawer.open({ component: <OrderDetail/>, navigate: navigate, props: { checkNo: record?.checkNo } })
        }}>{`${i18n.t('PC_GIFTCARD_ADJUST_CHECK')}${value}`}</Button> : 'N/A'
      }
    },
    {
      key: 'receivable',
      title: i18n.t('total'),
      dataIndex: 'receivable',
      render: (value: string) => `${CURRENCY_SYMBOL}${value || 0}`
    },
    {
      key: 'totalRefundAmount',
      title: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_FULLY_REFUNDED'),
      dataIndex: 'totalRefundAmount',
      render: (value) => (value ? formatCurrency(value) : '$0.00')
    }
  ]
  useEffect(() => {
    if (open && dataSource?.guestId) {
      queryTableList(pagination)
    }
  }, [dataSource, open])
  const queryTableList = async (pagination) => {
    setIsTableLoading(true)
    function getCurrentAndLastYearTime () {
      const formatString = 'YYYY-MM-DD HH:mm:ss';  // 定义你想要的格式
      const currentDate = dayjs();  // 获取当前日期
      const lastYearDate = currentDate.subtract(1, 'year');  // 获取一年前的日期

      return {
        currentDate: currentDate.format(formatString),  // 格式化当前日期
        lastYearDate: lastYearDate.format(formatString),  // 格式化一年前的日期
      };
    }

    const dates = getCurrentAndLastYearTime();
    const queryOrderParams = {
      current: pagination?.current,
      pageSize: pagination?.pageSize,
      request: {
        // advancedSearchIdDisplay: null,
        // diningOptionIds: null,
        // salesChannels: null,
        orderStatus: [300, 500],
        // Mark 目前取的时间可能有时区问题，跟后端同学沟通结论如下：如果传了guestId, 则自动重置时间区间为一年内，也就是说传入的beginTime&endTime不会生效
        beginTime: dates?.lastYearDate,
        endTime: dates?.currentDate,
        guestId: dataSource?.guestId,
      },
      orderBy: {
        field: 'complete_time',
        order: 'DESC',
      }
    }
    try {
      const res = await ApiQueryOrderReportViewList({ ...queryOrderParams })
      if (res?.code === 0) {
        const { list, current, total } = res?.data
        // 后端未返回pageSize，所以在成功获取到数据后才更新pageSize
        setPagination((prevState) => ({ ...prevState, ...pagination, current, total: Number(total) }))
        setTableList(list)
      }
    } catch (error) {
      Sentry.captureEvent({
        message: 'Failed to fetch queryOrderReportView List',
        level: 'info',
        extra: {
          additionalInfo: error,
        },
      });
    } finally {
      setIsTableLoading(false)
    }
  }
  const handleTableChange = (pagination) => {
    queryTableList(pagination)
  }

  const OrderDetailItem = ({ label, text }) => {
    const textStyle = { display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }
    return <Flex vertical style={{ padding: '9px 8px', backgroundColor:'#F6FFED' }} className='body-base'>
      <Text style={textStyle}>{label}</Text>
      <Text style={textStyle}>{text}</Text>
    </Flex>
  }
  return (
    <Drawer width={750} open={open} onClose={onClose} title={i18n.t('print_decoration_guest_info')} style={{ backgroundColor: '#F5F5F5' }} destroyOnClose={true}>
      <div className="guest-info-wrapper">
        <div className="content-wrapper" style={{ marginBottom: '16px' }}>
          <GuestInfoForm guestId={String(dataSource?.guestId)}/>
          <Row gutter={8}>
            {orderDetailOptions?.map(item =>
              <Col key={item.label} span={6}>
                <OrderDetailItem  {...item}/>
              </Col>)}
          </Row>
        </div>
        <div className="content-wrapper" >
          <Text className="heading4">{i18n.t('PC_GUEST_ORDER_HISTORY')}</Text>
          <Table loading={isTableLoading} rowKey="uuId" columns={columns} dataSource={tableList} pagination={pagination} onChange={handleTableChange} style={{ marginTop: '16px' }} scroll={{ y: 600 }}/>
        </div>
      </div>
    </Drawer>
  )
}
export default GuestInfo