import { pepprService } from '../index';
import { IRequestResponse } from '../interface';

export interface ICreateTimesheet {
  employeeId?: number;
  id?: number;
  clockInTime: string;
  clockOutTime: string;
  roleId: number;
  declareTips: string | null;
  breakTimeSheetList: {
    breakId: number;
    breakStartTime: string;
    breakEndTime: string;
  }[];
}

// 创建
export const ApiCreateTimesheet = (params: ICreateTimesheet): Promise<IRequestResponse<boolean>> => pepprService.post('/api/peppr-employee/employee/createTimesheet', params)

// 编辑
export const ApiUpdateTimesheet = (params: ICreateTimesheet): Promise<IRequestResponse<boolean>> => pepprService.post('/api/peppr-employee/employee/updateTimesheet', params)

// 删除
export const ApiDeleteTimesheet = (params: { ids: number[] }): Promise<IRequestResponse<boolean>> => pepprService.post('/api/peppr-employee/employee/deleteTimesheet', params)