import { globalState } from '@/stores';

export const getRefundStatusOptions = () => {
  const { i18n } = globalState;
  return [
    { value: '0', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_NOT_REFUNDED') },
    { value: '1', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_PARTIALLY_REFUNDED') },
    { value: '2', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_FULLY_REFUNDED') },
  ];
};
