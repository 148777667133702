import React from 'react'
import './InfoCard.scss'
import { Empty } from 'antd';

interface IProps {
  infoData: any,
  title?: string
  setInfoKey: {
    title: string,
    content: any
    key?: string
  }
}

export default function InfoCard (props: IProps) {
  const { infoData = [], title = '', setInfoKey } = props;

  return (
    <div className='info-card'>
      <div className="title">{title}</div>
      <div className="info-list">
        {infoData.length > 0 ? infoData.map((item: any, index: number) => (
          <div className={`info-item ${item[setInfoKey.title] === 'Total' ? 'bold' : ''}`} key={item[setInfoKey.key] || index}>
            <div className="info-item-title">{item[setInfoKey.title]}</div>
            <div className="info-item-content">{item[setInfoKey.content]}</div>
          </div>
        )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>
    </div>
  )
}