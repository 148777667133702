import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal, Typography, Table, Input, InputNumber, Spin, message, Form, Switch } from 'antd'
import { globalState } from '@/stores';
import { viewArchiveUsage } from '@/request/Menus/MenuDatabase'
import { ApiModifierUpdate } from '@/request/Menus/EditModifier'
import UsageCard from './UsageCard';
import Availability from '../component/Availability'
import ModifierReport from '../component/ModifierReport'
import ColorFilter from '../component/ColorFilter';
import './EditModifier.scss';
import { color } from 'echarts';
import ChannelAvailability from './ChannelAvailability';
import { set } from 'mobx';
const { Paragraph } = Typography;

interface IProps {
  openModal: boolean,
  setOpenModal: Dispatch<SetStateAction<boolean>>
  modifiedList: any[],
  handleOk?: (arr: any[]) => void
  handleCancel?: () => void
}

const EditModifier: FC<IProps> = (props) => {
  const { i18n } = globalState;
  const [modifierGroupsList, setModifierGroupsList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [arr, setArr] = useState<any[]>();
  const [chooseModifiers, setChooseModifiers] = useState<Array<any>>([]);
  const [form] = Form.useForm();
  const usePriceConfig = Form.useWatch('usePriceConfig', form);
  const refChannelAvailability = useRef(null);
  const [channelFormData, setChannelFormData] = useState<any>({});
  const handleOk = async () => {
    if (!!arr.length) {
      if (arr.length && arr.filter(fil => !fil.name).length > 0) { // 如果modifier list有值并且有一项name为空
        message.error(i18n?.t('please_enter_x', { msg: i18n?.t('menu_modifier_name') }));
        return;
      }
      const isValid = await form.validateFields();
      console.log(isValid)
      if (!isValid) return;
      const { usePriceConfig, priceDetails, inheritItemSalesCategory, salesCategoryId, color } = form.getFieldsValue()
      const formChannelAvailabilityData = refChannelAvailability.current.form.getFieldsValue()

      const { visibleChannelPOS, onlineOrdering, doordashVisible, qrScanVisible } = formChannelAvailabilityData

      const priceArr = priceDetails ? priceDetails.map(item => {
        return {
          ...item,
          startTime: item.timeRange[0],
          endTime: item.timeRange[1],
        }
      }) : null
      const req = arr.map(ele => ({
        uuid: ele.uuid,
        name: ele.name,
        foreignName: ele.foreignName,
        price: ele.modifierPrice,
        color,
        inheritItemSalesCategory,
        salesCategoryId: inheritItemSalesCategory ? null : salesCategoryId,
        priceConfig: {
          usePriceConfig,
          priceDetails: priceArr
        },
        visibleChannelPOS,
        onlineOrdering,
        doordashVisible,
        qrScanVisible
      }))[0];
      const { code } = await ApiModifierUpdate(req);
      if (code === 0) {
        message.success(i18n.t('message_success'));
        setArr([]);
        setTimeout(() => {
          props.setOpenModal(false);
          props.handleOk && props.handleOk(arr);
        })
      }
    }
  }
  const handleCancel = () => {
    setArr([]);
    setTimeout(() => {
      props.setOpenModal(false);
      props.handleCancel && props.handleCancel();
    })
  }

  const handleInputChange = (value, uuid, column) => {
    const newData = [...arr];
    const index = newData.findIndex(item => uuid === item.uuid);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [column]: value });
      setArr(newData);
    }
  };

  const handleInputBlur = (record) => {
    const { uuid } = record;
    const index = chooseModifiers.findIndex(find => find.uuid === uuid);
    if (index > -1) {
      const newArr = [...chooseModifiers];
      const newData = chooseModifiers[index];
      newData.name = record.name;
      newArr.splice(index, 1, newData);
      setChooseModifiers(newArr);
    } else {
      setChooseModifiers([
        ...chooseModifiers,
        record
      ]);
    }
  }

  const columns = [
    {
      title: <>{ i18n?.t('createModifierGroup_pc_item_group') } <span style={{ 'color': 'red' }}>*</span></>,
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <div>
        <Input value={text} maxLength={100} onChange={e => handleInputChange(e.target.value, record.uuid, 'name')} onBlur={() => { handleInputBlur(record); }}/>
        {chooseModifiers.map(ele => ele.uuid).includes(record.uuid) && !record.name && <div style={{ 'color': '#ff4d4f' }}> {i18n?.t('please_enter_x', { msg: i18n?.t('menu_modifier_name') })} </div>}
      </div>,
    },
    {
      title: i18n?.t('dashboard_menu_kitchen_display_name'),
      dataIndex: 'foreignName',
      key: 'foreignName',
      render: (text, record) => <Input value={text} maxLength={100} onChange={e => handleInputChange(e.target.value, record.uuid, 'foreignName')} />,
    },
    {
      title: i18n?.t('orderDetail_price'),
      dataIndex: 'modifierPrice',
      key: 'modifierPrice',
      render: (text, record) => <InputNumber defaultValue={text} min={0} max={9999999} precision={2} onChange={e => handleInputChange(e, record.uuid, 'modifierPrice')} />,
    }
  ]

  const initData = async () => {
    setArr([...props.modifiedList]);
    const modifierInfo = props.modifiedList[0] || {};
    const priceConfig = modifierInfo?.priceConfig;
    const inheritItemSalesCategory = modifierInfo?.inheritItemSalesCategory;
    const menuItem = modifierInfo?.menuItem;
    const color = modifierInfo?.menuItem.color || '';
    const salesCategoryId = menuItem?.salesCategories[0]?.id || null;
    setChannelFormData(menuItem);
    form.setFieldsValue({
      usePriceConfig: priceConfig?.usePriceConfig || false,
      inheritItemSalesCategory,
      salesCategoryId: salesCategoryId || null,
      color,
      priceDetails: priceConfig?.priceDetails?.map(item => {
        return {
          timeRange: [item.startTime, item.endTime],
          ...item
        }
      }) ?? []
    });
    const { code, data } = await viewArchiveUsage({
      params: {
        archive: {
          archiveType: 'MenuOption',
          archiveUUID: props.modifiedList[0].uuid
        }
      }
    });
    if (code === 0) {
      const list = data.filter(x => x.archiveType.value === 'MenuOptionGroup');
      setModifierGroupsList(list)
    }
  }

  useEffect(() => {
    if (props.openModal) {
      setLoading(true);
      initData();
      setLoading(false);
    }
  }, [props.openModal])
  return (
    <div className='menu-edit-modifier'>
      <Spin spinning={loading}>
        <Modal
          title={i18n?.t('menus_edit_modifier')}
          open={props.openModal}
          onOk={handleOk}
          onCancel={handleCancel} width={800}
          cancelText={i18n?.t('cancel')}
          okText={i18n.t('confirm')}
          wrapClassName='modal-body-wrap'
          destroyOnClose={true}>
          <div className='menu-edit-modifier-card'>
            <Paragraph strong>{i18n?.t('modifier')}</Paragraph>
            <Table columns={columns} dataSource={arr} rowKey={'uuid'} pagination={false} />
            <Form form={form} name='validateOnly' autoComplete='off' layout='vertical' style={{ marginTop: '8px' }}>
              <Form.Item name='color' label={i18n?.t('menus_pos_button_color')} style={ { 'marginTop': '10px' } }>
                <div>
                  <Input style={ { display: 'none' } }/>
                  <ColorFilter colorText={form.getFieldValue('color')} handleChangeColorItem={(colorText: string) => {
                    form.setFieldValue('color', colorText)
                  }}/>
                </div>
              </Form.Item>
              <div className="switch-text">
                <Form.Item name='usePriceConfig'>
                  <Switch />
                </Form.Item>
                <div className="text">Time based price override</div>
              </div>
            </Form>
            {<Availability form={form} style={{ marginTop: '16px', display: usePriceConfig ? 'block' : 'none' }} /> }
          </div>
          <ChannelAvailability
            ref={refChannelAvailability}
            editInfo={channelFormData}
            pricingType={null}
          />
          {<ModifierReport form={form}  />}
          <UsageCard list={modifierGroupsList} tip={i18n.t('used_in_modifierGroup', { x: modifierGroupsList.length })}/>
        </Modal>
      </Spin>
    </div>
  )
}

export default EditModifier;