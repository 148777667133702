import React from 'react';
import type { TableColumns } from '@/types/table';
import { formatTime, formatFullPrice, formatBackI18n } from '@/utils';
export interface GiftCardTransactionTableData {
  checkId: string;
  checkNo: string;
  paymentDate: string;
  amount: string;
  tip: string;
  total: string;
  accountId: string;
  refundStatus: string;
  refundTotal: string;
}

export const getColumns = (
  i18n,
): TableColumns<GiftCardTransactionTableData> => [
  {
    key: 'checkId',
    title: i18n.t('pc_transaction_check_id'),
    dataIndex: 'checkId',
  },
  {
    key: 'checkNo',
    title: i18n.t('pc_transaction_check_no'),
    dataIndex: 'checkNo',
  },
  {
    key: 'paymentDate',
    title: i18n.t('pc_transaction_payment_date'),
    dataIndex: 'paymentDate',
    render: (value) => value ? formatTime(value, 'MM/dd/yyyy hh:mm:ss tt') : '-',
  },
  {
    key: 'amount',
    title: i18n.t('pc_transaction_amount'),
    dataIndex: 'amount',
    render: (value) => value ? formatFullPrice(value) : '-',
  },
  {
    key: 'tip',
    title: i18n.t('pc_transaction_tip'),
    dataIndex: 'tip',
    render: (value) => value ? formatFullPrice(value) : '-',
  },
  {
    key: 'total',
    title: i18n.t('total'),
    dataIndex: 'total',
    render: (value) => value ? formatFullPrice(value) : '-',
  },
  {
    key: 'accountId',
    title: i18n.t('dashboard_report_account_id'),
    dataIndex: 'accountId',
  },
  { dataIndex: 'refundStatus',
    key: 'refundStatus',
    title: i18n.t('trans_table_refundStatus'),
    render: (val) => formatBackI18n(val?.displayName, i18n) || '-' },
  {
    key: 'refundTotal',
    title: i18n.t('trans_table_refundTotal'),
    dataIndex: 'refundTotal',
    render: (value) => value ? formatFullPrice(value) : '-',
  },
];