import { message } from 'antd'
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { ApiQueryCalendarDay, ApiQuerySalesDay } from '@/request/api';
import NP from './NP';
import { globalState } from '@/stores';
const { i18n } = globalState;

interface IResponse {
  data: any;
  code: number;
  message: string;
}

export function toRes (promise, { showErrMsg = true } = {}) {
  return promise.then((res = {} as IResponse) => {
    const { data, code, message: msg } = res
    if (code === 0) {
      return [null, data];
    } else {
      showErrMsg && msg && message.error(msg)
      return [res]
    }
  }).catch(err => {
    showErrMsg && message.error(err?.message || 'Request Failed')
    return [err]
  });
}

export function verifyEmail (email: string): boolean {
  // 修改正则表达式以匹配任意顶级域名
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)/;
  return re.test(email);
}

export function to (promise) {
  return promise.then(data => {
    return [null, data];
  }).catch(err => [err]);
}

export function parseHash (routeString) {
  const regex = /#?([^?]+)(?:\?(.*))?/;
  const matches = routeString.match(regex);

  if (!matches) {
    return {};
  }

  const route = matches[1].replace('#', ''); // 去除路由中的 '#'
  const paramString = matches[2];

  const params = {};
  if (paramString) {
    const paramPairs = paramString.split('&');
    for (const pair of paramPairs) {
      const [key, value] = pair.split('=');
      params[key] = value;
    }
  }

  return { route, params };
}

type Querys = {
  [key: string]: string
}

export function getQuerys (url: string): Querys {
  const querys: Querys = {};
  const params = (url.split('?')[1] || '').split('&');
  for (const param of params) {
    if (param) {
      const parts = param.split('=');
      querys[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1] || '');
    }
  }
  return querys;
}

export function tryParse<T = unknown> (str: string): T | undefined {
  try {
    return <T>JSON.parse(str);
  } catch (e) {
    return undefined;
  }
}

export function leftCompose<T> (...fns: ((arg: T) => T)[]): (arg: T) => T {
  return (arg: T) => fns.reduceRight((acc, fn) => fn(acc), arg);
}

export function decodeParams (): string {
  const url = window.location.href
  const regex = /param=(.*)$/;
  const paramArr = url.match(regex)
  let decodeParams = '{}'
  if (paramArr && paramArr.length) {
    decodeParams = decodeURIComponent(paramArr[1])
  }
  return decodeParams
}

export function checkEmail (email: string, i18n: any): Promise<any> {
  const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)/;
  if (email && pattern.test(email)) {
    return Promise.resolve();
  } else {
    return Promise.reject(i18n?.t?.('pc_login_valid_email'));
  }
}

export function checkPassword (password: string, i18n: any): Promise<any> {
  const pattern = /^(?=.*[a-zA-Z])(?=.*\d)(?!.*\s).{8,}$/;
  if (password) {
    if (pattern.test(password)) {
      return Promise.resolve();
    } else {
      return Promise.reject(i18n?.t?.('password_form_tips'));
    }
  } else {
    return Promise.reject('Must be 8 characters and contain  both numbers and letters.')
  }
}

export function isStrictlyEmptyString (value) {
  return !value || (typeof value === 'string' && value.trim() === '');
}

export function formatDollar (num, { plusSign = false } = {}) {
  const _result = Number(num)
  if (isNaN(_result)) return '$'
  if (plusSign) {
    return _result > 0 ? '+$' : _result === 0 ? '$' : '-$'
  }
  return _result >= 0 ? '$' : '-$'
}

export function formatPrice (num) {
  const _result = Math.abs(num);
  return isNaN(_result) ? '0.00' : _result.toFixed(2)
}

export function formatFullPrice (num, split = '') {
  return formatDollar(num) + split + formatPrice(num);
}

// 根据输入格式解析日期
export function parseDateFromString (dateString: string, dateFormat: string) {
  const formatParts = dateFormat.match(/yyyy|MM|dd|HH|mm|ss/g);
  const dateParts = dateString.match(/\d+/g);
  if (!formatParts || !dateParts || formatParts.length !== dateParts.length) {
    throw new Error('Date string does not match format or format is invalid.');
  }
  const dateMapping = {};
  for (let i = 0; i < formatParts.length; i++) {
    dateMapping[formatParts[i]] = parseInt(dateParts[i], 10);
  }
  return new Date(
    dateMapping['yyyy'] || 0,
    (dateMapping['MM'] ? dateMapping['MM'] - 1 : 0), // 月份需要减1，因为月份是从0开始的
    dateMapping['dd'] || 0,
    dateMapping['HH'] || 0,
    dateMapping['mm'] || 0,
    dateMapping['ss'] || 0
  );
}

// 使用示例
// const input = '2024-04-28 15:30:00'; // 默认输入格式为'yyyy-MM-dd HH:mm:ss'
// const outputPattern = 'MM/dd/yyyy hh:mm:ss tt';
// const formattedTime = formatTime(input, outputPattern);
// console.log(formattedTime); // 输出示例: 04/28/2024 03:30:00 PM
export function formatTime (input, outputPattern, inputFormat = 'yyyy-MM-dd HH:mm:ss') {
  let date;
  // 检查输入类型，并转换为Date对象
  if (input instanceof Date) {
    date = input;
  } else if (typeof input === 'number') {
    date = new Date(input); // 时间戳
  } else if (typeof input === 'string') {
    // 使用输入格式解析时间字符串
    date = parseDateFromString(input, inputFormat);
  } else {
    throw new Error('Invalid input type. Input should be a Date object, a timestamp, or a time string.');
  }

  // 计算12小时制的小时数
  const hours24 = date.getHours();
  const hours12 = hours24 % 12 === 0 ? 12 : hours24 % 12;
  // 设置AM或PM
  const amPm = hours24 >= 12 ? 'PM' : 'AM';

  // 定义格式化函数
  const formatter = {
    'yyyy': date.getFullYear().toString(),
    'MM': ('0' + (date.getMonth() + 1)).slice(-2),
    'dd': ('0' + date.getDate()).slice(-2),
    'HH': ('0' + hours24).slice(-2),
    'hh': ('0' + hours12).slice(-2), // 12小时制
    'mm': ('0' + date.getMinutes()).slice(-2),
    'ss': ('0' + date.getSeconds()).slice(-2),
    'tt': amPm
  };

  // 替换输出格式中的占位符
  return outputPattern.replace(/yyyy|MM|dd|HH|hh|mm|ss|tt/g, (match) => formatter[match]);
}

export function removeNullUndefinedFields (obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined)
  );
}

export const getTimeZone = () => {
  const shopInfo = JSON.parse(localStorage.getItem('shopInfo') || '{}')
  return shopInfo?.timeZone ?? shopInfo?.timeZoneId ?? '';
}

export const formatBackI18n = (str = '', _i18n) => {
  const result = str.replace(/[{}]/g, '');
  return i18n.t(result) || result;
}

export const errorOutput = (err, defaultOutput, i18n) => {
  if (isAxiosError(err) && [504, 408].includes(err?.request?.status)) {
    message.error(i18n.t('pc_timeout_exceeded'))
  } else {
    defaultOutput && message.error(defaultOutput)
  }
}

// 获取营业日
export const getToday = async ({ timeZone }: { timeZone?: string } = {}) => {
  const resp = await ApiQuerySalesDay({ params: { req: { timeZone: getTimeZone() } } });
  return resp?.data?.salesDay ? dayjs(resp.data.salesDay) : dayjs();
}

// 获取今天的日期
export const getCalendarToday = async ({ timeZone }: { timeZone?: string } = {}) => {
  const resp = await ApiQueryCalendarDay({ timeZone: getTimeZone() });
  console.log(dayjs(resp.data.calendarDay), 'dayjs(resp.data.calendarDay)');
  return resp?.data?.calendarDay ? dayjs(resp.data.calendarDay) : dayjs();
}

export const formatTimestamp = (val, { format = 'MM/DD/YYYY hh:mm:ss A' }: { format?: string } = {}) => {
  return val ? dayjs(val).tz(getTimeZone()).format(format) : '';
}

export const formatTimestampWithoutTimeZone = (val, { format = 'MM/DD/YYYY hh:mm:ss A' }: { format?: string } = {}) => {
  return val ? dayjs(val).format(format) : ''
}

export const showWrapMask = () => {
  const div = document.createElement('div');
  div.style.position = 'fixed';
  div.style.top = '0';
  div.style.left = '0';
  div.style.right = '0';
  div.style.bottom = '0';
  div.style.width = '100%';
  div.style.height = '100%';
  div.style.zIndex = '9999';
  document.body.appendChild(div);
  setTimeout(() => {
    document.body.removeChild(div);
  }, 300)
}

export const listSortBy = (data, fields, sortAsc = true) => {
  return data.sort((a, b) => {
    return fields.reduce((result, field) => {
      if (result !== 0) {
        return result;
      }
      if (sortAsc) {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
      } else if (!sortAsc) {
        if (a[field] > b[field]) {
          return -1;
        }
        if (a[field] < b[field]) {
          return 1;
        }
      }
      return 0;
    }, 0);
  });
}

export function isEmpty (value: unknown): boolean {
  if (value === undefined || value === null) {
    return true;
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
    if (value instanceof Map || value instanceof Set) {
      return value.size === 0;
    }
    return Object.keys(value).length === 0;
  }
  return false;
}

export function debounce (func: any, wait: number, immediate = false) {
  let timeout;

  return (...args) => {
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(this, args); // 使用 apply 方法传递参数
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(this, args); // 使用 apply 方法传递参数
  };
}

export const generateRandomId = () => {
  return 'id_' + Math.random().toString(36).substr(2, 9) + '_' + Date.now();
}
export const downloadFile = (url) => {
  if (!url) return;
  const urlObject = new URL(url);
  const filename = decodeURIComponent(urlObject.pathname.substring(urlObject.pathname.lastIndexOf('/') + 1));
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isArrayEmpty = (arr) => {
  return Array.isArray(arr) && arr.length === 0;
}
export const isNil = (variable) => {
  return variable === null || variable === undefined;
}

export const formatPreCent = (num = 0) => {
  return `${ NP.times(num, 100).toFixed(2) }%`
}

// 获取指定名称的 Cookie 值
export const getCookie = (name) => {
  const value = `; ${ document.cookie }`;
  const parts = value.split(`; ${ name }=`);
  if (parts.length > 0) return parts.pop().split(';').shift();
  return null;
};
export const formatParams = (params: any) => {
  return Object.keys(params).reduce((acc, key) => {
    const value = params[key];
    acc[key] = Array.isArray(value) && value.length > 0 ? value : null;
    return acc;
  }, {});
};

export const timeStringToMilliseconds = (
  timeString: string,
  format: true | false = false
) => {
  let timeParts = null;
  if (format) {
    timeParts = timeString.match(/(\d{1,2}):(\d{2})\s?(AM|PM)/i);
  } else {
    timeParts = timeString.match(/(\d{1,2}):(\d{2})/);
  }

  if (!timeParts) {
    throw new Error('Invalid time format');
  }

  let hours = parseInt(timeParts[1], 10);
  const minutes = parseInt(timeParts[2], 10);

  if (format) {
    const period = timeParts[3].toUpperCase();
    if (period === 'PM' && hours < 12) {
      hours += 12;
    }
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }
  } else {
    if (hours === 24) {
      hours = 0; // 对于24小时制，24:00等同于00:00
    }
  }

  const milliseconds = hours * 60 * 60 + minutes * 60;
  return milliseconds;
};
export const secondsToTimeString = (
  seconds: number,
  format: true | false = false
) => {
  if (seconds === null || seconds === undefined) {
    return '';
  }

  let hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (format) {
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours === 0 ? 12 : hours; // 将0点转换为12点

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    const timeString = `${formattedHours}:${formattedMinutes} ${period}`;
    return timeString;
  } else {
    if (hours === 0) {
      hours = 24; // 对于24小时制，0点转换为24点
    }
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    const timeString = `${formattedHours}:${formattedMinutes}`;
    return timeString;
  }
};
export const isAndroidWebView = () => {
  // 获取用户代理字符串
  const userAgent = navigator.userAgent || navigator.vendor;

  // 检查用户代理字符串中是否包含 "wv" 或 "Android.*Version\/"
  const isWebView =
    /wv/.test(userAgent) || /Android.*Version\/[0-9\.]+/.test(userAgent);

  return isWebView;
}

export const formatCurrency = (val): string => {
  const value = parseFloat(val);
  const formattedText = Math.abs(value).toFixed(2);
  return value < 0 ? `-$${formattedText}` : `$${formattedText}`;
};

export const formatDate = (val, { format = 'YYYY-MM-DD HH:mm:ss' }: { format?: string } = {}) => {
  return val ? dayjs(val).format(format) : ''
}
