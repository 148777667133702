import React, { useEffect, useRef, useState } from 'react';
import { globalState } from '@/stores';
import PepprDatePicker from '@/Components/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Spin } from 'antd';
import ReactToPrint from 'react-to-print';
import useGetState from '@/hooks/useGetState';
import { ApiQueryProductMixReport } from '@/request/api';
import { getToday } from '@/utils';
import './index.scss'
import { IParams, IProductMixData, IProductMixReq, IProductMixRes } from './interface';
import { SyncOutlined } from '@ant-design/icons';
import BreakdownByMenuList from '@/Pages/ProductMix/BreakdownByMenuList';
import TopSubMenusList from '@/Pages/ProductMix/TopSubMenusList';
import { getCookie } from '@/utils';

export default function ProductMix () {
  const { i18n } = globalState;

  const [loading, setLoading] = useState<boolean>(true)
  const [params, setParams, getParams] = useGetState<IParams>({
    date: [dayjs(), dayjs()]
  })
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [reportData, setReportData] = useState<Partial<IProductMixData>>({
    topSalesCategoryStats: []
  })
  const printRef = useRef(null);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _today = await getToday();
    setToday(_today);
    setParams({ date: [_today, _today] });
    fetchData();
  }

  const handleChangeDate = (values) => {
    setParams({ date: values })
    fetchData();
  }

  const fetchData = async () => {
    const _params = getParams();
    const result: IProductMixReq = {
      beginTime: _params.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: _params.date[1].format('YYYY-MM-DD 23:59:59')
    }
    setLoading(true)
    const res: IProductMixRes = await ApiQueryProductMixReport(result);
    setLoading(false)
    if (res.code === 0) {
      setReportData(res.data || {});
    }
  }

  return (
    <div className="product-mix-wrap print-container" ref={ printRef }>
      <Spin size="large" spinning={ loading }>
        <div className="m-title">
          <div className="title">{ i18n.t('pc_product_mix_title') }</div>
        </div>
        <div className="content-wrap">
          <div className="filter-wrap">
            <div className="filter-left-wrap">
              <PepprDatePicker value={ params.date } onChange={ handleChangeDate } today={ today }/>
            </div>
            <div className="filter-right-wrap">
              <Button icon={ <SyncOutlined/> } onClick={ fetchData }/>
              {getCookie('appCode') !== '1'  &&
              <ReactToPrint
                trigger={ () => <Button icon={ <div className="iconfont icon-print"/> }/> }
                content={ () => printRef.current }
              />
              }
            </div>
          </div>
          <div className="product-mix-split-wrap">
            <TopSubMenusList tableData={ reportData.topSubMenuStats } title={ i18n.t('pc_product_mix_top_sub_menus') }/>
            <TopSubMenusList tableData={ reportData.topMenuItemStats } title={ i18n.t('pc_product_mix_top_items') } getParams={getParams}/>
          </div>
          <BreakdownByMenuList reportData={ reportData } getParams={getParams}/>
        </div>
      </Spin>
    </div>
  )
}