import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Checkbox, Form, Input, InputNumber, message, Radio, Spin } from 'antd';
import { isNil, to, formatTimestampWithoutTimeZone } from '@/utils';
import { ApiBatchSaveDiscountActivitys, ApiQueryDiscountDetails } from '@/request/api';
import { DISCOUNT_TYPE, DISCOUNT_TYPE_I18N } from '../enum';
import './index.scss'
import PepprDatePicker from '@/Components/DatePicker';
import NP from '@/utils/NP';

export default function DiscountManageEdit (props) {
  const { editItem, fetchTableData, close } = props
  const { i18n } = globalState;
  const [form] = Form.useForm()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [editItemInfo, setEditItemInfo] = useState<any>()
  const discountTypeWatched = Form.useWatch('discountType', form);
  const permanentWatched = Form.useWatch('permanent', form)

  const initialValues = {
    name: '',
    discountType: DISCOUNT_TYPE.FIXED,
    discountAmount: 0,
    discountPercent: 0,
    permanent: true,
    dates: [],
    enableDiscountReasons: false
  }
  const init = async () => {
    const _isEdit = !!editItem;
    setIsEdit(_isEdit);
    if (_isEdit) {
      await fetchDetail();
    }

    setLoading(false)
  }

  useEffect(() => {
    init()
  }, []);

  const formRules = {
    name: [
      { required: true, message: i18n.t('please_enter_x', { msg: i18n.t('discountManage_pc_offer_name') }) },
      () => ({
        validator (_, value) {
          const chineseRegex = /[\u4e00-\u9fa5]/g;
          const chineseCount = (value.match(chineseRegex) || []).length;
          const totalLength = value.length + chineseCount;
          return totalLength > 200 ? Promise.reject(new Error(i18n.t('frontOfHouse_pc_maximum', { length: 200 }))) : Promise.resolve();
        },
      }),
    ],
    savings: [
      { required: true, message: i18n.t('please_enter_x', { msg: i18n.t('savings') }) },
      () => ({
        validator (_, value) {
          return (value > 0 || isNil(value)) ? Promise.resolve() : Promise.reject(new Error(i18n.t('save_amount')))
        },
      }),
    ],
    dates: [{ required: true, message: i18n.t('please_select_x', { msg: i18n.t('pc_employee_report_time_range') }) }]
  }

  const fetchDetail = async () => {
    const resp: any = await ApiQueryDiscountDetails({ uuids: [editItem.uuid] })
    if (resp.code !== 0) return;
    const item = resp?.data?.[0];
    setEditItemInfo(item)
    form.setFieldsValue({
      ...item,
      discountType: item.discountType.value,
      dates: item.permanent ? [] : [dayjs(item.standardStartTime), dayjs(item.standardEndTime)],
      discountPercent: Number(item.discountPercent) > 0 ? NP.times(item.discountPercent, 100) : 0
    })
    setLoading(false);
  }

  const handleCancel = () => {
    close();
  }

  const handleSave = async () => {
    const [err, formParams] = await to(form.validateFields());
    if (err) return;
    const formatObj = { format: 'YYYY-MM-DD HH:mm:ss' };
    const params = {
      activitys: [
        {
          ...editItemInfo,
          ...formParams,
          discountType: { value: formParams.discountType },
          dates: formParams.permanent ? [] : [
            formParams.dates[0].valueOf(),
            formParams.dates[1].valueOf()
          ],
          startTime: formParams.permanent ? null : formatTimestampWithoutTimeZone(formParams.dates[0].valueOf(), formatObj),
          standardStartTime: formParams.permanent ? null : formatTimestampWithoutTimeZone(formParams.dates[0].valueOf(), formatObj),
          endTime: formParams.permanent ? null : formatTimestampWithoutTimeZone(formParams.dates[1].valueOf(), formatObj),
          standardEndTime: formParams.permanent ? null : formatTimestampWithoutTimeZone(formParams.dates[1].valueOf(), formatObj),
          discountAmount: formParams.discountType === DISCOUNT_TYPE.FIXED ? formParams.discountAmount : 0,
          discountPercent: formParams.discountType === DISCOUNT_TYPE.PERCENT ? NP.divide(formParams.discountPercent, 100) : 0,
          enableDiscountReasons: formParams.enableDiscountReasons
        }
      ]
    };
    setLoading(true);
    const resp: any = await ApiBatchSaveDiscountActivitys(params);
    setLoading(false)
    if (resp.code !== 0) return;
    message.success(i18n.t('message_success'));
    fetchTableData();
    close();
  }

  return (
    <Spin spinning={ loading }>
      <div className="promotion-edit">
        <div className="header-wrap">
          <div className="title">{ isEdit ? i18n.t('discountManage_pc_edit_offer') : i18n.t('discountManage_pc_create_new') }</div>
          <div className="actions">
            <Button onClick={ handleCancel }>{ i18n?.t('cancel') }</Button>
            <Button type="primary" onClick={ handleSave }>{ i18n?.t('save') }</Button>
          </div>
        </div>
        <Form form={ form } layout="vertical" initialValues={ initialValues } requiredMark={ false }>
          <div className="form-wrap">
            <Form.Item name={ 'name' } label={ <div>{ i18n.t('discountManage_pc_offer_name') }<span className="required">*</span></div> } rules={ formRules.name }>
              <Input
                width={ 400 }
                placeholder={ i18n.t('enter_x', { msg: i18n.t('discountManage_pc_offer_name') }) }
                onBlur={ (e) => form.setFieldValue('name', e?.target?.value?.trim?.() ?? '') }
                disabled={[DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE, DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT].includes(form.getFieldValue('discountType'))}
              />
            </Form.Item>
            <Form.Item name="discountType" label={ <div>{ i18n.t('discountManage_pc_offer_type') }<span className="required">*</span></div> }>
              <Radio.Group
                options={ [
                  { value: DISCOUNT_TYPE.FIXED, label: i18n.t(DISCOUNT_TYPE_I18N[DISCOUNT_TYPE.FIXED]) },
                  { value: DISCOUNT_TYPE.PERCENT, label: i18n.t(DISCOUNT_TYPE_I18N[DISCOUNT_TYPE.PERCENT]) }]
                }
                disabled={[DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE, DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT].includes(form.getFieldValue('discountType'))}
              />
            </Form.Item>
            {
              discountTypeWatched === DISCOUNT_TYPE.FIXED &&
              <Form.Item name={ 'discountAmount' } label={ <div>{ i18n.t('savings') }<span className="required">*</span></div> } rules={ formRules.savings }>
                <InputNumber
                  step={ '1.00' }
                  min={ 0 }
                  precision={ 2 }
                  addonBefore={ '$' }
                  stringMode
                  disabled={[DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE, DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT].includes(form.getFieldValue('discountType'))}
                />
              </Form.Item>
            }
            {
              discountTypeWatched === DISCOUNT_TYPE.PERCENT &&
              <Form.Item name={ 'discountPercent' } label={ <div>{ i18n.t('savings') }<span className="required">*</span></div> } rules={ formRules.savings }>
                <InputNumber
                  step={ '1' }
                  min={ 0 }
                  precision={ 0 }
                  max={ 100 }
                  addonAfter="%"
                  stringMode
                  disabled={[DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE, DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT].includes(form.getFieldValue('discountType'))}
                />
              </Form.Item>
            }
            <Form.Item name="permanent" label={ <div>{ i18n.t('discountManage_pc_dates_available') }<span className="required">*</span></div> }>
              <Radio.Group
                rootClassName="rounding-type-radio-group" optionType="button"
                options={ [{ value: true, label: i18n.t('discountManage_pc_permanent') }, { value: false, label: i18n.t('discountManage_pc_specific_date') }] }
                disabled={[DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE, DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT].includes(form.getFieldValue('discountType'))}
              />
            </Form.Item>
            {
              !permanentWatched && <Form.Item name="dates" rules={ formRules.dates }>
                <PepprDatePicker
                  showTime={ { format: 'HH:mm', defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] } }
                  format="MM/DD/YYYY HH:mm"
                  presets={ [] }
                  style={ { width: 'auto' } }
                  disabled={[DISCOUNT_TYPE.OPEN_DISCOUNT_PERCENTAGE, DISCOUNT_TYPE.OPEN_DISCOUNT_FIXED_AMOUNT].includes(form.getFieldValue('discountType'))}
                />
              </Form.Item>
            }

          </div>

          <div className='form-wrap'>
            <Form.Item name="enableDiscountReasons" valuePropName="checked" label={ <div>{ i18n.t('dashboard_notes_discount_reasons') }<span className="required">*</span></div> }>
              <Checkbox>{ i18n.t('dashboard_promotion_discount_reasons_setting') }</Checkbox>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Spin>
  )
}