import { pepprService } from '@/request/index'

export interface Response<T = unknown> {
    code: number
    message?: string
    msg?: string
    success?: boolean
    data: T
}

export interface IResetEmail{
    newEmail: string
    oldEmail: string
    verificationCode: string
}

export interface IGetVerificationCode{
    email: string
    type: 1 | 2 // 1:重置密码 2:修改邮箱
}

export interface ICheckPassword{
    accountId: number
    password: string
}

export interface IResetPwdByVerificationCode{
    email: string;
    password: string;
    verificationCode: string;
}

/**
 * 邮箱获取验证码
 * @param params
 */
export const getVerificationCode = async (params: IGetVerificationCode): Promise<boolean> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/account/getVerificationCode', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 验证码修改邮箱
 * @param params
 */
export const resetEmailByVerificationCode = async (params: IResetEmail): Promise<boolean | undefined> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/account/resetEmailByVerificationCode', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 密码校验
 */
export const doCheckPassword = async (params: ICheckPassword): Promise<boolean | undefined> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/account/checkPassword', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
}

/**
 * 验证码修改密码
 */
export const resetPwdByVerificationCode = async (params: IResetPwdByVerificationCode): Promise<boolean | undefined> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/account/resetPwdByVerificationCode', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 获取登录信息
 * @returns
 */
export const getLoginInfo = async (): Promise<any> => {
  const res: Response<any> = await pepprService.post('/api/peppr-employee/login/getLoginInfo');
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 通过旧密码修改新密码
 */
export const updatePwdByLastPwd = async (params: { oldPassword: string, newPassword: string, accountId: number }): Promise<boolean | undefined> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/account/updatePwdByLastPwd', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};