import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd'
import { globalState } from '@/stores';
import Balance from '@/Pages/GiftCard/Balance';
import Summary from '@/Pages/GiftCard/Summary';
import './index.scss'

export default function GiftCard (props) {
  const { i18n } = globalState;
  const [currentTab, setCurrentTab] = useState('summary');

  const items = [
    {
      key: 'summary',
      label: i18n.t('dashboard_report_gift_card_summary'),
      children: <Summary  />
    },
    {
      key: 'balance',
      label: i18n.t('dashboard_report_gift_card_balance'),
      children: <Balance  />
    },
  ]

  const onChange = (value) => {
    setCurrentTab(value);
  }

  const tabBarExtraContent = {
    left: <div className="main-title">{ i18n.t('overseas_peppr_entrance_giftcard') }</div>,
  }

  return (
    <div className="gift-card">
      <Tabs destroyInactiveTabPane tabBarExtraContent={ tabBarExtraContent } activeKey={currentTab} items={items} onChange={onChange} />
    </div>
  )
}