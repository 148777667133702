import { service } from '@/request/index';
type IAddParams = {
  dayPartName: string;
  startTime: number;
  endTime: number;
};
type IUpdateParams = {
  id: number;
  dayPartName: string;
  startTime: number;
  endTime: number;
};
type IUpdateStatusParams = {
  ids: number[];
  archived: 0 | 1;
};
export const ApiQueryDayPartList: any = () =>
  service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryDayPartList');
export const ApiAddDayPart: any = (params: IAddParams) =>
  service.post(
    '/rms/overseas/MerchantManagementConsole/FrontOfHouse/addDayPart',
    params
  );
export const ApiUpdateDayPartById: any = (params: IUpdateParams) =>
  service.post(
    '/rms/overseas/MerchantManagementConsole/FrontOfHouse/updateDayPartById',
    params
  );
export const ApiUpdateDayPartStatusByIds: any = (params: IUpdateStatusParams) =>
  service.post(
    '/rms/overseas/MerchantManagementConsole/FrontOfHouse/updateDayPartStatusByIds',
    params
  );
