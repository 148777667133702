/**
 * 合并两个对象数组，如果两个数组中的对象都有值，使用 A 的值
 * @param arrayA 数组A
 * @param arrayB 数组B
 * @returns 合并后的数组
 */
export const fnMergeShiftReportData = <T extends object>(
  arrayA: T[],
  arrayB: T[]
): T[] => {
  const mergedArray: T[] = [];
  const maxLength = Math.max(arrayA.length, arrayB.length);

  for (let i = 0; i < maxLength; i++) {
    const itemA = arrayA[i];
    const itemB = arrayB[i];

    if (itemA && itemB) {
      // 如果两个数组都有值，使用 A 的值
      mergedArray.push({ ...itemB, ...itemA });
    } else if (itemA) {
      // 如果只有 A 有值，使用 A 的值
      mergedArray.push(itemA);
    } else if (itemB) {
      // 如果只有 B 有值，使用 B 的值
      mergedArray.push(itemB);
    }
  }

  return mergedArray;
};
