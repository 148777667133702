import React, { useState, useEffect, forwardRef, ForwardRefRenderFunction } from 'react';
import './index.scss';
import Block from './component/Block';
import { defaultCheckMapData } from '../defaultJsonData/defaultCheck';
import { ConfigTypeValue, IBaseDefaultChildrenData, IBaseDefaultMapData, ITag } from '../interface';
import { observer } from 'mobx-react';
import { toJS, autorun } from 'mobx';
import printOutDecorationStore from '../store';
import { configDataOptions } from '../mapData/typeMapConfigDataOptions';
import { renderChildComponent } from '../mapData/configTagChildComponent';
import SortableItem from '../component/SortableItem';
import DraggableContainer from '../component/DraggableContainer';
import { updatePreviewOrderAfterLeftDrag } from '../utils/fnUpdatePreviewOrderAfterLeftDrag';

interface ConfigUIContainerProps {}

const renderChild = (sectionChildren: Array<IBaseDefaultChildrenData<ITag>>, sectionId?: string) => {
  return sectionChildren.map((child, index) => {
    const ChildComponent = renderChildComponent[child.tag];
    return ChildComponent ? <ChildComponent key={index} content={child} sectionId={sectionId} /> : null;
  });
};


const ConfigUIContainer: ForwardRefRenderFunction<HTMLDivElement, ConfigUIContainerProps> = (_, ref) => {
  const [configData, setConfigData] = useState<Array<IBaseDefaultMapData<ITag>>>(defaultCheckMapData);

  const [configType, setConfigType] = useState<ConfigTypeValue>('Check');


  const [activeIdAndOverId, setActiveIdAndOverIde] = useState<{ activeId: string, overId: string  }>({ activeId: null, overId: null  });

  // 左侧需要设置为可拖拽的配置类型
  const isSettingConfigDraggable: Array<ConfigTypeValue> = ['ShiftReport']

  // 实时监听config变化的值
  useEffect(() => {
    let typePramsInUrl: ConfigTypeValue = 'Check';
    let settingConfigData: Array<IBaseDefaultMapData<ITag>> = [];

    const dispose = autorun(() => {
      typePramsInUrl = toJS(printOutDecorationStore.getUrlParamsType);
      settingConfigData = toJS(printOutDecorationStore.settingConfigData);
      const initSettingData = settingConfigData?.length > 0 ? settingConfigData : configDataOptions[typePramsInUrl];
      setConfigType(typePramsInUrl);
      setConfigData(initSettingData);
    });


    return () => dispose();
  }, []);


  useEffect(() => {
    updatePreviewOrderAfterLeftDrag(activeIdAndOverId);
    printOutDecorationStore.saveSettingConfigData(configData)
  }, [activeIdAndOverId])



  const renderBlockItems = () => (
    <div className='config-ui-container' ref={ref}>
      {configData.map(sectionItem => (
        !sectionItem?.modifier?.isHideConfig && (
          <SortableItem key={sectionItem.id} id={sectionItem.id}>
            <Block content={sectionItem}>
              {sectionItem.children && renderChild(sectionItem.children, sectionItem.id)}
            </Block>
          </SortableItem>
        )
      ))}
    </div>
  );


  const getDragActiveIdAndOverId = (activeId, overId)  => {
    setActiveIdAndOverIde({ activeId, overId });
  }



  return (
    isSettingConfigDraggable.includes(configType)
      ?
      <DraggableContainer
        sortData={configData}
        getCurrentSortData={(newSortData) => setConfigData(newSortData)}
        getDragActiveIdAndOverId={getDragActiveIdAndOverId}
      >
        {renderBlockItems()}
      </DraggableContainer>
      :
      renderBlockItems()
  )
}

export default observer(forwardRef(ConfigUIContainer));


