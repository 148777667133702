import React, { useEffect, useState } from 'react';
import BreadCrumb from '@/Pages/Menus/component/BreadCrumb';
import { globalState } from '@/stores';
import { Button, Form, Input, message, Modal, Spin } from 'antd';
import './index.scss';
import ColorFilter from '@/Pages/Menus/component/ColorFilter';
import { DeleteOutlined } from '@ant-design/icons';
import DragTable, { DragHandle } from '@/Components/DragTable';
import {
  ApiSavePreModifierGroup,
  ApiUpdatePreModifierGroup,
} from '@/request/PreModifier';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPageType } from '../interface';
import { arrayMove } from '@dnd-kit/sortable';

export default function EditPreModifier (props) {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const location = useLocation();

  const { rowPreModifierInfo, pageType } = location.state || {};

  const [loading, setLoading] = useState<boolean>(false)
  const [tableData, setTableData] = useState([]);
  const [currentPageType, setCurrentPageType] = useState<IPageType>('add');
  const [form] = Form.useForm();

  const tableColumns = [
    {
      key: 'sort',
      align: 'center',
      width: 50,
      render: () => <DragHandle />,
    },
    {
      title: (
        <div>
          {i18n.t('dashboard_menu_pre_modifier_name')}
          <span style={{ color: '#ff4d4f' }}> *</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any, index: number) => (
        <Form.Item
          name={['preModifiers', index, 'name']}
          shouldUpdate
          rules={[
            () => ({
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(
                    i18n.t('dashboard_menu_pre_modifier_name_is_required')
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            value={record.name}
            onChange={(e) => {
              const newData = [...tableData];
              newData[index].name = e.target.value;
              setTableData(newData);
            }}
            maxLength={100}
          />
        </Form.Item>
      ),
    },
    {
      title: i18n.t('dashboard_menus_pos_button_color'),
      dataIndex: 'posColor',
      key: 'posColor',
      render: (text: string, record: any, index: number) => (
        <Form.Item name={['preModifiers', index, 'posColor']} shouldUpdate>
          <ColorFilter
            colorText={record.posColor}
            handleChangeColorItem={(colorText: string) => {
              const newData = [...tableData];
              newData[index].posColor = colorText;
              setTableData(newData);
            }}
          />
        </Form.Item>
      ),
      width: 150,
    },
    {
      title: i18n.t('dashboard_menu_pos_display_name'),
      dataIndex: 'shortName',
      key: 'shortName',
      render: (text: string, record: any, index: number) => (
        <Form.Item name={['preModifiers', index, 'shortName']} shouldUpdate>
          <Input
            value={record.shortName}
            onChange={(e) => {
              const newData = [...tableData];
              newData[index].shortName = e.target.value;
              setTableData(newData);
            }}
            maxLength={100}
          />
        </Form.Item>
      ),
    },
    {
      title: i18n.t('dashboard_menu_kitchen_display_name'),
      dataIndex: 'foreignName',
      key: 'foreignName',
      render: (text: string, record: any, index: number) => (
        <Form.Item name={['preModifiers', index, 'foreignName']} shouldUpdate>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={record.foreignName}
              style={{ flex: 1 }}
              onChange={(e) => {
                const newData = [...tableData];
                newData[index].foreignName = e.target.value;
                setTableData(newData);
              }}
              maxLength={100}
            />
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => {
                const newData = [...tableData];
                newData.splice(index, 1);
                newData.forEach((item, idx) => {
                  item.displayOrder = idx;
                });
                setTableData(newData);
              }}
              style={{ marginLeft: 8 }}
            />
          </div>
        </Form.Item>
      ),
    },
  ];

  const addRow = () => {
    const newRow = {
      displayOrder: tableData.length,
      name: '',
      posColor: '',
      shortName: '',
      foreignName: '',
    };
    setTableData([...tableData, newRow]);
  };

  const cancelPreModifierGroup = () => {
    navigate('/menus/preModifier');
  };

  const savePreModifierGroup = async () => {
    try {
      // 触发表单校验
      await form.validateFields();

      const { name } = form.getFieldsValue();
      const params = {
        name,
        uuid: rowPreModifierInfo?.uuid || null,
        preModifiers: tableData.map((item, index) => ({
          ...item,
          displayOrder: index,
        })),
      };

      // 根据当前页面类型进行保存或更新操作
      setLoading(true);
      switch (currentPageType) {
      case 'add':
        const addRes = await ApiSavePreModifierGroup({ ...params });
        setLoading(false)
        if (addRes.code === 0) {
          message.success(i18n.t('message_success'));
          navigate('/menus/preModifier');
        }
        break;

      case 'edit':
        const editRes = await ApiUpdatePreModifierGroup({ ...params });
        setLoading(false)
        if (editRes.code === 0) {
          message.success(i18n.t('message_success'));
          navigate('/menus/preModifier');
        }
        break;

      default:
        break;
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.displayOrder === active.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.displayOrder === over.id
        );
        return arrayMove(prevState, activeIndex, overIndex).map(
          (item, index) => ({
            ...item,
            displayOrder: index + 1,
          })
        );
      });
    }
  };

  useEffect(() => {
    setCurrentPageType(pageType || 'add');
    setTableData(rowPreModifierInfo?.preModifiers || []);
    form.setFieldsValue({
      name: rowPreModifierInfo?.name || '',
    });
  }, [rowPreModifierInfo]);

  useEffect(() => {
    form.setFieldsValue({ preModifiers: tableData || [] });
    console.log('tableData===>', tableData)
  }, [tableData]);


  return (
    <Spin spinning={ loading }>
      <div className="pre-modifier-edit">
        <div>
          <BreadCrumb
            slotAction={
              <div>
                <Button
                  style={{ width: 88, marginRight: 15 }}
                  onClick={cancelPreModifierGroup}
                >
                  {i18n.t('cancel')}
                </Button>
                <Button
                  style={{ width: 88 }}
                  type="primary"
                  onClick={savePreModifierGroup}
                >
                  {i18n.t('save')}
                </Button>
              </div>
            }
            needConfirm
            customTitle={[
              {
                title: i18n.t('dashboard_menu_pre_modifier'),
                router: '/menus/preModifier',
              },
              {
                title:
                pageType === 'add'
                  ? i18n.t('dashboard_menu_pre_modifier_group_add_group_button')
                  : rowPreModifierInfo?.name,
              },
            ]}
          />
        </div>

        <div className="content">
          <Form form={form} style={{ width: '100%' }}>
            <div className="card">
              <Form.Item
                label={
                  <div>
                    <span>
                      {i18n.t('dashboard_menu_pre_modifier_group_name')}
                    </span>
                    <span style={{ color: '#ff4d4f' }}> * </span>
                  </div>
                }
                name="name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(
                          i18n.t('dashboard_menu_pre_modifier_name_is_required')
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input maxLength={100} />
              </Form.Item>
            </div>

            <div className="card table-area">
              <div className="table-btn">
                <div>{i18n.t('dashboard_menu_pre_modifier')}</div>
                <Button onClick={addRow}>
                + {i18n.t('dashboard_menu_pre_modifier_add_button')}
                </Button>
              </div>
              <DragTable
                loading={false}
                onDragEnd={onDragEnd}
                rowKey={'displayOrder'}
                onChange={() => {}}
                columns={tableColumns as any}
                dataSource={tableData}
                locale={{ emptyText: i18n.t('dashboard_menu_pre_modifier_empty') }}
              ></DragTable>
            </div>
          </Form>
        </div>
      </div>
    </Spin>

  );
}
