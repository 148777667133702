import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { globalState } from '@/stores';
import './index.scss'
import Price from '../components/Price';
import classNames from 'classnames';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { TableEmptyContent } from '@/Components/EmptyContent';

export default function SalesChannel ({ reportData }) {
  const { i18n } = globalState;
  const [tableData, setTableData] = useState([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState <boolean>(false)
  const [tableColumns, setTableColumns] = useState([])
  const [showColumnsList, setShowColumnsList] = useState([])

  useEffect(() => {
    const _tableData = reportData?.salesSourceSalesList || [];
    const hasCashDiscount = _tableData.length > 0 && _tableData.some(x => + x.cashDiscount > 0)
    const _tableColumns = [
      {
        title: i18n.t('pc_product_mix_sales_channel'),
        dataIndex: 'salesChannelName',
        width: 180,
        render: (text, record) => {
          return record.children ? text : record.salesSourceName || text
        }
      }, {
        title: i18n.t('pc_sales_summary_items'),
        dataIndex: 'itemActualCount',
        width: 80
      }, {
        title: i18n.t('pc_sales_summary_total_sales'),
        dataIndex: 'totalSales',
        width: 120,
        render: (val) => <Price value={val}/>
      }, {
        title: i18n.t('pc_sales_summary_sales_voids'),
        dataIndex: 'salesVoids',
        width: 120,
        render: (val) => <Price value={val}/>
      }, {
        title: i18n.t('pc_sales_summary_gross_sales'),
        dataIndex: 'grossSales',
        width: 120,
        render: (val) => <Price value={val}/>
      },
      hasCashDiscount && {
        title: i18n.t('pc_sales_summary_cash_discount'),
        dataIndex: 'cashDiscount',
        width: 140,
        render: (val) => <Price value={val}/>
      }, {
        title: i18n.t('pc_employee_report_tabs_discount'),
        dataIndex: 'discounts',
        width: 140,
        render: (val) => <Price value={val}/>
      },
      {
        title: i18n.t('pc_sales_summary_sales_discounts'),
        dataIndex: 'salesComps',
        width: 140,
        render: (val) => <Price value={val}/>
      }, {
        title: i18n.t('pc_sales_summary_sales_refunds'),
        dataIndex: 'refunds',
        width: 130,
        render: (val) => <Price value={val}/>
      }, {
        title: i18n.t('pc_sales_summary_net_sales'),
        dataIndex: 'netSales',
        width: 150,
        render: (val) => <Price value={val}/>
      }
    ].filter(x => x)
    setTableColumns(_tableColumns)
    setShowColumnsList(_tableColumns.map(x => x.dataIndex))
    console.log('_tableData', _tableData);
    setTableData(_tableData)
  }, [reportData]);

  const handleChangeColumns = (showColumnsList) => {
    setIsShowEmptyTable(showColumnsList.length === 0)
    setShowColumnsList(showColumnsList);
  }

  return (
    <div className="sales-summary-net-sales-list">
      <div className="header-wrap">
        <div className="sales-list-title-wrap">
          <span>{i18n.t('pc_product_mix_sales_channel')}</span>
        </div>
        <div className="actions-wrap">
          <Columns
            value={showColumnsList}
            options={tableColumns.map(item => ({ label: item.title, value: item.dataIndex }))}
            onChange={handleChangeColumns}/>
        </div>

      </div>
      <div
        className={classNames('net-sales-list-table-wrap', {
          'no-data': tableData.length === 0
        })}>
        <Table
          className={isShowEmptyTable && 'empty-table'}
          columns={tableColumns.filter(x => showColumnsList.includes(x.dataIndex))as ColumnsType}
          dataSource={tableData.map((x, i) => {
            return {
              ...x,
              key: i,
              children: x.children?.map((y, j) => ({ ...y, key: `${i}-${j}` }))
            }
          })}
          rowClassName={(_) => {
            return classNames('row', {
              'sticky-row': _.salesChannelName === 'Total'
            })}
          }
          scroll={{
            y: 410,
            x: '100%'
          }}
          pagination={false}
          summary={() => (isShowEmptyTable && <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={999}>
              {TableEmptyContent}
            </Table.Summary.Cell>
          </Table.Summary.Row>)}/>
      </div>
    </div>
  )
}