import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card, Flex, Form, Select, Switch, Typography } from 'antd'
import { globalState } from '@/stores';
import { IOptions } from '../interface'
import { getQuerys } from '@/utils'

import { queryActiveSalesCategoryList } from '@/request/Menus'
import './ModifierReport.scss'
import { set } from 'mobx';
const { Paragraph } = Typography;


interface IProps {
  form
}

const ModifierReport = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState

  const {
    form
  } = props

  const [allReportOptions, setAllReportOptions] = useState<Array<IOptions>>([]);
  const inheritItemSalesCategory = Form.useWatch('inheritItemSalesCategory', form);
  useImperativeHandle(ref, () => {
    return {
      form,
    }
  }, [form])

  const changSwitch = (value) => {
    form.setFieldsValue({ inheritItemSalesCategory: value })
  }

  const onChange = (value) => {
    form.setFieldsValue({ inheritItemSalesCategory: false })
    form.setFieldsValue({ salesCategories: value })
  }

  useEffect(() => {
    queryActiveSalesCategoryList().then((res) => {
      if (res.code === 0) {
        const options = (res.data || []).map(item => ({
          value: item.id,
          label: item.name
        }));
        setAllReportOptions(options)
      }
    })
  }, [])

  return (
    <div className='modifier-report'>
      <Paragraph strong>{i18n?.t('pc_reports')}</Paragraph>
      <Form form={form} layout="vertical">
        {
          <Flex align='center'>
            <Form.Item name="inheritItemSalesCategory">
              <Switch
                onChange={changSwitch}
                style={{ marginRight: 5 }}
              />
            </Form.Item>
            <span>{`${i18n.t('pc_inherit')} ${i18n.t('pc_orderDetail_item')}`}</span>
          </Flex>
        }
        {!inheritItemSalesCategory && <Form.Item name="salesCategoryId" label={i18n.t('pc_sales_summary_sales_category')} rules={[
          { required: !inheritItemSalesCategory, message: i18n.t('pc_select_sales_category') }
        ]}>
          <Select
            style={{ width: 250 }}
            options={allReportOptions}
            placeholder={i18n.t('pc_select_sales_category')}
            allowClear
            onChange={onChange}
          />
        </Form.Item>}
      </Form>
    </div>
  )

})

export default ModifierReport