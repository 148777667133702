import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { globalState } from '@/stores';
import './index.scss'
import Price from '../components/Price';
import classNames from 'classnames';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { TableEmptyContent } from '@/Components/EmptyContent';

export default function SalesCategoryList ({ reportData }) {
  const { i18n } = globalState;
  const [tableData, setTableData] = useState([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [tableColumns, setTableColumns] = useState([])
  const [showColumnsList, setShowColumnsList] = useState([])

  useEffect(() => {
    const _tableData = reportData?.salesCategoryStatsList || [];
    const hasCashDiscount = _tableData.length > 0 && _tableData.some(x => +x.cashDiscount > 0)
    const _tableColumns = [
      {
        title: i18n.t('pc_sales_summary_sales_category'), dataIndex: 'salesCategoryName', width: 220,
        render: (val, row) => row.salesCategoryId === '-1' ? i18n.t('pc_sales_summary_no_sales_category_assigned') : val
      },
      { title: i18n.t('pc_sales_summary_items'), dataIndex: 'itemActualCount', width: 80 },
      { title: i18n.t('pc_sales_summary_total_sales'), dataIndex: 'totalSales', width: 120, render: val => <Price value={ val }/> },
      { title: i18n.t('pc_sales_summary_sales_voids'), dataIndex: 'salesVoids', width: 120, render: val => <Price value={ val }/> },
      { title: i18n.t('pc_sales_summary_gross_sales'), dataIndex: 'grossSales', width: 120, render: val => <Price value={ val }/> },
      hasCashDiscount && { title: i18n.t('pc_sales_summary_cash_discount'), dataIndex: 'cashDiscount', width: 140, render: (val) => <Price value={ val }/> },
      { title: i18n.t('pc_employee_report_tabs_discount'), dataIndex: 'discounts', width: 140, render: val => <Price value={ val }/> },
      { title: i18n.t('pc_sales_summary_sales_discounts'), dataIndex: 'salesComps', width: 140, render: val => <Price value={ val }/> },
      { title: i18n.t('pc_sales_summary_sales_refunds'), dataIndex: 'refunds', width: 140, render: val => <Price value={ val }/> },
      { title: i18n.t('pc_orders_Tax'), dataIndex: 'tax', width: 140, render: val => <Price value={ val }/> },
      { title: i18n.t('pc_sales_summary_net_sales'), dataIndex: 'netSales', width: 120, render: val => <Price value={ val }/> },
    ].filter(x => x)
    setTableColumns(_tableColumns)
    setShowColumnsList(_tableColumns.map(x => x.dataIndex))
    setTableData(_tableData)
  }, [reportData]);

  const handleChangeColumns = (showColumnsList) => {
    setIsShowEmptyTable(showColumnsList.length === 0)
    setShowColumnsList(showColumnsList);
  }

  return (
    <div className="sales-summary-sales-category-list">
      <div className="header-wrap">
        <div className="sales-list-title-wrap">
          <span>{ i18n.t('pc_sales_summary_sales_category') }</span>
        </div>
        <div className="actions-wrap">
          <Columns value={ showColumnsList } options={ tableColumns.map(item => ({ label: item.title, value: item.dataIndex })) } onChange={ handleChangeColumns }/>
        </div>

      </div>
      <div className={ classNames('net-sales-list-table-wrap', { 'no-data': tableData.length === 0 }) }>
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowClassName={ (_, index) => classNames('row', { 'sticky-row': index === tableData.length - 1 }) }
          scroll={ { y: 410, x: 'max-content' } }
          pagination={ false }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
        />
      </div>
    </div>
  )
}