import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Card, Form, Radio, Checkbox, Switch, Popover, Row, Col } from 'antd'
import { globalState } from '@/stores';
import './ChannelAvailability.scss'
import store from '@/stores/userInfo';
import { checkPermission } from '@/utils/permissionUtils';
import { InfoCircleOutlined } from '@ant-design/icons';
interface IProps {
  editInfo: {
    visibleChannelPOS: boolean
    onlineOrdering: boolean
    doordashVisible: boolean
    isRestrictedToServiceArea?: boolean
    qrScanVisible: boolean
    serviceAreaUuids?: string[]
  },
  pricingType?: {
    displayName: string,
    value: string
  },
  serviceAreasList?: IServiceArea[]
}
interface IServiceArea {
  id: number
  areaName: string
  uuid: string
}
const ChannelAvailability = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState;
  const {
    editInfo: { visibleChannelPOS, qrScanVisible, onlineOrdering, doordashVisible, isRestrictedToServiceArea, serviceAreaUuids },
    serviceAreasList = null,
    pricingType = null
  } = props
  const [onlineOrderingDisabled, setOnlineOrderingDisabled] = useState<boolean>(false);
  const [visibleChannelPOSValue, setVisibleChannelPOSValue] = useState<boolean>(true);
  const [form] = Form.useForm();
  const checkedServiceArea = Form.useWatch('serviceAreaUuids', form);
  const restrictedToServiceAreaSwitch = Form.useWatch('isRestrictedToServiceArea', form);
  const watchVisibleChannelPOS = Form.useWatch('visibleChannelPOS', form);
  const [lastCheckedServiceArea, setLastCheckedServiceArea] = useState(null);
  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      form
    };
  }, []);

  const changePOSChecked = (val) => {
    setVisibleChannelPOSValue(val);
    if (!val) {
      form.setFieldsValue({ 'qrScanVisible': val });
    }
    form.setFieldsValue({ 'visibleChannelPOS': val });
  }
  const changeSwitchPOS = (val) => {
    // setVisibleChannelPOSSwitch(val);
    const allServiceArea = val ? serviceAreasList.map(item => item.uuid) : [];
    form.setFieldsValue({ 'serviceAreaUuids': allServiceArea });
    setIsShowErrorMessage(false);
  }
  const changeCheckedServiceArea = (val) => {
    if (val.length === 0) {
      setIsShowErrorMessage(true);
      form.setFieldsValue({ 'serviceAreaUuids': [lastCheckedServiceArea] });
      return;
    } else {
      setIsShowErrorMessage(false);
    }
  }
  useEffect(() => {
    form.setFieldsValue({
      'visibleChannelPOS': visibleChannelPOS ?? true,
      'onlineOrdering': (onlineOrdering ?? (pricingType === null ? true : false)),
      'doordashVisible': doordashVisible ?? true,
      'qrScanVisible': qrScanVisible ?? ((pricingType?.value === 'openPrice') ? false : (visibleChannelPOS ?? true)),
      'isRestrictedToServiceArea': isRestrictedToServiceArea ?? false,
      'serviceAreaUuids': serviceAreaUuids ?? []
    });
    // setVisibleChannelPOSSwitch(isRestrictedToServiceArea);
    setVisibleChannelPOSValue(visibleChannelPOS ?? true);
  }, [visibleChannelPOS, onlineOrdering, doordashVisible, serviceAreaUuids, qrScanVisible])

  useEffect(() => {
    const { pricingType } = props;
    if (pricingType) {
      const state = pricingType.value === 'openPrice';
      setOnlineOrderingDisabled(state);
      if (state) {
        form.setFieldValue('onlineOrdering', false);
        form.setFieldValue('qrScanVisible', false);
        form.setFieldValue('doordashVisible', false);
      }
    }
  }, [pricingType])
  useEffect(() => {
    if (checkedServiceArea?.length === 1) {
      setLastCheckedServiceArea(checkedServiceArea[0]);
    }
  }, [checkedServiceArea])
  return (
    <div className='sub-menus-channel-availability'>
      <Card title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 10 }}>{i18n.t('visible_channel')}</span>
        </div>
      } >
        <Form form={form} layout='vertical'>
          <Form.Item name='visibleChannelPOS' valuePropName='checked'>
            <Checkbox  onChange={(e) => changePOSChecked(e.target.checked) }>{i18n.t('rms_overseas_management_restaurant_org_saleChannel_POS')}</Checkbox>
          </Form.Item>
          {serviceAreasList && serviceAreasList.length > 0 && visibleChannelPOSValue && (
            <div className='pos-service-area'>
              <div className='switch-box'>
                <Form.Item name='isRestrictedToServiceArea' valuePropName='checked'>
                  <Switch onChange={changeSwitchPOS} />
                </Form.Item>
                <span className='text'>{i18n.t('dashboard_menu_serviceArea') || 'Set service area availability'}</span>
                <Popover
                  arrow={false}
                  trigger='hover'
                  placement='bottom'
                  content={
                    <div className='popover-content' style={{ width:'345px', color: '#676768' }}>{i18n.t('dashboard_menu_serviceArea_tooltip')}</div>
                  }
                >
                  <InfoCircleOutlined size={16} style={{ color: '#777' }} />
                </Popover>
              </div>
              {restrictedToServiceAreaSwitch && (<div className='service-area'>
                <Form.Item name='serviceAreaUuids'
                  help={isShowErrorMessage ? i18n.t('dashboard_menu_serviceArea_error') : ''}
                  validateStatus={isShowErrorMessage ? 'error' : ''}
                >
                  <Checkbox.Group onChange={changeCheckedServiceArea} style={{ marginLeft:'-12px' }}>
                    <Row gutter={[{ xs: 8, sm: 16, md: 20, lg: 24 }, { xs: 8, sm: 16, md: 20, lg: 24 }]}>
                      {serviceAreasList.map((item, index) => {
                        const key = `col-${index}`
                        return (
                          <Col
                            key={key}
                            xs={{ flex: '100%' }}
                            sm={{ flex: '50%' }}
                            md={{ flex: '33.333%' }}
                            lg={{ flex: '25%' }}
                            xl={{ flex: '20%' }}
                          >
                            <Checkbox
                              value={item.uuid}
                            >{item.areaName}</Checkbox>
                          </Col>
                        )
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </div>)
              }
            </div>
          )}
          {<Form.Item style={{ display: checkPermission('QR_CODE') ? 'block' : 'none' }} name='qrScanVisible' valuePropName='checked'>
            <Checkbox disabled={!watchVisibleChannelPOS || onlineOrderingDisabled}>
              <div className='text'>
                { i18n?.t('CHANNEL_QR_CODE') }
                <Popover
                  arrow={false}
                  trigger='hover'
                  placement='bottom'
                  content={<div className='popover-content' style={{ width:'345px', color: '#676768' }}>{i18n.t('CHANNEL_QR_CODE_VISIBILITY_TOOLTIP')}</div>}>
                  <InfoCircleOutlined size={16} style={{ color: '#777', marginLeft: '10px' }}  />
                </Popover>
              </div>
            </Checkbox>
          </Form.Item>}
          {
            <Form.Item style={{ display: checkPermission('ONLINE_PICK_UP') ? 'block' : 'none' }} name='onlineOrdering' valuePropName='checked'>
              <Checkbox disabled={onlineOrderingDisabled}>{ i18n?.t('menu_online_ordering') }</Checkbox>
            </Form.Item>
          }
          {
            <Form.Item style={{ display: checkPermission('ONLINE_DOORDASH') ? 'block' : 'none' }} name='doordashVisible' valuePropName='checked'>
              <Checkbox disabled={onlineOrderingDisabled} >{ i18n?.t('menu_door_dash') || 'Doordash' }</Checkbox>
            </Form.Item>
          }
        </Form>
      </Card>
    </div>
  )
})

export default ChannelAvailability