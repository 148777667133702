import React, { useEffect, useState } from 'react'
import {  Flex, Spin, Table, Button, Drawer, PaginationProps, Typography, Row, Col } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import AdjustBalanceModal from './AdjustBalanceModal'
import { ApiBalanceDetail, ApiTransactionDetail, ApiAdjustBalance } from '@/request/api'
import { ADJUST_OPERATE_TYPE, ITransaction, TransactionType } from './interface'
import { formatTimestampWithoutTimeZone } from '@/utils'
import './index.scss'
import GlobalDrawer from '@/utils/GlobalDrawer'
import OrderDetail from '@/Pages/OrdersReport/OrderDetail'
import { IBalanceDetail } from './interface'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react';
import { globalState } from '@/stores'

const { Text } = Typography;

interface IProps {
  onClose?: () => void,
  cardNumber?: string,
  isOpen: boolean;
  cardNumberView: string;
}
// 根据type返回加减号
// 根据交易类型返回对应的算术符号
const getOperateByType = (type: TransactionType): string => {
  switch (type) {
  case TransactionType.RECHARGE:
  case TransactionType.VOID_REDEEM:
  case TransactionType.ADJUST_INC:
    return '+';
  case TransactionType.VOID_RECHARGE:
  case TransactionType.REDEEM:
  case TransactionType.ADJUST_DEC:
    return '-';
  default:
    return '';
  }
};
const initPagination = { 'current': 1,
  'pageSize': 10, total: 0 }
// 货币符号
const currencySymbol = '$'

const GiftCardDetail: React.FC<IProps> = (props) => {
  const navigate = useNavigate()
  const { i18n } = globalState;
  const multiLanguagePrefix = 'PC_GIFTCARD_ADJUST_'
  const columns = [
    {
      title: i18n.t('PC_GIFTCARD_ADJUST_DATE'),
      dataIndex: 'transactionTime',
      key: 'transactionTime',
      render: (text: string) => (formatTimestampWithoutTimeZone(text, { format: 'MM/DD/YYYY hh:mm:ss A' }))
    }, {
      title: i18n.t('type'),
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => (i18n.t(multiLanguagePrefix + text))
    }, {
      title: i18n.t('PC_GIFTCARD_ADJUST_AMOUNT'),
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string, record) => (`${getOperateByType(record?.type)}${currencySymbol}${text}`)
    }, {
      title: i18n.t('PC_GIFTCARD_ADJUST_EMPLOYEE'),
      dataIndex: 'employeeName',
      key: 'employeeName',
      render: (text: string) => (text ? text : 'N/A')
    }, {
      title: i18n.t('PC_GIFTCARD_ADJUST_CHECK'),
      dataIndex: 'posDisplayNo',
      key: 'posDisplayNo',
      // 展示使用posDisplayNo, 跳转使用checkNo
      render: (value: string, record:ITransaction) => {
        return value ? <Button type="link" className="body-base" style={{ padding:0, height:'auto' }}onClick={ () => {
          GlobalDrawer.open({ component: <OrderDetail/>, navigate: navigate, props: { checkNo: record?.checkNo } })
        }}>{`${i18n.t('PC_GIFTCARD_ADJUST_CHECK')}${value}`}</Button> : 'N/A'
      }
    }, {
      title: i18n.t('PC_GIFTCARD_BALANCE'),
      dataIndex: 'balance',
      key: 'balance',
      render: (text: string) => `${currencySymbol}${text}`
    }
  ];
  const CardDetailItem = ({ label, value }) => {
    return (
      <div>
        <Text strong>{label}</Text>
        <div>{value}</div>
      </div>
    );
  };
  const { cardNumber, onClose, isOpen, cardNumberView } = props
  const [cardDetail, setCardDetail] = useState<IBalanceDetail>()
  const [isAdjustModalOpen, setIsAdjustModalOpen] = useState<boolean>(false)
  const [isAdjustModalLoading, setIsAdjustModalLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<PaginationProps>(initPagination)
  const [list, setList] = useState<ITransaction[]>()
  useEffect(() => {
    cardNumber && initData()
  }, [cardNumber])
  const initData = async () => {
    setIsLoading(true)
    try {
      await Promise.all([getBalanceDetail(), getTransactionDetail(pagination)])
    } catch (error) {
      Sentry.captureEvent({
        message: 'GiftCard Balance Init Failed',
        level: 'error',
        extra: {
          additionalInfo: error
        }
      });
      // 提示用户重新刷新
    } finally {
      setIsLoading(false)
    }
  }
  // 获取Card Detail
  const getBalanceDetail = async () => {
    const res = await ApiBalanceDetail({ cardNumber })
    const data = res?.data
    setCardDetail(data)
  }
  // 获取流水明细
  const getTransactionDetail = async (pagination: PaginationProps) => {
    const { data } = await ApiTransactionDetail({
      request: { cardNumber },
      ...pagination
    }) || {}
    const { current, total } = data || {}
    // 后端未返回pageSize，所以在成功获取到数据后才更新pageSize
    setPagination((prevState) => ({ ...prevState, ...pagination, current, total: Number(total) }))
    setList(data?.list)
  }

  // 打开Order Detail详情弹窗
  const handleAdjustClick = () => {
    setIsAdjustModalOpen(true)
  }
  const handleAdjustClose = () => setIsAdjustModalOpen(false)
  const handleAdjustConfirm = async (adjustType: ADJUST_OPERATE_TYPE, amount: string) => {
    try {
      setIsAdjustModalLoading(true)
      const res = await ApiAdjustBalance({ cardNumber, adjustType, amount })
      // 刷新抽屉数据
      if (res?.code === 0) {
        handleAdjustClose()
        await initData()
      }
    } catch (error) {
      Sentry.captureEvent({
        message: 'Adjust GiftCard Balance Failed',
        level: 'error',
        extra: {
          additionalInfo: error
        }
      });
    } finally {
      setIsAdjustModalLoading(false)
    }
  }
  const handleTableChange = async (pagination: PaginationProps) => {
    setIsLoading(true)
    try {
      await getTransactionDetail(pagination)
    } catch (error) {
      Sentry.captureEvent({
        message: 'Error when switching the gift card table',
        level: 'error',
        extra: {
          additionalInfo: error
        }
      })
    } finally {
      setIsLoading(false)
    }
  };
  const CardDetails = ({ cardDetail }) => {
    const getStringOrDash = (str) => {
      return str ? str : '-';
    }
    return (
      <div>
        <Row gutter={0} className='heading5' style={{ marginBottom: '8px' }}>
          <Col span={12} >
            <CardDetailItem label={i18n.t('PC_GIFTCARD_CURRENT_BALANCE')} value={`${currencySymbol}${getStringOrDash(cardDetail?.currentBalance)}`} />
          </Col>
          <Col span={12}>
            <CardDetailItem label={i18n.t('PC_GIFTCARD_ADJUST_LAST_TRANS_TIME')} value={getStringOrDash(formatTimestampWithoutTimeZone(cardDetail?.lastTranTime))} />
          </Col>
        </Row>
        <Row gutter={0} className='heading5'>
          <Col span={12}>
            <CardDetailItem label={i18n.t('PC_GIFTCARD_ADJUST_INITIAL_AMOUNT')} value={`${currencySymbol}${getStringOrDash(cardDetail?.initAmount)}`} />
          </Col>
          <Col span={12}>
            <CardDetailItem label={i18n.t('PC_GIFTCARD_ADJUST_FIRST_TRANS_TIME')} value={getStringOrDash(formatTimestampWithoutTimeZone(cardDetail?.firstTranTime))} />
          </Col>
        </Row>
      </div>
    );
  };
  return <Drawer width={785} open={isOpen} closeIcon={null} styles={{ body:{ padding:0 } }} destroyOnClose={true}>
    <Spin spinning={isLoading}>
      <div className="balance-wrapper">
        {/* Title */}
        <Flex justify="space-between" align="center" className='balance-title-wrapper'>
          <Text className='heading2' strong>{i18n.t('PC_GIFTCARD_ADJUST_GIFTCARD_DETAIL')}</Text>
          <CloseOutlined onClick={onClose} style={{ width: '24px', height: '24px' }}/>
        </Flex>
        {/* Card Detail */}
        <div className="content-wrapper" style={{ marginBottom: '24px' }}>
          <Flex style={{ marginBottom: '8px' }}>
            <Text className='heading4' style={{ fontWeight: 600 }}>{i18n.t('PC_GIFTCARD_ADJUST_GIFTCARD')}</Text>
            <Text className='heading4'>&nbsp;{cardNumberView}</Text>
          </Flex>
          <CardDetails cardDetail={cardDetail}/>
        </div>
        {/* Transaction Detail */}
        <div className="content-wrapper">
          <Flex justify='space-between' align='center' style={{ marginBottom:'16px' }}>
            <Text strong className='heading4'>{i18n.t('PC_GIFTCARD_ADJUST_RECORD')}</Text>
            <Button onClick={handleAdjustClick}>{i18n.t('PC_GIFTCARD_ADJUST_BALANCE')}</Button>
          </Flex>
          <Table rowKey="transactionNo" columns={columns} dataSource={list} pagination={{ showTotal: (total) => i18n.t('table_total_items', { num: total }), ...pagination }} onChange={handleTableChange} scroll={{ y: 600 }} />
        </div>
      </div>
      {isAdjustModalOpen && <AdjustBalanceModal open={isAdjustModalOpen} onClose={handleAdjustClose} onConfirm={handleAdjustConfirm} currentBalance={Number(cardDetail?.currentBalance)} confirmLoading={isAdjustModalLoading}/>}
    </Spin>
  </Drawer>

}
export default GiftCardDetail
