import React, { useCallback } from 'react';
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import FilterContainer from '@/Components/FilterContainer';
import type { FilterItem } from '@/Components/FilterContainer/types';
import Columns from '@/Components/Columns/Columns';
import type { TableColumns } from '@/types/table';
import './index.scss';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { EReport_Status } from '@/types';
import { service } from '@/request';
import { IExportResult } from '@/Components/Export/interface';
import ReactToPrint from 'react-to-print';
import { getCookie } from '@/utils';


interface TableToolbarProps<T> {
  filters?: FilterItem[];
  initialFilterValues?: Record<string, any>;
  columns?: TableColumns<T>;
  showColumnsList?: string[];
  onFilterChange?: (values: Record<string, any>) => void;
  onRefresh?: () => void;
  onChangeColumns?: (list: string[]) => void;
  extraActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  exportOptions?: {
    exportApi?: string;
    exportParams?: Record<string, any>
  }
  printRef?: React.RefObject<HTMLDivElement>
}

const EXPORT_MAP = {
  'CSV': EReport_Status.CSV,
  'XLS': EReport_Status.XLS
}

export const TableToolbar = <T, >({
  filters,
  initialFilterValues,
  columns,
  showColumnsList,
  onFilterChange,
  onRefresh,
  onChangeColumns,
  extraActions,
  leftActions,
  exportOptions,
  printRef
}: TableToolbarProps<T>) => {

  // 导出相关
  const fetchApiExport = useCallback(async (exportType: string) => {
    const res: any = await service({
      method: 'post',
      url: exportOptions?.exportApi,
      data: {
        exportType: EXPORT_MAP[exportType] ?? EReport_Status.XLS,
        ...(exportOptions.exportParams || {})
      }
    })
    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }
    return exportResult
  }, [exportOptions])

  return (
    <div className='table-toolbar'>
      <div className='table-toolbar-left'>
        <div className="export-wrap">
          {
            exportOptions?.exportApi && <PepprExportComponent
              type='button'
              isDropDown={true}
              fetchApiExport={fetchApiExport}
            />
          }
        </div>
        {onFilterChange && filters && (
          <FilterContainer
            filters={filters}
            onFilterChange={onFilterChange}
            initialFilterValues={initialFilterValues}
          />
        )}
        {leftActions}
      </div>
      <div className='table-toolbar-right'>
        {onRefresh && (
          <Button type="link" onClick={onRefresh}>
            <SyncOutlined />
          </Button>
        )}
        {onChangeColumns && columns && showColumnsList && (
          <Columns
            value={showColumnsList}
            options={columns.map(item => ({
              label: item.title as string,
              value: item.dataIndex as string
            }))}
            onChange={onChangeColumns}
          />
        )}
        {
          (getCookie('appCode') !== '1' &&  printRef?.current)  && <ReactToPrint
            trigger={ () => <Button type="link" icon={ <div className="iconfont icon-print"/> }/> }
            content={ () => printRef.current }
          />
        }
        {extraActions}
      </div>
    </div>
  );
};