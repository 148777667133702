/** TransactionDetail接口返回数据结构 **/
export interface ITransaction {
    transactionNo: string, // 用作唯一key
    transactionTime: string;
    type: TransactionType;
    amount: string;
    employeeName: string;
    posDisplayNo: string;
    checkNo: string;
    balance: string;
}

export interface ITransactionResponse {
    current: number;
    total: string; // 糟粕
    list: ITransaction[];
}

/** Balance Detail接口返回数据结构 **/
export interface IBalanceDetail {
    cardNumber: string;
    firstTranTime: string;
    initAmount: string;
    lastTranTime: string;
    currentBalance: string;
}
// 定义一个枚举类型 TransactionType，用于表示不同的交易类型
export enum TransactionType {
    // 充值
    RECHARGE = 'recharge',
    // 取消充值
    VOID_RECHARGE = 'voidRecharge',
    // 核销
    REDEEM = 'redeem',
    // 取消核销
    VOID_REDEEM = 'voidRedeem',
    // 查询
    QUERY = 'query',
    // 调整_加余额
    ADJUST_INC = 'adjust_inc',
    // 调整_减余额
    ADJUST_DEC = 'adjust_dec'
}
// 调整金额类型
export enum ADJUST_OPERATE_TYPE{
    increment = 'adjust_inc',
    decrement = 'adjust_dec'
}