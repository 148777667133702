import { useState, useEffect, useCallback, useRef } from 'react';
import { RESPONSE_CODE } from '@/constants/http';

interface Options<TData, TParams> {
  // 手动触发接口请求
  manual?: boolean;
  // 初始数据
  initialData?: TData;
  // 依赖项 依赖与上一个请求的返回值
  deps?: any[];
  // 轮询间隔
  pollingInterval?: number;
  // 重试次数
  retryCount?: number;
  // 防抖时间
  debounceWait?: number;
  // 成功回调
  onSuccess?: (data: TData) => void;
  // 失败回调
  onError?: (error: Error) => void;
  // 串行请求配置
  serial?: {
    deps: any[];
    shouldExecute?: (data: any) => boolean;
  };
}

interface Response<TData> {
  code: number;
  message: string;
  data: TData;
  success: boolean;
}

interface Result<TData, TParams> {
  data: TData | undefined;
  loading: boolean;
  fetch: (params?: TParams) => Promise<Response<TData>>;
  refresh: () => void;
  cancel: () => void;
}

/**
 * 请求封装
 * @param service 请求方法
 * @param options 配置
 * @returns
 * example:
 * const { data, loading, fetch, refresh, cancel } = useRequest(ApiQueryGiftCardSummary, {
 *   deps: [dateRange],
 * });
 */
function useRequest<TData, TParams = void> (
  service: (params?: TParams) => Promise<Response<TData>>,
  options: Options<TData, TParams> = {}
): Result<TData, TParams> {
  const {
    manual = false,
    initialData,
    deps = [],
    pollingInterval = 0,
    retryCount = 0,
    debounceWait = 0,
    onSuccess,
    onError,
    serial,
  } = options;

  const [data, setData] = useState<TData | undefined>(initialData);
  const [loading, setLoading] = useState(false);

  const pollingTimer = useRef<NodeJS.Timeout>(null);
  const retryCountRef = useRef(0);
  const unmountedRef = useRef(false);

  // 防抖处理
  const debounceTimer = useRef<NodeJS.Timeout>(null);
  const debouncedFetch = useCallback((params?: TParams) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    return new Promise<Response<TData>>((resolve, reject) => {
      debounceTimer.current = setTimeout(() => {
        fetch(params).then(resolve).catch(reject);
      }, debounceWait);
    });
  }, [debounceWait]);

  const fetch = useCallback(async (params?: TParams) => {
    setLoading(true);

    try {
      const result = await service(params);
      if (!unmountedRef.current && result.code === RESPONSE_CODE.SUCCESS_CODE && result.data) {
        setData(result.data);
        onSuccess?.(result.data);
      }
      retryCountRef.current = 0;
      setLoading(false);
      return result;
    } catch (err) {
      if (!unmountedRef.current) {
        setLoading(false);
        onError?.(err as Error);
        if (retryCountRef.current < retryCount) {
          retryCountRef.current += 1;
          return fetch(params);
        }
      }
    }
  }, [service, retryCount, onSuccess, onError]);

  const refresh = useCallback(() => {
    fetch();
  }, [fetch]);

  const cancel = useCallback(() => {
    if (pollingTimer.current) {
      clearInterval(pollingTimer.current);
    }
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
  }, []);

  useEffect(() => {
    if (!manual) {
      const execute = async () => {
        // 串行请求处理
        if (serial) {
          const shouldExecute = serial.shouldExecute ?? (() => true);
          if (shouldExecute(data)) {
            await (debounceWait > 0 ? debouncedFetch() : fetch());
          }
        } else {
          await (debounceWait > 0 ? debouncedFetch() : fetch());
        }
      };

      execute();
    }
  }, [...deps, ...(serial?.deps || [])]);

  // 轮询处理
  useEffect(() => {
    if (pollingInterval > 0) {
      pollingTimer.current = setInterval(() => {
        fetch();
      }, pollingInterval);
    }
    return () => {
      if (pollingTimer.current) {
        clearInterval(pollingTimer.current);
      }
    };
  }, [pollingInterval, fetch]);

  // 组件卸载处理
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
      cancel();
    };
  }, []);

  return {
    data,
    loading,
    fetch: debounceWait > 0 ? debouncedFetch : fetch,
    refresh,
    cancel,
  };
}

export default useRequest;
