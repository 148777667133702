import { useState, useEffect, useCallback, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { getToday } from '@/utils';

// 用于在多个 hook 实例之间共享数据
let globalTime: Dayjs;
const subscribers = new Set<(time: Dayjs) => void>();

export function useTime () {
  const [today, setTime] = useState<Dayjs>(globalTime);
  const [lastSevenDayTime, updateLastSevenDayTime] = useState<[string, string] | []>();

  /**
   * 获取最近7天的开始和结束时间
   */
  const lastSevenDays = useMemo(() => {
    if (!today) return [];
    return [today?.subtract(6, 'day').startOf('day'), today];
  }, [today]);

  /**
   * 获取最近7天的开始和结束时间,精确到秒
   */
  useEffect(() => {
    if (!today) return;
    updateLastSevenDayTime([today?.subtract(6, 'day').startOf('day').format('YYYY-MM-DD 00:00:00'), today?.format('YYYY-MM-DD 23:59:59')])
  }, [today])

  useEffect(() => {
    if (subscribers.size === 0) {
      fetchTime();
    }
    subscribers.add(setTime);
    return () => {
      subscribers.delete(setTime);
    };
  }, []);

  const refreshTime = useCallback(async () => {
    return fetchTime();
  }, []);

  const updateDateRange = useCallback((filters: Record<string, any>) => {
    updateLastSevenDayTime([filters.startTime, filters.endTime]);
  }, [updateLastSevenDayTime]);

  return {
    today,
    lastSevenDays,
    lastSevenDayTime,
    refreshTime,
    updateDateRange
  };
}

// 获取服务器时间并通知所有订阅者
async function fetchTime (): Promise<Dayjs> {
  try {
    const newTime = await getToday();
    globalTime = newTime;
    subscribers.forEach(subscriber => subscriber(newTime));
    return newTime;
  } catch (error) {
    return globalTime;
  }
}
