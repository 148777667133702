import React, { useState, useEffect } from 'react';
import { Table, Typography, Statistic, Divider, Card, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './UserTotal.scss';
const { Title } = Typography;
import dayjs from 'dayjs';
import { globalState } from '@/stores';
import { formatCurrency } from '@/utils'
import { InfoCircleOutlined } from '@ant-design/icons';
import { EmployeeLaborGroupDTO, EmployeeLaborDetailDTO } from '../type'
import classNames from 'classnames';

interface IUserTotalProps {
  tableData: EmployeeLaborGroupDTO[];
  tableColumns: any;
  onRow: (row) => void;
}

const EmployeeTable: React.FC<IUserTotalProps> = (props) => {
  const { tableData, onRow, tableColumns } = props;
  const { i18n } = globalState;
  const [data, setData] = useState<EmployeeLaborGroupDTO[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [topInfo, setTopInfo] = useState([]);
  const [columns, setColumns] = useState([]);
  const defaultTopInfo = [
    {
      title: i18n.t('laborSummary_totalLaborHours'),
      value: 'totalLaborHours',
      headerId: 3,
      suffix: 'hrs',
    },
    {
      title: i18n.t('laborSummary_estimatedTotalPay'),
      value: 'totalEstimatedTotalPay',
      headerId: 6,
      prefix: '$',
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_netsales_devided_pay'),
      value: 'netSalesPayRate',
      headerId: 10,
      suffix: '%',
    },
    {
      title: <div>
        <span style={{ paddingRight: '5px' }}>{i18n.t('dashboard_tippable_net_sales')}</span>
        <Tooltip title={i18n.t('Dashboard_labor_summary_tippable_sales_info') || 'This displays the net sales of all tippable sale categories. If not configured, it reflects total net sales.'}>
          <InfoCircleOutlined />
        </Tooltip>
      </div>,
      value: 'employeeSalesTippable',
      headerId: 7,
      prefix: '$',
    },
    {
      title: i18n.t('laborSummary_cardTips'),
      value: 'cardTips',
      headerId: 8,
      prefix: '$',
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_declared_tips'),
      value: 'declareTips',
      headerId: 9,
      prefix: '$',
    }
  ]
  const defaultColumns = [
    {
      title: i18n?.t('dashboard_report_labor_summary_role'),
      dataIndex: 'roleName',
      headerId: 0,
      render: (_, record) => {
        const isRed = record.clockOutType === 2
        return (
          <>
            <span style={{ color: isRed ? 'red' : 'inherit', marginRight: 2 }}>
              {record?.clockTime || record.roleName ||  '-'}
            </span>
            {
              isRed && <Tooltip title={i18n.t('dashboard_report_auto_clock_out_hint')}>
                <InfoCircleOutlined />
              </Tooltip>
            }
          </>
        );
      },
    },
    {
      title: i18n.t('dashboard_Regular_Hours'),
      dataIndex: 'regularHours',
      headerId: 1,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n.t('dashboard_overtime_hours'),
      dataIndex: 'overTimeHours',
      headerId: 2,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n?.t('laborSummary_totalLaborHours'),
      dataIndex: 'laborHours',
      headerId: 3,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n?.t('laborSummary_hourlyRate'),
      dataIndex: 'regularRate',
      headerId: 4,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_ot_rate') || 'OT Rare',
      dataIndex: 'overTimeRate',
      headerId: 5,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n?.t('laborSummary_estimatedTotalPay'),
      dataIndex: 'estimatedTotalPay',
      headerId: 6,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('dashboard_tippable_net_sales'),
      dataIndex: 'netSalesTippable',
      headerId: 7,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('laborSummary_cardTips'),
      dataIndex: 'cardTips',
      headerId: 8,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_declared_tips'),
      dataIndex: 'declareTips',
      headerId: 9,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
  ];
  const handleExpand = (expanded, key) => {
    const keys = [...expandedRowKeys];
    if (expanded) {
      keys.push(key);
    } else {
      const index = keys.indexOf(key);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }
    setExpandedRowKeys(keys);
  };
  const formatDateTimeRange = (clockIn: string, clockOut: string): string => {
    const format = 'MM/DD/YYYY hh:mm A';
    const formattedClockIn = dayjs(clockIn).format(format);
    const formattedClockOut = dayjs(clockOut).format(format);
    return `${formattedClockIn} - ${formattedClockOut}`;
  };
  const transformData = (data) => {
    return data.map(employee => ({
      ...employee,
      detailDTOList: employee.detailDTOList ? employee.detailDTOList.map(detail => ({
        ...detail,
        key: detail.roleId + detail.regularRate + employee.employeeId,
        detailDTOList: detail.detailDTOList ? detail.detailDTOList.map(timeDetail => ({
          ...timeDetail,
          roleName: detail.roleName,
          employeeId: employee.employeeId * 1,
          clockTime: formatDateTimeRange(timeDetail.clockIn, timeDetail.clockOut),
        })) : [],
      })).concat([{
        roleId: 0,
        roleName: i18n.t('pc_transaction_total'),
        regularHours: employee.totalRegularHours,
        overTimeHours: employee.totalOverTimeHours,
        laborHours: employee.totalLaborHours,
        regularRate: employee.regularRate,
        overTimeRate: employee.overTimeRate,
        estimatedTotalPay: employee.totalEstimatedTotalPay,
        netSalesTippable: employee.employeeSalesTippable,
        cardTips: employee.cardTips,
        declareTips: employee.declareTips,
      }]) : [],
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      if (tableData) {
        const list = transformData(tableData);
        setData(list);
        list?.[0]?.detailDTOList?.[0]?.key && setExpandedRowKeys([list?.[0]?.detailDTOList?.[0].key]);
      }
    };
    fetchData();
  }, [tableData]);
  useEffect(() => {
    setTopInfo(defaultTopInfo.filter(item => tableColumns.some(column => column.headerId === item.headerId)))
    setColumns(defaultColumns.filter(column => tableColumns.some(item => item.headerId === column.headerId)))
  }, [tableColumns])
  return (
    <div className='user-info-total'>
      {data.map((employee) => (
        <Card key={employee.employeeId} className='user-labor'>
          <div className='user-labor-top'>
            <div className='title'>
              <Title level={3} style={{ margin: 0 }}>
                {employee.firstName} {employee.lastName}
              </Title>
            </div>
            <div className='top-info'>
              {topInfo.map(item => (<div className="item" key={item.dataIndex + 't'}>
                <Divider type="vertical" />
                <Statistic
                  title={item.title}
                  value={employee[item.value] || '-'}
                  precision={2}
                  suffix={employee[item.value] ? item.suffix : ''}
                  prefix={employee[item.value] ? item.prefix : ''}
                />
              </div>))}
              {/* <div className="item">
                <Divider type="vertical" />
                <Statistic title={i18n.t('laborSummary_estimatedTotalPay')} value={employee.totalEstimatedTotalPay || '-'} precision={2} prefix={employee.totalEstimatedTotalPay ? '$' : ''} />
              </div>
              <div className="item">
                <Divider type="vertical" />
                <Statistic title={i18n.t('Dashboard_report_labor_summary_netsales_devided_pay') || 'Net sales/Pay'} value={employee.netSalesPayRate || '-'} precision={2} suffix={employee.netSalesPayRate ? '%' : ''} />
              </div>
              <div className="item">
                <Divider type="vertical" />
                <Statistic title={<div>
                  <span style={{ paddingRight: '5px' }}>{i18n.t('dashboard_tippable_net_sales')}</span>
                  <Tooltip title={i18n.t('Dashboard_labor_summary_tippable_sales_info') || 'This displays the net sales of all tippable sale categories. If not configured, it reflects total net sales.'}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>} value={employee.employeeSalesTippable || '-'} precision={2} prefix={employee.employeeSalesTippable ? '$' : ''} />
              </div>
              <div className="item">
                <Divider type="vertical" />
                <Statistic title={i18n.t('laborSummary_cardTips')} value={employee.cardTips || '-'} precision={2} prefix={employee.cardTips ? '$' : ''} />
              </div>
              <div className="item">
                <Divider type="vertical" />
                <Statistic title={i18n.t('Dashboard_report_labor_summary_declared_tips') || 'Declared Tips'} value={employee.declareTips || '-'} precision={2} prefix={employee.declareTips ? '$' : ''} />
              </div> */}
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={employee.detailDTOList}
            scroll={{ x: 'max-content' }}
            pagination={false}
            key={employee.employeeId}
            onRow={(record) => {
              return {
                onClick: (event) => {onRow(record)}, // 点击行
              };
            }}
            rowClassName={ (_, index) => classNames('row', { 'sticky-row': index === employee.detailDTOList.length - 1 }) }
            expandable={{
              expandedRowKeys: expandedRowKeys,
              childrenColumnName: 'detailDTOList',
              onExpand: (expanded, record) => handleExpand(expanded, record.key),
              indentSize: 24,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (!!record.roleId) {
                  return <span className='table-expand-icon iconfont icon-UpOutlined' onClick={e => onExpand(record, e)} style={{ transform: expanded ? 'rotate(0)' : 'rotate(90deg)' }}></span>
                } else {
                  return null;
                }
              }
            }}
            // summary={() => (
            //   <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
            //     {tableColumns.map((item, index) => {
            //       if (item.totalKey === 'roleName') {
            //         return (
            //           <Table.Summary.Cell key={item.totalKey + index} index={index}><b>{i18n.t('pc_transaction_total')}</b></Table.Summary.Cell>
            //         )
            //       } else {
            //         return <Table.Summary.Cell key={item.totalKey + index} index={index}><b>{employee?.[item.totalKey] ? `${employee?.[item.totalKey] } hrs` : '-'}</b></Table.Summary.Cell>
            //       }
            //     })}
            //     {/* <Table.Summary.Cell index={0}><b>{i18n.t('pc_transaction_total')}</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={1}><b>{employee?.totalRegularHours ? `${employee?.totalRegularHours } hrs` : '-'}</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={2}><b>{employee?.totalOverTimeHours ? `${employee?.totalOverTimeHours } hrs` : '-'}</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={3}><b>{employee?.totalLaborHours ? `${employee?.totalLaborHours } hrs` : '-'}</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={4}><b>{employee?.regularRate ? formatCurrency(employee.regularRate) : '-' }</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={5}><b>{employee?.overTimeRate ? formatCurrency(employee.overTimeRate) : '-'}</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={6}><b>{employee?.totalEstimatedTotalPay ? formatCurrency(employee.totalEstimatedTotalPay) : '-'}</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={7}><b>{employee?.employeeSalesTippable ? formatCurrency(employee.employeeSalesTippable) : '-'}</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={8}><b>{employee?.cardTips ? formatCurrency(employee.cardTips) : '-'}</b></Table.Summary.Cell>
            //     <Table.Summary.Cell index={9}><b>{employee?.declareTips ? formatCurrency(employee.declareTips) : '-'}</b></Table.Summary.Cell> */}
            //   </Table.Summary.Row>
            // )}
          />
        </Card>
      ))}
    </div>
  );
}

export default EmployeeTable;
