import React, { useCallback, useState } from 'react';
import { useGenericTable } from '@/hooks/useGenericTable';
import { GenericTablePage } from '@/Components/GenericTablePage';
import type { BalanceTableData } from '@/types/giftCard';
import { getColumns } from './columns';
import type { FilterItem } from '@/Components/FilterContainer/types';
import GiftCardDetail from '../GiftCardDetail';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import './index.scss';
import { globalState } from '@/stores';
import { EReport_Status } from '@/types';
import { service } from '@/request';
import { IExportResult } from '@/Components/Export/interface';


const SORTER_MAP = {
  firstTranTime: 'first_transaction_time',
  lastTranTime: 'last_transaction_time',
};
const EXPORT_MAP = {
  'CSV': EReport_Status.CSV,
  'XLS': EReport_Status.XLS
}

const EXPORT_URL = 'rms/overseas/MerchantManagementConsole/reports/giftcard/exportBalance'
const TABLE_API_URL = '/rms/overseas/MerchantManagementConsole/reports/giftcard/balance'

const Balance: React.FC = () => {
  const { i18n } = globalState
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
  const [cardNumber, setCardNumber] = useState<string>('')
  const [cardNumberView, setCardNumberView] = useState('');
  const handleViewDetail = useCallback((record: BalanceTableData) => {
    const cardNumber = record?.cardNumber
    if (cardNumber) {
      setCardNumber(cardNumber)
      setIsDetailOpen(true)
      setCardNumberView(record.cardNumberView)
    }
  }, []);

  const columns = React.useMemo(() => getColumns(i18n, handleViewDetail), [handleViewDetail]);

  const filters = React.useMemo(()=>[
    {
      name: 'cardNumber',
      placeholder: i18n.t('dashboard_report_search_card_number'),
      type: 'search',
    }
  ], []);

  const {
    data,
    loading,
    pagination,
    setParams,
    refresh,
    handleTableChange,
    showColumnsList,
    isShowEmptyTable,
    handleChangeColumns
  } = useGenericTable<BalanceTableData>({
    apiUrl: TABLE_API_URL,
    defaultSorter: {
      field: 'last_transaction_time',
      order: 'DESC'
    },
    sorterMap: SORTER_MAP,
    columns
  });


  // 导出相关
  const fetchApiExport = useCallback(async (exportType: string) => {
    const res: any = await service({
      method: 'post',
      url: EXPORT_URL,
      data: {
        exportType: EXPORT_MAP[exportType] ?? EReport_Status.XLS,
      }
    })
    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }
    return exportResult
  }, [])

  return (
    <React.Fragment>
      <div className="action-wrap">
        <PepprExportComponent
          type='button'
          disabled={ data.length === 0 }
          isDropDown={true}
          fetchApiExport={fetchApiExport}
        />
      </div>
      <GenericTablePage<BalanceTableData>
        columns={columns}
        filters={filters as FilterItem[]}
        tableProps={{
          data,
          loading,
          pagination,
          showColumnsList,
          isShowEmptyTable
        }}
        handlers={{
          onFilterChange: setParams,
          onRefresh: refresh,
          onChangeColumns: handleChangeColumns,
          onTableChange: handleTableChange
        }}
      />
      {isDetailOpen && <GiftCardDetail isOpen={isDetailOpen} onClose={() => {setIsDetailOpen(false); setCardNumber(''); refresh();}} cardNumber={cardNumber} cardNumberView={cardNumberView}/>}
    </React.Fragment>

  );
};

export default Balance;
