// amplitudeWrapper.ts

import * as amplitude from '@amplitude/analytics-browser';

interface InitOptions {
  deviceId?: string;
  userId: string;
  tenantId: string;
  accId: string;
  nodeId: string;
}

class AmplitudeWrapper {
  private apiKey: string;

  constructor (apiKey: string) {
    this.apiKey = apiKey;
  }

  async init ({
    deviceId,
    userId,
    tenantId,
    accId,
  }: InitOptions): Promise<void> {
    if (window.location.hostname !== 'pos.peppr.com') return
    // Initialize Amplitude
    await amplitude.init(this.apiKey, userId, {
      deviceId: deviceId,
    });

    // Set user properties
    this.setUserProperties({ tenantId, accId });
  }

  setUserProperties (properties: Record<string, any>): void {
    // Create an Identify instance
    const identify = new amplitude.Identify();

    // Set user properties
    for (const [key, value] of Object.entries(properties)) {
      identify.set(key, value);
    }

    // Send the identify call
    amplitude.identify(identify);
  }

  logPageView (url: string): void {
    // Log Page View event
    amplitude.track('Page View', {
      url: url,
      timestamp: new Date().toISOString(),
    });
  }

  logClickEvent (
    eventName: string,
    additionalProperties: Record<string, any> = {}
  ): void {
    // Log click event
    const eventProperties = {
      ...additionalProperties,
      timestamp: new Date().toISOString(),
    };

    amplitude.track(eventName, eventProperties);
  }
}

export default AmplitudeWrapper;

export const amplitudeWrapper = new AmplitudeWrapper('3efdfa4700d7698ef93f3d594a4a524d');
