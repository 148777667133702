import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import './index.scss';
import { Button, Form, Input, Select, Table, Tooltip } from 'antd';
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import {
  formatDollar,
  formatPrice,
  getToday,
  removeNullUndefinedFields,
} from '@/utils';
import { ApiExportCashDrawerTransDetail, ApiExportOrderReport, ApiQueryCashManagement, ApiQueryEmployeeList } from '@/request/api';
import useGetState from '@/hooks/useGetState';
import dayjs, { Dayjs } from 'dayjs';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { TableEmptyContent } from '@/Components/EmptyContent';
import axios from 'axios';
import {
  CASH_OPERATE_TYPE,
  CASH_OPERATE_TYPE_I18N,
  ICashDrawerTransDetail,
  IPage,
} from './types';
import { SyncOutlined } from '@ant-design/icons';
import { SorterResult } from 'antd/es/table/interface';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { IDropMenuExportParams, IExportResult } from '@/Components/Export/interface';
import { EReport_Status } from '@/types';
import { getCashDrawerList } from '@/request/PrintersAndCashDrawers';

let axiosSource = axios.CancelToken.source();

export default function CashManagement () {
  const { i18n } = globalState;

  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false);
  const [employeeAllList, setEmployeeAllList] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [cashDrawerList, setCashDrawerList] = useState<Array<any>>([]);
  const [rangePresets, setRangePresets] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showColumnsList, setShowColumnsList] = useState<Array<string>>([]);
  const [tableData, setTableData] = useState<Array<ICashDrawerTransDetail>>([]);
  const [today, setToday] = useState<Dayjs>(dayjs());
  const [sortedInfo, setSortedInfo, getSortedInfo] = useGetState<
    SorterResult<ICashDrawerTransDetail>
  >({ columnKey: 'transTime', order: 'descend' });
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  });
  const [displayTimeRange, setDisplayTimeRange] = useState<Array<string>>([
    '',
    '',
  ]);
  const [, setParams, getParams] = useGetState<{ dateRangeType: string }>({
    dateRangeType: DATE_RANGE_TYPE.TODAY,
  });
  const [form] = Form.useForm();

  const formInitialValues = {
    date: [dayjs().startOf('day'), dayjs().endOf('day')],
    employeeIdList: undefined,
    typeList: [],
  };

  const tableColumns = [
    {
      dataIndex: 'transTime',
      key: 'transTime',
      title: i18n.t('pc_cash_management_operate_time'),
      width: 220,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'transTime' ? sortedInfo.order : null,
      sortDirections: ['descend', 'ascend', 'descend'],
    },
    {
      dataIndex: 'cashDrawerName',
      key: 'cashDrawerName',
      title: i18n.t('Dashboard_report_cashDrawerOverview_cash_drawer_Name'),
      width: 220,
    },
    {
      dataIndex: 'employeeName',
      key: 'employeeName',
      title: i18n.t('pc_cash_management_employee'),
      width: 220,
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: i18n.t('pc_cash_management_cash_amount'),
      render: (val) => formatDollar(val, { plusSign: true }) + formatPrice(val),
      width: 150,
    },
    {
      dataIndex: 'type',
      key: 'type',
      title: i18n.t('pc_cash_management_cash_operation'),
      render: (val) => i18n.t(CASH_OPERATE_TYPE_I18N[val]),
      width: 220,
    },
    {
      dataIndex: 'reason',
      width: 220,
      key: 'reason',
      title: i18n.t('pc_cash_management_reason'),
      render: (val) => val || 'N/A',
    },
    {
      dataIndex: 'checkDisplayNo',
      width: 220,
      key: 'checkDisplayNo',
      title: i18n.t('Dashboard_report_cash_management_check_id'),
      render: (val) => val || '-',
    },
  ];

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setShowColumnsList(tableColumns.map((x) => x.dataIndex));
    fetchEmployeeList();
    fetchCashDrawerList();
    const _today = await getToday();
    setToday(_today);
    form.setFieldValue('date', [_today, _today]);
    await fetchTableData();
  };

  const fetchEmployeeList = async () => {
    const _params = { needPage: false };
    const resp = await ApiQueryEmployeeList(_params);
    const { list = [] } = resp?.data;
    setEmployeeAllList(
      (list || []).map((x) => ({
        label: x.employee.fullName,
        value: x.employee.employeeId,
      }))
    );
  };

  const fetchCashDrawerList = async () => {
    const res = await getCashDrawerList({});
    setCashDrawerList(res?.data || []);
  };

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0);
    setShowColumnsList(list);
  };

  const handleFormValuesChange = ({ date }) => {
    setPager({ ...pager, current: 1 });
    if (date) {
      const dateRangeType =
        rangePresets.find((v) => {
          return v.value[0].isSame(date[0]) && v.value[1].isSame(date[1]);
        })?.key ?? DATE_RANGE_TYPE.CUSTOM;
      setParams({ dateRangeType });
    }
    fetchTableData();
  };

  const handleTableChange = (_pagination, _, sorter) => {
    setPager({ ...pager, ..._pagination });
    setSortedInfo(sorter);
    fetchTableData();
  };

  const fetchTableData = async () => {
    const pagerParams = getPager();
    const formParams = form.getFieldsValue();
    const curParams = getParams();
    const _sorter = getSortedInfo();
    const params = {
      request: {
        dateRangeType: curParams.dateRangeType,
        beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
        endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
        employeeIdList: formParams.employeeIdList && [formParams.employeeIdList],
        typeList: formParams.typeList,
        cashDrawerIds: formParams.cashDrawerIds,
      },
      orderBy: {
        field: 'createdTime',
        order: _sorter.order === 'descend' ? 'DESC' : 'ASC' // 原sortByDescTime字段，"ASC"(原false) OR "DESC"(原true)，默认倒序DESC
      },
      current: pagerParams.current,
      pageSize: pagerParams.pageSize,
    };
    setLoading(true);
    axiosSource.cancel();
    axiosSource = axios.CancelToken.source();
    const resp = await ApiQueryCashManagement(
      removeNullUndefinedFields(params),
      { cancelToken: axiosSource.token }
    );
    setLoading(false);
    if (resp.code !== 0) return;
    const {
      list = [],
      current,
      total,
      beginTime = '',
      endTime = '',
    } = resp.data;
    setTableData(list);
    setDisplayTimeRange([beginTime, endTime]);
    setPager({ ...pagerParams, total, current });
  };


  // 导出相关
  const fetchApiExport = async (exportType: IDropMenuExportParams) => {
    const params = {
      ...getRequestParams(),
      exportType: EReport_Status[exportType],
    }
    const res: any = await ApiExportCashDrawerTransDetail(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult
  }


  const getRequestParams = () => {
    const formParams = form.getFieldsValue();
    return {
      beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
    }
  }

  return (
    <div className="cash-management-wrap">
      <div className="m-title">
        <div className="title">{i18n.t('pc_cash_management_title')}</div>
      </div>
      <div className="filter-wrap">
        <div className="left-wrap">
          <Form
            form={form}
            layout="inline"
            onValuesChange={handleFormValuesChange}
            initialValues={formInitialValues}
          >
            <Form.Item name={'date'}>
              <PepprDatePicker today={today} setPresets={setRangePresets} />
            </Form.Item>
            <Form.Item name={'employeeIdList'}>
              <Select
                options={employeeAllList}
                showSearch
                optionFilterProp="label"
                style={{ width: 250 }}
                allowClear
                placeholder={i18n.t('pc_employee_report_all_employee')}
              />
            </Form.Item>
            <Form.Item name={'typeList'}>
              <Select
                style={{ minWidth: 250 }}
                allowClear
                mode="multiple"
                placeholder={i18n.t('pc_employee_report_cash_operation')}
                maxTagCount={2}
                showSearch={false}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    title={omittedValues.map(({ label }) => label).join(', ')}
                  >
                    <span>+{omittedValues.length}...</span>
                  </Tooltip>
                )}
                options={[
                  CASH_OPERATE_TYPE.CASH_COLLECTED,
                  CASH_OPERATE_TYPE.CASH_TIP,
                  CASH_OPERATE_TYPE.NO_SALE,
                  CASH_OPERATE_TYPE.ORDER_CASH_PAYMENT,
                  CASH_OPERATE_TYPE.PAY_IN,
                  CASH_OPERATE_TYPE.PAY_OUT,
                  CASH_OPERATE_TYPE.REFUND_CASH_OUT,
                  CASH_OPERATE_TYPE.TIP_OUT,
                ].map((x) => ({
                  value: x,
                  label: i18n.t(CASH_OPERATE_TYPE_I18N[x]),
                }))}
              />
            </Form.Item>
            <Form.Item name={'cashDrawerIds'}>
              <Select
                options={cashDrawerList.map(item => ({ label: item.name, value: item.id }))}
                showSearch
                mode="multiple"
                style={{ width: 250 }}
                allowClear
                placeholder={i18n.t('All cash ddashboard_reports_cash_drawer_overview_filter_by_cash_drawerrawers')}
                maxTagCount={2}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Form>
        </div>
        <div className="right-wrap">
          <Button type="link" onClick={fetchTableData}>
            <SyncOutlined />
          </Button>
          <Columns
            value={showColumnsList}
            options={tableColumns.map((item) => ({
              label: item.title,
              value: item.dataIndex,
            }))}
            onChange={handleChangeColumns}
          />
        </div>
      </div>
      <div className="time-info">{`${i18n.t(
        'pc_employee_report_time_range'
      )}: ${displayTimeRange[0]} - ${displayTimeRange[1]}`}</div>
      <div className="table-wrap">
        <Table
          className={isShowEmptyTable && 'empty-table'}
          columns={
            tableColumns.filter((x) =>
              showColumnsList.includes(x.dataIndex)
            ) as ColumnsType
          }
          dataSource={tableData.map((x, i) => ({ ...x, key: i }))}
          rowKey={'key'}
          loading={loading}
          pagination={pager}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: TableEmptyContent }}
          summary={() =>
            isShowEmptyTable && (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={999}>
                  {TableEmptyContent}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )
          }
        />
      </div>

      <div className="action-wrap">
        <PepprExportComponent
          type='button'
          isDropDown={true}
          disabled={tableData.length === 0}
          fetchApiExport={(exportType) => fetchApiExport(exportType)}
        />
      </div>
    </div>
  );
}
