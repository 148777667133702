
import { IBreakItem } from '@/Pages/EmployeeList/Breaks/interface';
import { pepprService } from '../index';

export interface Response<T = unknown> {
    code: number
    message?: string
    msg?: string
    success?: boolean
    data: T
}

export interface IPermissionTree {
    groupViewName: string
    permissionList: { id: number, name: string }[]
}

export interface ICreateRole {
    name: string;
    permissionIdList: number[];
    mandatoryClockIn: 1 | 0;
    autoLogout: 1 | 0;
    autoLogoutTime: number,
    relatedBreakIds: number[]
}

export interface IRole {
    roleId: number;
    roleName: string
    roleType: number
    roleTypeName: string
    archived: number
    archiveName?: string
    archivedDesc: string
    createTime: number
    mandatoryClockIn?: number
    modifiedTime: number
    roleTag?: number
    autoLogout?: number,
    autoLogoutTime?: number
}

export interface IRoleDetail {
    role?: IRole
    rolePermissions?: IPermissionTree[];
    breakList?: IBreakItem[]
}

export interface IEditRoleDetail{
    roleId: number,
    name: string,
    permissionIdList: number[]
}

/**
 * 查询权限树
 */
export const getPermissionTree = async (): Promise<IPermissionTree[] | undefined> => {
  const res: Response<IPermissionTree[]> = await pepprService.post('/api/peppr-employee/permission/getPermissionTree');
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 创建角色
 */
export const createRole = async (params: ICreateRole): Promise<boolean> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/role/create', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return false;
};

/**
 * 查询角色列表
 */
export const getRoleDetail = async (params: { roleId: number }): Promise<IRoleDetail> => {
  const res: Response<IRoleDetail> = await pepprService.post('/api/peppr-employee/role/getRoleDetail', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 编辑角色
 */
export const editRoleDetail = async (params: IEditRoleDetail): Promise<boolean> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/role/edit', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 获取当前门店未归档角色列表
 */
export const getActiveRoles = async (): Promise<IRole[]> => {
  const res: Response<IRole[]> = await pepprService.post('/api/peppr-employee/role/getActiveRoleList');
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return [];
};
