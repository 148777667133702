import { globalState } from '@/stores';
import React, { useEffect, useRef, useState } from 'react';
import './index.scss'
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import { Button, Select, Table, Empty, Spin, Tooltip } from 'antd';
import useGetState from '@/hooks/useGetState';
import dayjs, { Dayjs } from 'dayjs';
import { getToday, removeNullUndefinedFields, getCookie, formatCurrency } from '@/utils';
import { ApiQueryEmployeeList, ApiQueryLaborReport, ApiExportLaborSummary, ApiApaasQueryExportResult } from '@/request/api';
import { ColumnsType } from 'antd/es/table';
import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { EReport_Status, RangeValueType } from '@/types';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { IDropMenuExportParams, IExportResult } from '@/Components/Export/interface';
import ReactToPrint from 'react-to-print';
import { checkPermission } from '@/utils/permissionUtils';
import { reaction } from 'mobx';
import store from '@/stores/userInfo';
import AddTimeSheet, { TimeSheetType } from '@/Pages/EmployeeTimeSheets/component/addTimeSheet';
import RestaurantTotal from './components/restaurantTotal';
import UserTotal from './components/UserTotal';
import Columns from '@/Components/Columns/Columns';
import UserInfo from '@/stores/userInfo'
import { EmployeeLaborDetailDTO } from './type'

interface IParamsState {
  date: RangeValueType,
  employeeIdList: Array<number>,
  sortByDescTime: boolean,
  dateRangeType: string
}

interface ILaborSummary {
  totalLaborHours: string,
  totalHourlyRate: string,
  totalEstimatedTotalPay: string,
  totalCashTips: string,
  totalCardTips: string,
  totalTips: string,
}

export default function LaborSummary () {
  const { i18n } = globalState;
  const printRef = useRef(null);
  const [isInit, setIsInit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [rangePresets, setRangePresets] = useState([])
  const [employeeAllList, setEmployeeAllList] = useState([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [params, setParams, getParams] = useGetState<IParamsState>({
    date: [dayjs(), dayjs()],
    employeeIdList: [],
    sortByDescTime: false,
    dateRangeType: DATE_RANGE_TYPE.TODAY
  })
  const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showColumnsList, setShowColumnsList] = useState([])
  const [timeSheet, setTimeSheet] = useState<TimeSheetType>();

  const columns = [
    {
      title: i18n?.t('dashboard_report_labor_summary_role'),
      dataIndex: 'roleName',
      headerId: 0,
      render: (_, record) => {
        const isRed = record.clockOutType === 2
        return (
          <>
            <span style={{ color: isRed ? 'red' : 'inherit', marginRight: 2 }}>
              {record?.clockTime || record.roleName ||  '-'}
            </span>
            {
              isRed && <Tooltip title={i18n.t('dashboard_report_auto_clock_out_hint')}>
                <InfoCircleOutlined />
              </Tooltip>
            }
          </>
        );
      },
    },
    {
      title: i18n.t('dashboard_Regular_Hours'),
      dataIndex: 'regularHours',
      headerId: 1,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n.t('dashboard_overtime_hours'),
      dataIndex: 'overTimeHours',
      headerId: 2,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n?.t('laborSummary_totalLaborHours'),
      dataIndex: 'laborHours',
      headerId: 3,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n?.t('laborSummary_hourlyRate'),
      dataIndex: 'regularRate',
      headerId: 4,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_ot_rate') || 'OT Rare',
      dataIndex: 'overTimeRate',
      headerId: 5,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n?.t('laborSummary_estimatedTotalPay'),
      dataIndex: 'estimatedTotalPay',
      headerId: 6,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('dashboard_tippable_net_sales'),
      dataIndex: 'netSalesTippable',
      headerId: 7,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('laborSummary_cardTips'),
      dataIndex: 'cardTips',
      headerId: 8,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_declared_tips'),
      dataIndex: 'declareTips',
      headerId: 9,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_netsales_devided_pay'),
      dataIndex: 'netSalesPayRate',
      headerId: 10,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
  ];

  useEffect(() => {
    initPage();
  }, []);

  const initPage = async () => {
    fetchEmployeeList();
    const _today = await getToday();
    setToday(_today);
    setParams({ ...params, date: [_today, _today] });
    await fetchTableData();
    setIsInit(true);
  }

  const fetchEmployeeList = async () => {
    const _params = { needPage: false }
    const res = await ApiQueryEmployeeList(_params)
    if (res.code !== 0) return;
    const { list = [] } = res.data;
    setEmployeeAllList(list.map(x => ({ label: x.employee.fullName, value: x.employee.employeeId })))
  }

  const fetchTableData = async () => {
    const { date, ...curParams } = getParams();
    const reqParams = {
      startTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      employeeId: !curParams.employeeIdList || curParams.employeeIdList?.length === 0 ? '' : curParams.employeeIdList[0],
    }
    setIsLoading(true);
    const res = await ApiQueryLaborReport(removeNullUndefinedFields(reqParams))
    setIsLoading(false);
    if (res.code !== 0) return;
    const { datas = [] } = res.data;
    setTableData(datas);
  }
  const onDateRangeChange = (dates) => {
    const dateRangeType = rangePresets.find((v) => {
      return (v.value[0].isSame(dates[0])) && (v.value[1].isSame(dates[1]))
    })?.key ?? DATE_RANGE_TYPE.CUSTOM
    setParams(({ ...params, date: dates, dateRangeType }))
    fetchTableData();
  };

  const handleEmployeeChange = (val) => {
    setParams({ ...params, employeeIdList: val ? [val] : undefined });
    fetchTableData();
  }

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }
  const reload = () => {
    fetchTableData();
  }
  const clickRow = (record) => {
    if (getCookie('appCode') === '1' || !hasEditPermission) return;
    if (record?.id) {
      setTimeSheet(record);
      setOpenModal(true);
    }
  }
  // 导出相关
  const fetchApiExport = async (exportType: IDropMenuExportParams) => {
    const { date, ...curParams } = getParams();
    const params = {
      beginTime: date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      exportType: EReport_Status[exportType],
      exportHeaders: showColumnsList
    }
    const res: any = await ApiExportLaborSummary(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult
  }
  useEffect(() => {
    setShowColumnsList(columns.map(x => x.headerId))
    const disposer = reaction(
      () => store.getPermissionIds,
      () => {
        setHasEditPermission(checkPermission('EDIT_TIME_SHEETS'))
      },
      { fireImmediately: true } // 这将使反应立即触发一次
    );
    return () => disposer();
  }, []);

  return (
    <div className="labor-summary-wrap">
      <Spin size="large" spinning={isLoading}>
        <div className="main-wrap">
          <div className="filter-wrap">
            <div className="left-wrap">
              <PepprDatePicker value={params.date} onChange={onDateRangeChange} timeFrame={2} today={today} setPresets={setRangePresets} />
              <Select
                className="employee-select"
                showSearch
                optionFilterProp="label"
                options={employeeAllList}
                value={params.employeeIdList}
                onChange={handleEmployeeChange}
                style={{ width: 200 }}
                allowClear
                placeholder={i18n.t('pc_employee_report_all_employee')}
              />
            </div>
            <div className="right-wrap">
              <Button icon={ <SyncOutlined/> } onClick={ reload }/>
              {getCookie('appCode') !== '1' && <ReactToPrint
                trigger={ () => <Button icon={ <div className="iconfont icon-print"/> }/> }
                content={ () => printRef?.current }
              />}
              {getCookie('appCode') !== '1' && <div className="action-wrap">
                <PepprExportComponent
                  type='button'
                  isDropDown={true}
                  disabled={tableData.length === 0}
                  fetchApiExport={(exportType) => fetchApiExport(exportType)}
                />
              </div>}
              <Columns value={ showColumnsList } options={ columns.map(item => ({ label: item.title, value: item.headerId, disabled: item.headerId === 0 })) } onChange={ handleChangeColumns }/>
            </div>
          </div>
          <div className="print-container" ref={printRef}>
            <div className="print-header">
              <div className="print-info">
                <div className="print-left">
                  <div>{UserInfo.getShopInfo?.orgName}</div>
                  <div>{UserInfo.getShopInfo?.state || ''},{ UserInfo.getShopInfo?.city || ''}</div>
                  <div>{UserInfo.getShopInfo?.streetAddress || ''}</div>
                </div>
                <div className="print-title">
                  <div className='title'>{i18n?.t('overseas_peppr_entrance_laborsummary')}</div>
                  <div>({getParams().date[0].format('MM-DD-YYYY')} - {getParams().date[1].format('MM-DD-YYYY')})</div>
                </div>
                <div className="print-right">{dayjs().format('MM/DD/YYYY - hh:mm A')}</div>
              </div>
            </div>
            {tableData .length > 0 && <RestaurantTotal restaurantInfo={tableData[0]} tableColumns={columns.filter(x => showColumnsList.includes(x.headerId)) as ColumnsType} />}
            {tableData .length > 0 && <UserTotal tableData={tableData.slice(1)} tableColumns={columns.filter(x => showColumnsList.includes(x.headerId)) as ColumnsType} onRow={(row) => clickRow(row)}/>}
          </div>
        </div>
        {tableData.length === 0  && <Empty description={i18n.t('laborSummary_noRecords')} image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>}
      </Spin>
      <AddTimeSheet
        openModal={openModal}
        setOpenModal={setOpenModal}
        timeSheetInfo={timeSheet}
        handleCancel={() => {setTimeSheet(null)}}
        handleOk={() => {fetchTableData()}}
      />
    </div>
  )
}