import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import './ColorFilter.scss'
import { DownOutlined } from '@ant-design/icons';
import { colorFilter } from '../enum'
interface IProps {
  colorText: string,
  handleChangeColorItem?: (colorText: string) => void
}
const { defaultList, normalList } = colorFilter;
const Content = (props: IProps) => {
  const [changeItem, setChangeItem] = useState<any>({});

  useEffect(() => {
    if (!props.colorText) {
      setChangeItem(defaultList[0]);
    } else {
      setChangeItem(normalList.find(fin => fin.color === props.colorText))
    }
  }, [props.colorText])

  const handleClick = (item) => {
    setChangeItem(item);
    props.handleChangeColorItem(item.color);
  }

  return <div className='color-filter-content'>
    <div className='color-filter-area'>
      {
        defaultList.map(m => (
          <div key={m.id} className={`color-filter-area-square color-filter-area-square-default ${m.id === changeItem.id ? 'color-filter-area-square-active' : ''}`}
            onClick={() => handleClick({ id: m.id, color: '' })}>
          </div>
        ))
      }
    </div>
    <div className='color-filter-area'>
      {
        normalList.map(m => (
          <div key={m.id}
            className={`color-filter-area-square ${m.id === changeItem.id ? 'color-filter-area-square-active' : ''}`}
            style={{ 'backgroundColor': m.color }}
            onClick={() => handleClick(m)}>
          </div>
        ))
      }
    </div>
  </div>;
}
const ColorFilter = (props: IProps) => {
  const [colorText, setColorText] = useState<string>('');

  useEffect(() => {
    setColorText(props.colorText);
  }, [props.colorText])

  return (
    <div className='color-filter'>
      <Popover
        content={
          <Content
            colorText={props.colorText}
            handleChangeColorItem={(colorText: string) => {
              setColorText(colorText);
              props.handleChangeColorItem(colorText)
            }}/>}
        placement="bottom"
        trigger="click"
      >
        <div className='color-filter-section'>
          <div className='color-filter-section-inner'>
            <div className={`color-filter-section-tag ${ !colorText ? 'color-filter-section-tag-default' : '' }`} style={{ 'backgroundColor': colorText }}></div>
            <DownOutlined />
          </div>
        </div>
      </Popover>
    </div>
  )
}
export default ColorFilter;