// 定义 settingConfigSchema 左侧控制的JSON Schema
export const settingConfigSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: { type: 'string' },
      title: { type: 'string' },
      defaultValue: { type: 'boolean' },
      disabled: { type: 'boolean' },
      children: { type: 'array' },
    },
    required: ['id', 'title', 'defaultValue', 'disabled', 'children'],
    additionalProperties: false
  }
};
