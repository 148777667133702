import {
  Button,
  DrawerProps,
  Form,
  Input,
  Space,
  Drawer,
  Radio,
  Table,
  Spin,
  RadioChangeEvent,
  Checkbox,
  Select
} from 'antd';
import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { globalState } from '@/stores';
import { isEmpty, isStrictlyEmptyString } from '@/utils';
import { ModelType, ModelTypeList, PosTypeList, PosType, StationModelList, TableModelList, ModelName } from '../../enum';
import './index.scss';
import {
  ApiQueryPosDeviceConfig,
  queryPosDeviceInfo,
  updatePosDeviceInfo,
} from '@/request/PosMonitor';
interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<any>>;
  detailInfo: any;
  type: 0 | 1; // 0代表编辑 1代表新增
  reloadTable: () => void;
}

export default function PosDrawer (props: Props) {
  const [posInfoForm] = Form.useForm();
  const [readerForm] = Form.useForm();
  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');
  const [printers, setPrinters] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsgMap, setErrorMsgMap] = useState<any>({});
  const [type, setType] = useState<PosType>(PosType.STATION);
  const [modelOption, setModelOption] = useState(StationModelList)
  const formModelName = Form.useWatch('modelName', readerForm);
  const [validCardReaderModel, setValidCardReaderModel] = useState<
    Array<ModelType>
  >([]);
  const i18n = globalState.i18n;
  const modelWatched = Form.useWatch('model', readerForm);

  const tableColumns: any = [
    {
      dataIndex: 'printer',
      key: 'printer',
      title: i18n.t('printer'),
      width: 100,
      align: 'center',
      render: (_, row) => <>{row?.name}</>,
    },
    {
      dataIndex: 'prepStation',
      key: 'prepStation',
      title: 'Prep Station',
      width: 100,
      align: 'center',
      render: (_, row) => (
        <>
          <>{row?.prepStations?.map((m) => m.name).join(',')}</>
        </>
      ),
    },
    {
      dataIndex: 'cashDrawer',
      key: 'cashDrawer',
      title: i18n.t('cash_drawer'),
      width: 100,
      align: 'center',
      render: (_, row) => {
        return (
          row?.isRelateCashDrawer && (
            <Checkbox.Group
              disabled
              defaultValue={
                row?.isRelateCashDrawer ? ['isRelateCashDrawer'] : ['']
              }
            >
              <Checkbox value={'isRelateCashDrawer'}></Checkbox>
            </Checkbox.Group>
          )
        );
      },
    },
  ];

  // 初始化数据
  const initData = async () => {
    setLoading(true);
    const validModelRes = await ApiQueryPosDeviceConfig();
    const { validCardReaderModels = [] } = validModelRes?.data ?? {};
    setValidCardReaderModel(validCardReaderModels);
    if (props.type === 0) {
      const {
        posDevice: { id },
      } = props.detailInfo;
      const res = await queryPosDeviceInfo({ deviceId: id });
      const { cardReaders, posDevice, printers } = res?.data ?? {};
      const cardReader = !isEmpty(cardReaders) ? cardReaders[0] : {};
      posInfoForm.setFieldsValue({
        posSN: posDevice?.sn,
        posNickname: posDevice?.name,
        type: posDevice?.type,
        cardReadConfig: posDevice?.cardReadConfig,
      });
      readerForm.setFieldValue('model', cardReader?.model);
      readerForm.setFieldsValue({
        modelSn_Adyen:
          cardReader?.model === ModelType.ADYEN ? cardReader?.modelSn : '',
        modelSn_Ingenico:
          cardReader?.model === ModelType.INGENICO ? cardReader?.modelSn : '',
        modelName: cardReader?.modelName,
      });
      setPrinters(printers);
      setType(posDevice?.type);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (props.open) {
      initData();
    } else {
      setType(PosType.STATION);
      posInfoForm.setFieldValue('type', PosType.STATION);
    }
  }, [props.open, props.type, props.detailInfo]);

  const onSave = async () => {
    if (loading) return;
    const { cardReaders, posDevice, printers } = props.detailInfo ?? {};
    const cardReader =
      !isEmpty(cardReaders) && props.type === 0 ? cardReaders[0] : {};
    const promiseArr = [
      posInfoForm.validateFields(),
      readerForm.validateFields(),
    ];
    const params = await Promise.all(promiseArr);

    console.log('posDevice==?', posDevice, params)

    const reqPosDevice = {
      ...(posDevice?.id && props.type === 0 ? { id: posDevice.id } : {}),
      sn: params[0].posSN,
      name: params[0].posNickname,
      type: params[0].type,
      cardReadConfig: params[0].cardReadConfig
    };
    let reqCardReader = {};
    if (params[1].model) {
      if (params[1].model === ModelType.ADYEN) {
        reqCardReader = {
          ...(cardReader?.id ? { id: cardReader.id } : {}),
          modelSn: params[1].modelSn_Adyen,
          modelName: params[1].modelName,
          model: params[1].model,
        };
      } else if (params[1].model === ModelType.INGENICO) {
        reqCardReader = {
          ...(cardReader?.id ? { id: cardReader.id } : {}),
          modelSn: params[1].modelSn_Ingenico,
          model: params[1].model,
        };
      }
    }
    const req = {
      posDevice: reqPosDevice,
      cardReaders: isEmpty(reqCardReader) ? [] : [reqCardReader],
    };
    setLoading(true);
    const resp = await updatePosDeviceInfo(req);
    const { res, errorMsgMap } = resp?.data ?? {};
    setLoading(false);
    if (res) {
      closeDrawer();
    } else {
      setErrorMsgMap(errorMsgMap);
    }
  };

  const closeDrawer = () => {
    setErrorMsgMap({});
    posInfoForm.resetFields();
    readerForm.resetFields();
    props.reloadTable();
  };

  const onClose = () => {
    setErrorMsgMap({});
    posInfoForm.resetFields();
    readerForm.resetFields();
    props.setOpen((prevState) => !prevState);
  };
  const changeModelName = (val) => {
    if (val === ModelName.TAP) {
      readerForm.setFieldValue('modelSn_Adyen', null)
    }
    readerForm.setFieldValue('modelName', val)
  }
  const formRules = {
    modelName: [
      {
        required: true,
        message: i18n.t('please_enter_x', {
          msg: i18n.t('pc_pos_monitor_model'),
        }),
      },
      {
        pattern: /^[a-zA-Z0-9]+$/,
        message: i18n.t('pc_pos_monitor_input_model_rules'),
      },
    ],
    modelSnAdyen: [
      {
        required: true,
        message: i18n.t('please_enter_x', {
          msg: i18n.t('card_reader_sn_serial_number'),
        }),
      },
      { pattern: /^[0-9]+$/, message: i18n.t('pc_pos_monitor_input_sn_rules') },
      () => ({
        validator (_, value) {
          const chineseRegex = /[\u4e00-\u9fa5]/g;
          const chineseCount = (value.match(chineseRegex) || []).length;
          const totalLength = value.length + chineseCount;
          return totalLength > 15
            ? Promise.reject(
              new Error(i18n.t('frontOfHouse_pc_maximum', { length: 15 }))
            )
            : Promise.resolve();
        },
      }),
    ],
    modelSnIngenico: [
      {
        required: true,
        message: i18n.t('please_enter_x', {
          msg: i18n.t('card_reader_sn_serial_number'),
        }),
      },
      {
        pattern: /^[a-zA-Z0-9]+$/,
        message: i18n.t('pc_pos_monitor_input_model_rules'),
      },
      () => ({
        validator (_, value) {
          const chineseRegex = /[\u4e00-\u9fa5]/g;
          const chineseCount = (value.match(chineseRegex) || []).length;
          const totalLength = value.length + chineseCount;
          return totalLength > 15
            ? Promise.reject(
              new Error(i18n.t('frontOfHouse_pc_maximum', { length: 15 }))
            )
            : Promise.resolve();
        },
      }),
    ],
  };

  return (
    <Drawer
      title={props.type ? i18n?.t('add_pos') : i18n?.t('edit_pos')}
      placement={placement}
      width={500}
      onClose={onClose}
      open={props.open}
      closeIcon={false}
      style={{ backgroundColor: '#F5F5F5', paddingBottom: '80px' }}
      className="delete-under-line"
      extra={
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            {i18n?.t('cancel')}
          </Button>
          <Button type="primary" onClick={onSave}>
            {i18n?.t('save')}
          </Button>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <div className="pos-monitor-content-card">
          <div className="pos-monitor-content-card-title">
            {i18n?.t('pos_info')}
          </div>
          <Form
            form={posInfoForm}
            layout="vertical"
            labelAlign="left"
            colon={false}
          >
            <Form.Item
              label={<div>{i18n?.t('pc_service_charge_type')}</div>}
              name="type"
            >
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setType(e.target.value);
                  if (e.target.value === PosType.STATION) {
                    setModelOption(StationModelList)
                  } else {
                    setModelOption(TableModelList)
                  }
                }}
              >
                {PosTypeList.map((m, index) => (
                  <Radio value={m.value} key={index}>
                    {m.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                <div>
                  {i18n?.t('pos_sn_serial_number')}
                  <span style={{ color: 'red' }}>*</span>
                </div>
              }
              name="posSN"
              style={{ marginBottom: '0px' }}
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    if (isStrictlyEmptyString(value)) {
                      return Promise.reject(i18n?.t('pos_sn_required'));
                    }
                    if (value?.length > 20) {
                      return Promise.reject(
                        i18n?.t('frontOfHouse_pc_maximum', { length: 20 })
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                required
                onBlur={(e) =>
                  posInfoForm.setFieldValue('posSN', e.target.value.trim())
                }
              />
            </Form.Item>
            {errorMsgMap?.posDevice && (
              <div style={{ color: 'red' }} className="ant-form-item">
                <span>{errorMsgMap?.posDevice}</span>
              </div>
            )}
            <Form.Item
              label={<div>{i18n?.t('dashboard_menu_pos_display_name')}</div>}
              style={{ marginTop: '16px' }}
              name="posNickname"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    if (value?.length > 20) {
                      return Promise.reject(
                        i18n?.t('frontOfHouse_pc_maximum', { length: 20 })
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                onBlur={(e) =>
                  posInfoForm.setFieldValue(
                    'posNickname',
                    e.target.value.trim()
                  )
                }
              />
            </Form.Item>
          </Form>
        </div>

        {
          <div className="pos-monitor-content-card">
            <div className="pos-monitor-content-card-title">
              {i18n?.t('card_reader')}
            </div>
            <Form
              form={readerForm}
              layout="vertical"
              labelAlign="left"
              colon={false}
            >
              <Form.Item name="model">
                <Radio.Group>
                  {ModelTypeList.map((m, index) => (
                    <Radio
                      value={m.value}
                      key={index}
                      onClick={(e) => {
                        if (m.value === readerForm.getFieldValue('model')) {
                          readerForm.setFieldValue('model', null);
                        }
                      }}
                      disabled={!validCardReaderModel.includes(m.value)}
                    >
                      {i18n.t(m.label)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              {modelWatched === ModelType.ADYEN && (
                <>
                  <Form.Item
                    name="modelName"
                    label={
                      <div>
                        {i18n.t('pc_pos_monitor_model')}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                    rules={formRules.modelName}
                    validateFirst={true}
                  >
                    <Select options={modelOption} onChange={changeModelName}></Select>
                  </Form.Item>
                  {formModelName !== ModelName.TAP && <Form.Item
                    label={
                      <div>
                        {i18n?.t('card_reader_sn_serial_number')}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                    name="modelSn_Adyen"
                    rules={formRules.modelSnAdyen}
                    validateFirst={true}
                  >
                    <Input
                      onBlur={(e) => {
                        readerForm.setFieldValue(
                          'modelSn_Adyen',
                          e.target.value.trim()
                        );
                        readerForm.validateFields(['modelSn_Adyen']);
                      }}
                      disabled={!validCardReaderModel.includes(ModelType.ADYEN)}
                    />
                  </Form.Item>}

                </>
              )}
              {modelWatched === ModelType.INGENICO && (
                <Form.Item
                  label={
                    <div>
                      {i18n?.t('card_reader_sn_serial_number')}
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                  name="modelSn_Ingenico"
                  style={{ marginBottom: '0px' }}
                  rules={formRules.modelSnIngenico}
                  validateFirst={true}
                >
                  <Input
                    onBlur={(e) => {
                      readerForm.setFieldValue(
                        'modelSn_Ingenico',
                        e.target.value.trim()
                      );
                      readerForm.validateFields(['modelSn_Ingenico']);
                    }}
                    disabled={
                      !validCardReaderModel.includes(ModelType.INGENICO)
                    }
                  />
                </Form.Item>
              )}
              {errorMsgMap?.cardReaders && (
                <div style={{ color: 'red' }} className="ant-form-item">
                  <span>{errorMsgMap?.cardReaders}</span>
                </div>
              )}
            </Form>
          </div>
        }
        {type !== PosType.HANDHELD && <Spin spinning={loading}>
          <div className="pos-monitor-content-card">
            <div className="pos-monitor-content-card-title">
              {i18n?.t('Dashboard_devices_POS_edit_card_login')}
            </div>
            <Form
              form={posInfoForm}
              layout="vertical"
              labelAlign="left"
              colon={false}
            >
              <Form.Item
                label={
                  <div>{i18n?.t('Dashboard_devices_POS_edit_card_login_option')}</div>
                }
                name="cardReadConfig"
                style={{ marginBottom: 0 }}
              >
                <Radio.Group
                  onChange={(e) => {
                    posInfoForm.setFieldValue(
                      'cardReadConfig',
                      e.target.value
                    );
                  }}
                >
                  <Radio value={1}>ON</Radio>
                  <Radio value={0}>OFF</Radio>
                </Radio.Group>
              </Form.Item>
              <div style={{ color: '#999999' }}>
                {i18n?.t('Dashboard_devices_POS_edit_card_login_notice')}
              </div>
            </Form>
          </div>
        </Spin>}


        {!!!props.type && (
          <div className="pos-monitor-content-card">
            <div className="pos-monitor-content-card-title">
              {i18n?.t('printer')}
            </div>
            <Table
              rowKey={'id'}
              columns={tableColumns}
              dataSource={printers}
              pagination={false}
            />
          </div>
        )}
      </Spin>
    </Drawer>
  );
}
