import { pepprService } from '../index';

export interface Response<T = unknown> {
    code: number
    message?: string
    msg?: string
    success?: boolean
    data: T
}

export interface ICheckAccountParams {
    tenantId?: number
    orgId?: number
    employeeId?: number
    email?: string
}
export interface ICheckAccountRes{
    success: boolean
    firstName: string
    restaurantName: string
    accountId: number
}
/**
 *  检查账户
 */
export const checkAccount = async (params: ICheckAccountParams): Promise<ICheckAccountRes> => {
  const res: Response<ICheckAccountRes> = await pepprService.post('/api/peppr-employee/account/checkAccount', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
}

export interface ILoginParams{
    email: string
    password: string
}

export interface ILoginRes{
    accountId: number
    token: string
    orgList: ILogin[]
}

export interface ILogin{
    tenantId: number
    orgId: number
    orgName: string
    orgType: string
    relatedId: number
}
/**
 * 登录
 */
export const login = async (params: ILoginParams): Promise<ILoginRes> => {
  const res: Response<ILoginRes> = await pepprService.post('/api/peppr-employee/login/in', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
}

export interface ISwitchOrg{
    tenantId: number
    orgId: number
}
/**
 * 选择门店登录
 */
export const switchOrg = async (params: ISwitchOrg) => {
  const res: Response<ILoginRes> = await pepprService.post('/api/peppr-employee/login/switchOrg', params);
  if (res.code === 200 || res.success) {
    return res.success;
  }
  return undefined;
}
export interface ILoginInfo{
    tenantId: number
    orgId: number
    orgName: string
    orgType: string
    relatedId: number
    timeZone: string
}
export interface ILoginInfoRes{
    orgList: ILoginInfo[]
    curOrgInfo: ILoginInfo,
    empInfo: any
}
/**
 * 获取登录信息
 */
export const getLoginInfo = async (): Promise<any> => {
  const res: Response<any> = await pepprService.post('/api/peppr-employee/login/getLoginInfo');
  if (res.code === 200 || res.success) {
    return res.data;
  }
}

export interface ICreateParams {
    tenantId: number
    orgId: number
    employeeId: number
    email: string
    password: string
}
/**
 * 创建账号
 */
export const create = async (params: ICreateParams): Promise<boolean> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/account/create', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
}

export interface IBindAccountParams{
    accountId: number,
    employeeId: number
}
/**
 * 账号绑定
 */
export const bindAccount = async (params: IBindAccountParams): Promise<boolean> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/account/accIdBindEmp', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
}

export interface IResetPasswordParams {
    password: string
    email: string
    verificationCode: string
}
/**
 * 重置密码
 */
export const resetPassword = async (params: IResetPasswordParams): Promise<boolean> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/account/resetPwdByVerificationCode', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
}

/**
 * 退出登录
 */
export const ApiLogout = async (): Promise<boolean> => {
  const res: Response<boolean> = await pepprService.post('/api/peppr-employee/login/out');
  if (res.code === 200 || res.success) {
    return res.success;
  }
}