import React, { useState, useEffect } from 'react';
import { Checkbox, Tabs } from 'antd'
import { globalState } from '@/stores';
import type { CheckboxProps } from 'antd';
import printOutDecorationStore from '../../store'
import { observer } from 'mobx-react';
import { toJS, autorun } from 'mobx';
import './Block.scss'
import { IPreviewSectionAttributes } from '../../interface';
import { handleToggleChildrenVisibility } from '../../utils/fnRenderCheckBoxGroup';

export default observer(function Block ({ content, children }) {
  const { i18n } = globalState
  const [checked, setChecked] = useState(true);
  const [disabled, setDisabled] = useState<boolean>(false);

  const onChange: CheckboxProps['onChange'] = (e) => {
    // ***** get previewData *****
    let previewData: Array<IPreviewSectionAttributes> = toJS(printOutDecorationStore.getPreviewData)

    // ***** modifier previewData *****
    // 修改 previewData
    previewData = previewData.map(previewItem => {
      if (previewItem.id === content.id) {
        const newCheckStatus = e.target.checked
        const defaultSort = content?.children[0]?.defaultSort || [[]]
        const childrenItem = handleToggleChildrenVisibility(previewItem, defaultSort, newCheckStatus)
        return { ...previewItem, ...childrenItem,  hide: !newCheckStatus, };
      }
      return previewItem;
    });

    // ***** update previewData *****
    printOutDecorationStore.savePreviewData(previewData)

    setChecked(e.target.checked);
  };

  const enhancedChildren = React.Children.map(children, child =>
    React.cloneElement(child)
  );

  // 实时监听config变化的值
  useEffect(() => {
    const dispose = autorun(() => {
      const currentSection = toJS(printOutDecorationStore.getPreviewData)
        .find(({ id }) => id === content.id);

      if (currentSection) {
        setChecked(!currentSection.hide);
      }
    });

    return () => dispose();
  }, []);


  useEffect(() => {
    if (content) {
      setDisabled(content.disabled)
    }
  }, [content])


  return (
    <div
      className='config-block'
    >
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      >
        {i18n?.t(content.title) ? i18n?.t(content.title) : content.title}
      </Checkbox>

      {
        enhancedChildren.length > 0 &&
        <div style={{ marginTop: '15px' } }>{enhancedChildren}</div>
      }

    </div>
  )
})