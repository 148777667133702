import './editBreaks.scss';
import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import {
  Button,
  Input,
  Select,
  Form,
  Spin,
  Radio,
  Drawer,
  InputNumber,
  Tooltip
} from 'antd';
import { message } from 'antd';
import { ApiCreateBreak, ApiUpdateBreak } from '@/request/api';
import { IBreakItem } from '../interface';
import { QuestionCircleOutlined } from '@ant-design/icons/lib/icons';

interface IProps {
  open: boolean;
  currentRow: IBreakItem;
  handleCancel: () => void;
  fetchListData: () => void
}

export default function EditBreaks (props: IProps) {
  const [breakInfoForm] = Form.useForm();
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  const i18n = globalState.i18n;

  const handleSave = async () => {
    try {
      const values = await breakInfoForm.validateFields();
      let res
      if (props?.currentRow?.breakId) {
        res = await ApiUpdateBreak({ ...values, breakId: props.currentRow.breakId });
      } else {
        res = await ApiCreateBreak(values);
      }
      if (res?.code === 0) {
        message.success(i18n.t('message_success'));
        props.fetchListData();
        props.handleCancel();
      }
    } catch (error) {
      console.error('Failed to save break info:', error);
    }
  };

  const handleFormInputBlur = (form, key) => {
    form.validateFields([key]);
  };

  useEffect(() => {
    if (props.currentRow) {
      breakInfoForm.setFieldsValue(props.currentRow);
    }
    setIsOpenDrawer(props.open);
  }, [props.open,]);

  return (
    <Drawer
      onClose={props.handleCancel}
      open={isOpenDrawer}
      width={750}
      className='breaks-container'
    >
      <Spin spinning={false}>
        <div className="breaks-edit-content">
          <div className="breaks-edit-content-box">
            <span
              style={{ fontSize: '24px', fontWeight: '500', color: '#3d3d3d' }}
            >
              {props?.currentRow?.breakId
                ? i18n?.t('dashboard_teams_breaks_edit_break')
                : i18n?.t('dashboard_teams_breaks_add_new_break')}
            </span>
            <div>
              <Button onClick={props.handleCancel}>{i18n?.t('cancel')}</Button>
              <Button
                type="primary"
                style={{ marginLeft: '12px' }}
                onClick={handleSave}
              >
                {i18n?.t('save')}
              </Button>
            </div>
          </div>
          <div className="breaks-edit-content-wrap">
            <div className="breaks-edit-content-breakInfoForm">
              <Form
                labelCol={{ span: 6 }}
                labelAlign='left'
                form={breakInfoForm}
                validateTrigger="onSubmit"
                initialValues={{ mandatory: 0 }}>
                <Form.Item
                  label={
                    <div>
                      <span>{i18n?.t('dashboard_teams_breaks_add_new_break_name')}</span>
                      <span style={{ color: '#ff4d4f' }}> * </span>
                    </div>
                  }
                  name="name"
                  rules={[
                    () => ({
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject(
                            i18n.t('please_enter_x', {
                              msg: i18n?.t('dashboard_teams_breaks_add_new_break_name'),
                            })
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  {
                    <Input
                      required
                      onBlur={() => {
                        handleFormInputBlur(breakInfoForm, 'name');
                      }}
                      maxLength={50}
                    />
                  }
                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <span>{i18n?.t('dashboard_teams_breaks_add_new_break_type')}</span>
                      <span style={{ color: '#ff4d4f' }}> * </span>
                    </div>
                  }
                  name="type"
                  rules={[
                    () => ({
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject(
                            i18n.t('please_enter_x', {
                              msg: i18n?.t('dashboard_teams_breaks_add_new_break_type'),
                            })
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  {
                    <Select
                      onBlur={() => {
                        handleFormInputBlur(breakInfoForm, 'type');
                      }}
                    >
                      <Select.Option value={1}>{i18n.t('rms_overseas_transaction_order_OrderPayStatus_PAID')}</Select.Option>
                      <Select.Option value={2}>{i18n.t('rms_overseas_transaction_order_OrderPayStatus_UNPAID')}</Select.Option>
                    </Select>
                  }
                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <span>{i18n?.t('dashboard_teams_breaks_add_new_break_lengths')}</span>
                      <span style={{ color: '#ff4d4f' }}> * </span>
                    </div>
                  }
                  name="duration"
                  rules={[
                    {
                      validator (rule, value) {
                        if (!value) {
                          return Promise.reject(
                            i18n.t('please_enter_x', {
                              msg: i18n?.t('dashboard_teams_breaks_add_new_break_lengths'),
                            })
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  {
                    <InputNumber
                      onChange={() => {
                        handleFormInputBlur(breakInfoForm, 'duration');
                      }}
                      precision={0}
                      min={1}
                      max={1439}
                      addonAfter="/mins"
                      style={{ 'width': '100%' }}
                    />
                  }
                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <span>{i18n?.t('dashboard_teams_breaks_add_new_break_mandatory_breaks')}</span>
                      <span style={{ color: '#ff4d4f' }}> * </span>
                      <Tooltip title={'Select this option to require employees to take this break during their shift.'}>
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                      </Tooltip>
                    </div>
                  }
                  name="mandatory"
                >
                  <Radio.Group>
                    <Radio value={0}>{i18n.t('dashboard_teams_breaks_add_new_break_no')}</Radio>
                    <Radio value={1}>{i18n.t('dashboard_teams_breaks_add_new_break_yes')}</Radio>
                  </Radio.Group>

                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}
