import React from 'react';
import {
  DndContext,
  DragEndEvent,
  useSensors,
  useSensor,
  MouseSensor,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';

interface DraggableContainerProps {
  // 排序数据数组
  sortData: Array<{ id: string; [key: string]: any }>;
  // 接收插槽内容
  children: React.ReactNode;
  // 自定义的 onDragEnd 处理函数
  onDragEnd?: (event: DragEndEvent) => void;
  // 获取当前排序数据的回调函数
  getCurrentSortData: (newSortData) => void;
  /**
   * 获取拖拽项的 ID 和目标位置的 ID 的回调函数
   * @param activeId - 当前拖拽项的 ID
   * @param overId - 拖拽目标位置的 ID
   */
  getDragActiveIdAndOverId: (activeId, overId) => void;
}

const DraggableContainer: React.FC<DraggableContainerProps> = ({
  sortData,
  children,
  onDragEnd,
  getCurrentSortData,
  getDragActiveIdAndOverId,
}) => {
  // 默认的拖拽结束处理函数
  const defaultHandleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    // 如果没有拖拽到目标位置，直接返回
    if (!over) return;

    // 获取当前拖拽项的 ID
    const activeId = active.id;
    // 获取拖拽目标位置的 ID
    const overId = over.id;

    console.log('Active ID:', activeId);
    console.log('Over ID:', overId);
    // 调用回调函数，传递当前拖拽项的 ID 和拖拽目标位置的 ID
    getDragActiveIdAndOverId(activeId, overId);

    // 复制排序数据数组
    const items = Array.from(sortData);
    // 获取拖拽项的原始索引
    const oldIndex = items.findIndex((item) => item.id === active.id);
    // 获取拖拽项的新索引
    const newIndex = items.findIndex((item) => item.id === over.id);

    if (oldIndex !== newIndex) {
      // 移动数组中的元素
      const newItems = arrayMove(items, oldIndex, newIndex);
      // 更新排序数据
      getCurrentSortData(newItems);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (onDragEnd) {
      onDragEnd(event);
    } else {
      defaultHandleDragEnd(event);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
  );

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext
        items={sortData?.map((item) => item.id)}
        strategy={verticalListSortingStrategy}
      >
        {children} {/* 渲染插槽内容 */}
      </SortableContext>
    </DndContext>
  );
};

export default DraggableContainer;
