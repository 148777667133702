import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import './index.scss'
import classNames from 'classnames';
import Price from '../components/Price';
import { Tooltip } from 'antd';
import { globalState } from '@/stores';
import { IRevenueSummaryDTO } from '@/Pages/SalesSummary/interface';

interface IProps {
  item: Partial<IRevenueSummaryDTO>
}

export default function TotalSummary (props: IProps) {
  const { i18n } = globalState;
  const { item = {} } = props;
  const { totalAmount, tax, tips, deferredTax, paidInTotal, gratuity, deferredRefund, giftCardSold, taxExemptNetSales, totalSales } = item;

  const dataList = [
    { title: i18n.t('pc_sales_summary_total_amount'), value: totalAmount, tip: i18n.t('pc_sales_summary_total_amount_tooltip'), amountClassName: 'bold' },
    { title: 'Gross Amount', value: totalSales },
    { title: i18n.t('pc_sales_summary_tax_amount'), value: tax },
    { title: i18n.t('pc_sales_summary_tips'), value: tips },
    { title: i18n.t('orderDetail_service_charge_gratuity'), value: gratuity },
    { title: i18n.t('pc_sales_summary_paid_in_total'), value: paidInTotal, tip: i18n.t('pc_sales_summary_paid_in_total_tooltip') },
    { title: i18n.t('pc_sales_summary_deferred_tax'), value: deferredTax },
    { title: i18n.t('pc_sales_summary_deferred_refund'), value: deferredRefund },
    { title: i18n.t('pc_sales_summary_gift_card_sold'), value: giftCardSold },
    { title: i18n.t('Tax_Exempt_Sales'), value: taxExemptNetSales },
  ]
  const amountItemRender = (item, key) => {
    return (
      <div className="item-wrap" key={ key }>
        <div className="top">
          <div className="title">{ item.title }</div>
          {
            item.tip && <Tooltip placement="top" title={ <div>{ item.tip }</div> }>
              <InfoCircleOutlined className="tip-icon"/>
            </Tooltip>
          }
        </div>
        <div className={ classNames('amount', item.amountClassName) }><Price value={ item.value }/></div>
      </div>
    )
  }

  return (
    <div className="sales-summary-total-summary-wrap">
      { dataList.map((x, i) => amountItemRender(x, i)) }
    </div>
  )
}