import './index.scss'
import { globalState } from '@/stores';
import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form, Input, message, Popover } from 'antd'
import PepprTimePicker from '@/Components/PepprTimePicker';
import './index.scss';

import { MoreOutlined } from '@ant-design/icons';
import { ArchivedPointIcon, ActivePointIcon } from '@/Pages/DiningOptions/component/IconComponent';
import { timeStringToMilliseconds, secondsToTimeString } from '@/utils'
import { ApiQueryDayPartList,
  ApiAddDayPart,
  ApiUpdateDayPartById,
  ApiUpdateDayPartStatusByIds } from '@/request/DaypartSettings';
import dayjs from 'dayjs';
const format = 'hh:mm A' // 12小时制
const MAX_DAYPART = 5 // 最多5个时间段
export interface IQueryDayPartList {
  id: number;
  dayPartName: string;
  startTime: number;
  endTime: number;
  archived : 0 | 1; // 'ARCHIVED:1  NOT_ARCHIVED:0'
}

export default function DayPartSettings () {
  const { i18n } = globalState;
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editDayPartId, setEditDayPartId] = useState(null);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const columns: any = [
    {
      dataIndex: 'dayPartName',
      key: 'dayPartName',
      title: i18n.t('DayPart_Name') || 'Daypart Name',
      width: 100,
    },
    {
      dataIndex: 'id',
      key: 'id',
      title: i18n.t('Time') || 'Time',
      width: 140,
      render: (_, row) => {
        return (
          <div>
            {secondsToTimeString(row?.startTime > 86400 ? row?.startTime / 1000 : row?.startTime, true)} - {secondsToTimeString(row?.endTime > 86400 ? row?.endTime / 1000 : row?.endTime, true)}
          </div>
        )
      }
    },
    {
      dataIndex: 'archived',
      key: 'id',
      title: i18n.t('status'),
      width: 50,
      render: (_, row) => {
        return (
          <div>
            {row?.archived ? <span><ArchivedPointIcon /> {i18n.t('archived')}</span> : <span><ActivePointIcon /> {i18n.t('active')}</span>}
          </div>
        )
      }
    },
    {
      key: 'actions',
      title: i18n.t('table_actions'),
      width: 50,
      render: (_, row) => {
        return (
          <div >
            {row.defaultCreated === 1 || row.defaultQuickService === 1 ?
              <Button type="link" icon={<MoreOutlined />} disabled={true}></Button> :
              <Popover content={
                <div>
                  <Button type="link" onClick={() => {archiveDiningOption(row);}}>
                    {i18n?.t?.(row.archived ? 'unarchived' : 'archive')}
                  </Button>
                  <Button type='link' onClick={() => editDaypart(row)}>{i18n.t('edit')}</Button>
                </div>
              }>
                <Button type="link" icon={<MoreOutlined />}></Button>
              </Popover>
            }
          </div>
        )
      }
    },
  ];
  const archiveDiningOption = async (row) => {
    const { id, archived } = row;
    const res = await ApiUpdateDayPartStatusByIds({
      ids: [id],
      archived: archived === 1 ? 0 : 1
    });
    if (res?.code === 0) {
      fetchTableData();
    }
  }

  const UpdateDayPartById = async (row) => {
    const response = await ApiUpdateDayPartById({
      id: editDayPartId,
      dayPartName: row.dayPartName,
      startTime: row.startTime,
      endTime: row.endTime,
    });
    if (response.code === 0) {
      message.success(response.message);
      fetchTableData();
      closeModal();
    }
  }
  const fetchTableData = async () => {
    const response = await ApiQueryDayPartList();
    if (response.code === 0) {
      setDataSource(response.data);
    }
  }
  const editDaypart = (row) => {
    setEditDayPartId(row.id);
    setModalOpen(true);
    console.log(row.id, dayjs(row.startTime / 1000).format())
    form.setFieldsValue({
      dayPartName: row.dayPartName,
      startTime: row.startTime > 86400 ? row.startTime / 1000 : row.startTime,
      endTime: row.endTime > 86400 ? row.endTime / 1000 : row.endTime,
    });
  }

  const addDaypart = () => {
    setModalOpen(true);
    form.resetFields();
  }
  const closeModal = () => {
    form.resetFields();
    setEditDayPartId(null);
    setModalOpen(false);
  }
  const saveDaypart = () => {
    form.validateFields().then(async (values) => {
      if (editDayPartId) {
        UpdateDayPartById(values);
        return;
      }
      const res = await ApiAddDayPart(values)
      if (res.code === 0) {
        message.success(res.message);
        closeModal();
        fetchTableData();
      }
      console.log(values);
    }).catch((error) => {
      console.log(error);
    })
  }
  useEffect(() => {
    fetchTableData();
  }, [])
  return (
    <div className="daypart-settings">
      <div className="m-title">
        <div className="title">{i18n.t('DayPart_Settings')}</div>
        <div className="title"></div>
      </div>
      <div className="table-box">
        <div className="btn-box">
          <Button type="primary" disabled={dataSource.length >= MAX_DAYPART} onClick={() => addDaypart()}>{i18n.t('New_DayPart')}</Button>
        </div>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </div>
      <Modal
        open={modalOpen}
        okText={i18n.t('save')}
        cancelText={i18n.t('cancel')}
        onCancel={()=> closeModal()}
        onOk={()=> saveDaypart()}
        title={editDayPartId === null ? i18n.t( 'New_DayPart') : i18n.t('Edit_DayPart')}
      >
        <Form form={form} className='form-box' { ...formItemLayout } requiredMark={false}>
          <Form.Item name='dayPartName' label={i18n.t('DayPart')} rules={[
            { required: true, message: 'Please input daypart name' }
          ]}>
            <Input maxLength={50} style={{ width: '200px' }} />
          </Form.Item>
          <Form.Item
            name='startTime'
            label={i18n.t('employee_time_sheets_start_time')}
            rules={[
              { required: true, message: 'Please input start time' }
            ]}
          >
            <PepprTimePicker />
          </Form.Item>
          <Form.Item
            name='endTime'
            label={i18n.t('employee_time_sheets_end_time')}
            rules={[
              { required: true, message: 'Please input end time' }
            ]}
          >
            <PepprTimePicker />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}