// 比较左侧的setting是否顺序都一样
// 若一样 -> 表示未拖动过

import { IBaseDefaultMapData, ITag } from '../interface';

// 若不一样 -> 表示拖动过
export function fnSettingConfigArrayAreEqual (fetchSettingArr: IBaseDefaultMapData<ITag>[], defaultSettingArr: IBaseDefaultMapData<ITag>[]): boolean {
  if (fetchSettingArr.length !== defaultSettingArr.length) {
    return false;
  }
  for (let i = 0; i < fetchSettingArr.length; i++) {
    if (fetchSettingArr[i].id !== defaultSettingArr[i].id) {
      return false;
    }
  }
  return true;
}