import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { Button, message, Radio, RadioChangeEvent, Space, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ApiQueryOrderBySeatConfig, ApiSaveOrderBySeatConfig } from '@/request/api';

enum OrderScreenStatus {
  ON = 1,
  OFF = 2,
}

export default function OrderScreen () {
  const { i18n } = globalState;
  const [loading, setLoading] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const initialConfig = {
    orderBySeatValue: OrderScreenStatus.OFF,
    sendNavigationValue: OrderScreenStatus.OFF,
    guestPopupValue: OrderScreenStatus.ON,
    fastCashValue: OrderScreenStatus.OFF,
  };
  const [initData, setInitData] = useState(initialConfig);
  const [orderBySeatValue, setOrderBySeatValue] = useState<OrderScreenStatus>(OrderScreenStatus.OFF);
  const [sendNavigationValue, setSendNavigationValue] = useState<OrderScreenStatus>(OrderScreenStatus.OFF);
  const [guestPopupValue, setGuestPopupValue] = useState<OrderScreenStatus>(OrderScreenStatus.ON);
  const [fastCashValue, setFastCashValue] = useState<OrderScreenStatus>(OrderScreenStatus.OFF);

  const onChange = (e: RadioChangeEvent) => {
    setOrderBySeatValue(e.target.value);
  };
  const onChangeNavigation = (e: RadioChangeEvent) => {
    setSendNavigationValue(e.target.value);
  };
  const onChangeGuestPopUp = (e: RadioChangeEvent) => {
    setGuestPopupValue(e.target.value);
  };
  const onFastCashValue = (e: RadioChangeEvent) => {
    setFastCashValue(e.target.value);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setOrderBySeatValue(initData.orderBySeatValue)
    setSendNavigationValue(initData.sendNavigationValue)
    setGuestPopupValue(initData.guestPopupValue)
    setFastCashValue(initData.fastCashValue)
  };

  const saveOrderScreenConfig = async () => {
    setLoading(true)

    const params = {
      ORDER_BY_SEAT_MODE: orderBySeatValue,
      NAVIGATOR_AFTER_SEND: sendNavigationValue,
      GUEST_POPUP_CONFIG: guestPopupValue,
      FAST_CASH_CONFIG: fastCashValue
    }

    const { data } = await ApiSaveOrderBySeatConfig(params)

    if (data) {
      message.success('success', 2)
      setIsEdit(false)
      setInitData({
        orderBySeatValue,
        sendNavigationValue,
        guestPopupValue,
        fastCashValue
      })
    }

    setLoading(false)
  };

  const queryOrderBySeatConfig = async () => {
    setLoading(true)
    const { data } = await ApiQueryOrderBySeatConfig()

    if (data)  {
      setOrderBySeatValue(data?.ORDER_BY_SEAT_MODE || 2)
      setSendNavigationValue(data?.NAVIGATOR_AFTER_SEND || 2)
      setGuestPopupValue(data?.GUEST_POPUP_CONFIG || 1)
      setFastCashValue(data?.FAST_CASH_CONFIG || 2)

      setInitData({
        orderBySeatValue: data?.ORDER_BY_SEAT_MODE || 2,
        sendNavigationValue: data?.NAVIGATOR_AFTER_SEND || 2,
        guestPopupValue: data?.GUEST_POPUP_CONFIG || 1,
        fastCashValue: data?.FAST_CASH_CONFIG || 2,
      })
    }

    setLoading(false)
  }


  useEffect(() => {
    queryOrderBySeatConfig()
  }, [])

  return (
    <Spin spinning={loading}>
      <div className="orderScreen">
        <div className="top">
          <div className="btn-box" style={{ textAlign: 'right' }}>
            {isEdit ? (
              <>
                <Button
                  onClick={() => cancelEdit()}
                  style={{ marginRight: '16px' }}
                >
                  {i18n.t('cancel')}
                </Button>
                <Button onClick={() => saveOrderScreenConfig()} type="primary">
                  {i18n.t('save')}
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setIsEdit(true)}
                type="primary"
                icon={<EditOutlined />}
              >
                {i18n.t('edit_1')}
              </Button>
            )}
          </div>
        </div>

        <div style={{ margin: '0 0 20px' }}>
          <div style={{ fontWeight: '600' }}>{i18n.t('dashboard_front_of_house_order_screen_order_by_seat')}</div>

          <div style={{ margin: '10px 0' }}>
            {i18n.t('dashboard_front_of_house_order_screen_order_by_seat_reminder')}
          </div>

          <Radio.Group onChange={onChange} value={orderBySeatValue} disabled={!isEdit}>
            <Space direction="vertical">
              <Radio value={1}>{i18n.t('pc_online_orders_on')}</Radio>
              <Radio value={2}>{i18n.t('pc_online_orders_off')}</Radio>
            </Space>
          </Radio.Group>
        </div>

        <div style={{ margin: '0 0 20px' }}>
          <div style={{ fontWeight: '600' }}>{i18n.t('dashboard_front_of_house_ui_option_order_screen_send_navigation') || 'Send user back to floor plan page after clicking Send'}</div>

          <div style={{ margin: '10px 0' }}>
            {i18n.t('dashboard_front_of_house_ui_option_order_screen_send_navigation_reminder') || 'Turn on this setting to automatically go back to floor plan page after sending item(s) in Table Service scenario.'}
          </div>

          <Radio.Group onChange={onChangeNavigation} value={sendNavigationValue} disabled={!isEdit}>
            <Space direction="vertical">
              <Radio value={1}>{i18n.t('pc_online_orders_on')}</Radio>
              <Radio value={2}>{i18n.t('pc_online_orders_off')}</Radio>
            </Space>
          </Radio.Group>
        </div>

        <div style={{ margin: '0 0 20px' }}>
          <div style={{ fontWeight: '600' }}>{i18n.t('dashboard_foh_ui_option_guest_pop_up_setting')}</div>
          <div style={{ margin: '10px 0' }}>
            {i18n.t('dashboard_foh_ui_option_guest_pop_up_setting_reminder')}
          </div>

          <Radio.Group onChange={onChangeGuestPopUp} value={guestPopupValue} disabled={!isEdit}>
            <Space direction="vertical">
              <Radio value={1}>{i18n.t('pc_online_orders_on')}</Radio>
              <Radio value={2}>{i18n.t('pc_online_orders_off')}</Radio>
            </Space>
          </Radio.Group>
        </div>


        <div style={{ margin: '0 0 20px' }}>
          <div style={{ fontWeight: '600' }}>{i18n.t('dashboard_front_of_house_ui_option_order_screen_fast_cash_button')}</div>

          <div style={{ margin: '10px 0' }}>
            {i18n.t('dashboard_front_of_house_ui_option_order_screen_fast_cash_button_reminder')}
          </div>

          <Radio.Group onChange={onFastCashValue} value={fastCashValue} disabled={!isEdit}>
            <Space direction="vertical">
              <Radio value={1}>{i18n.t('pc_online_orders_on')}</Radio>
              <Radio value={2}>{i18n.t('pc_online_orders_off')}</Radio>
            </Space>
          </Radio.Group>
        </div>

      </div>

    </Spin>

  );
}
