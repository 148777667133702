/**
 * api接口统一管理
 */
import axios from 'axios';
import { pepprService, service } from './index'

export const apiArticleEdit = params => service.post('/api/v1/articleEdit', params);

// get day order detail
export const ApiQueryOrder = parmas => service.post('/rms/overseas/InStorePOS/OrderHub/getOrderDailyOverview', parmas);

export const ApiChooseShop = params => service.post('/rms/overseas/MerchantManagementConsole/Authentication/chooseShop', params);


export const ApiQueryByNodeId: any = params => pepprService.post('/api/peppr-org/restaurant/queryByOrgId', params);

export const ApiRestaurantUpdate = params => pepprService.post('/api/peppr-org/restaurant/update', params);

// languages
export const ApiLanguagesQuery: any = params => pepprService.post('/api/peppr-org/restaurant/supportLangs', params);

// region Permission
export const ApiPermissionQueryPage = params => pepprService.post('/api/peppr-employee/permission/getPermissionList', params);
export const ApiPermissionCategoryReferData: any = params => pepprService.post('/api/peppr-employee/category/getAllCategoryIdentity', params);
// endregion

// region Entrances
export const ApiQueryEntrancePage = params => pepprService.post('/api/peppr-employee/entrance/getEntranceList', params);
// endregion

// region Category
export const ApiListQueryCategory = params => pepprService.post('/api/peppr-employee/category/getCategoryList', params);
// endregion

// region Roles
export const ApiRolesQuery = params => pepprService.post('/api/peppr-employee/tool/role/getToolRoleList', params);
// endregion

// region SettleIn
// 获取餐厅入驻元数据
export const ApiRestaurantSettleInMetadata: any = params => pepprService.post('/api/peppr-org/restaurant/settleIn/metadata', params);
// 餐厅入驻
export const ApiRestaurantSettleIn = params => pepprService.post('/api/peppr-org/restaurant/settleIn', params);
// endregion

// region Employees
// Employees List
export const ApiQueryEmployeeList: any = params => pepprService.post('/api/peppr-employee/employee/queryEmployeeList', params);
export const ApiSendCreateAccountUrl = params => pepprService.post(`/api/peppr-employee/employee/${ params.employeeId }/sendCreateAccountUrl`);
export const ApiArchiveEmployee = params => pepprService.post(`/api/peppr-employee/employee/${ params.employeeId }/archive`);
export const ApiUnArchiveEmployee = params => pepprService.post(`/api/peppr-employee/employee/${ params.employeeId }/unarchive`);
export const ApiGetTimesheetDetail = params => pepprService.post('/api/peppr-employee/employee/getTimesheetDetail', params);

export const queryCheckOutReport = params => service.post('/rms/overseas/MerchantManagementConsole/reports/queryCheckOutReport', params);
// Jobs List
export const ApiRoleListQuery = params => pepprService.post('/api/peppr-employee/role/getRoleList', params);
export const ApiGetRoleRelateEmployeeList = params => pepprService.post('/api/peppr-employee/role/getRoleRelateEmployeeList', params);
export const ApiUnbindRoleRelateEmployee = params => pepprService.post('/api/peppr-employee/role/unbindRoleRelateEmployee', params);
export const ApiRoleArchive = params => pepprService.post('/api/peppr-employee/role/archive', params);
export const ApiRoleUnarchived = params => pepprService.post('/api/peppr-employee/role/unarchived', params);
// endregion

// Layout Page
export const ApiGetEmployeeEntranceTree: any = params => pepprService.post('/api/peppr-employee/entrance/getEmpPermissionAndEntranceTree', params);


// 获取accesscode
// export const ApiAccessCode = params => service.post('/rms/overseas/MerchantManagementConsole/Employee/generateAccessCode', params)

// export const ApiJobListQuery = params => service.post('/rms/overseas/MerchantManagementConsole/Authentication/roleListQuery', params);

export const ApiServiceArea = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/listServiceAreas', params);

export const ApiQueryTableList = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryTableListByArea', params);

export const ApiAddTableInfo = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchAddTableInfo', params);
// Reports
// Sales Summary
export const ApiQuerySaleReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/querySaleReport', params);
export const ApiQuerySaleReportNew: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/querySaleReportNew', params);
// Payout Report
export const ApiQueryPayoutSummary = params => pepprService.post('/api/pay-batch/payoutReport/queryPayoutSummary', params);
export const ApiExportPayoutReport = params => pepprService.post('/api/pay-batch/export/exportPayoutReport', params);
export const ApiQueryExportResult = params => pepprService.post('/api/pay-batch/export/queryExportResult', params);
export const ApiQuerySalesDay = params => pepprService.post('/api/pay-batch/payoutReport/querySalesDay', params);
export const ApiQueryPayoutDetail = params => pepprService.post('/api/pay-batch/payoutReport/queryPayoutDetail', params);
export const ApiQueryCalendarDay = params => service.post('/rms/overseas/MerchantManagementConsole/reports/queryCalendarDay', params);


// Fee
export const ApiQueryFeeSummary = params => pepprService.post('/api/pay-batch/feeReport/queryFeeSummary', params);
export const ApiQueryFeeGroupStatistics = params => pepprService.post('/api/pay-batch/feeReport/queryFeeGroupStatistics', params);
export const ApiPageQueryCreditCardStatementsRequest = params => pepprService.post('/api/pay-batch/feeReport/pageQueryCreditCardStatementsRequest', params);
export const ApiDownloadCreditCardStatement = params => pepprService.post('/api/pay-batch/feeReport/downloadCreditCardStatement', params);

// Employee Report
export const ApiQueryOperationLog = (params, config) => service.post('/rms/overseas/MerchantManagementConsole/reports/queryOperationLog', params, config);
export const ApiExportOperationLog = (params) => service.post('/rms/overseas/MerchantManagementConsole/reports/exportOperationLog', params);
export const ApiQueryTimesheet: any = params => pepprService.post('/api/peppr-employee/employee/queryTimesheet', params);
export const ApiExportTimesheet: any = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/exportTimesheet', params);
// front of house notes
export const ApiQueryRemarkVO = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryRemarkVO', params);
export const ApiChangeSwitch = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/changeSwitch', params);
export const ApiBatchSaveNotes = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchSaveNotes', params);
export const ApiSortNotes = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/sortNotes', params);
export const ApiRemarkEdit = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/remarkEdit', params);
export const ApiDeleteAndReorder = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/deleteAndReorder', params);
export const ApiQueryGuestFacingConfig: any = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryGuestFacingConfig', params);
export const ApiSaveGuestFacingConfig = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/saveGuestFacingConfig', params);
export const ApiQueryTipConfigVO: any = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryTipConfigVO', params);
export const ApiSaveTipConfigVO = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/saveTipConfigVO', params);


// Order Screen
export const ApiSaveOrderBySeatConfig: any = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/OrderScreenConfig/save', params);
export const ApiQueryOrderBySeatConfig: any = () => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/OrderScreenConfig/query');

// shift screen
export const ApiSaveTaskCheckListConfig: any = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/TaskCheckListConfig/save', params);
export const ApiQueryTaskCheckListConfig: any = () => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/TaskCheckListConfig/query');

// Service Charge
export const ApiQueryServiceChargeList: any = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryServiceChargeList', params);
export const ApiQueryServiceChargeDetail: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryServiceChargeDetail', params);
export const ApiSaveServiceCharge: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/saveServiceCharge', params);
export const ApiArchiveServiceCharge = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/archiveServiceCharge', params);
export const ApiSortServiceCharge = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/FrontOfHouse/sortServiceCharge', params);
export const ApiQueryTaxes: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/queryTaxes', params);

// labor Summary
export const ApiQueryLaborReport: any = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/queryLaborReport', params);
export const ApiQueryLaborSummary: any = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/queryLaborSummary', params);
export const ApiExportLaborSummary: any = (params) => service.post('/rms/overseas/MerchantManagementConsole/reports/exportLaborReport', params);

// upload
// ********* UploadImage *********
// 获取图片上传信息
export const ApiGetToken = async (params, appUri = 'rmsmmc',) => {
  try {
    const url = '/apaas/api/' + appUri + '/image/getToken'
    return await pepprService.post(url, params)
  } catch (e) {
    return e
  }
};
// 获取原图url
export const getOriginalImage = async (params) => {
  try {
    const { url, formData } = params
    console.log('API:::', formData)
    const config = {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        'Content-Type': formData.type,
      },

    }
    return await axios.put(url, formData, config)
  } catch (e) {
    console.error(e)
    return false
  }
};

// Orders Report
export const ApiQueryAllDiningOptions: any = params => pepprService.post('/apaas/api/rms/overseas/operation/configuration/frontofhouse/FrontOfHouse/queryAllDiningOptions', params);
export const ApiGetAllEmployees = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/Employee/getAllEmployees', params);
export const ApiQueryOrderReportViewList: any = params => pepprService.post('/api/peppr-trade/order/queryOrderReportViewList', params);
export const ApiGetOrderDetail = params => pepprService.post('/api/peppr-trade/order/getOrderDetail', params);
export const ApiExportOrderReport = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/exportOrderReport', params);

// Transactions Report
export const ApiQueryTransactionList = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/queryTransactionList', params);
export const ApiQueryPaymentTransactionReport = (params, config) => service.post('/rms/overseas/MerchantManagementConsole/reports/queryPaymentTransactionReport', params, config);
export const ApiExportPaymentTransactionReport = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/exportTransactionReport', params);
export const ApiApaasQueryExportResult = params => pepprService.post('/apaas/api/rms/overseas/InStorePOS/pay/queryExportResult', params);
export const ApiExportProductMixTopItemReport = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/exportProductMixTopItemReport', params);
export const ApiExportProductMixBreakdownReport = params => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/exportProductMixBreakdownReport', params);

// Payment Methods
export const ApiQueryPaymentMethodList = () => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryPaymentMethodList');
export const ApiBatchArchivedPayments = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchArchivedPayments', { params });
export const ApiQueryPaymentMethodByUuids: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/queryPaymentMethodByUuids', { params });
export const ApiBatchSavePayMethod = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/batchSavePayMethod', { params });
// Tax Rates
export const ApiQueryTaxesList: any = () => service.post('/rms/overseas/MerchantManagementConsole/Menu/queryTaxes');
export const ApiBatchSaveTax: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/batchSaveTax', { params });
export const ApiBatchArchivedTax: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/batchArchivedTax', { params });
export const ApiBatchQueryDetail: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/batchQueryDetail', { params });
// DiscountManage
export const ApiQueryDiscountActivityLists = () => service.post('/rms/overseas/operation/configuration/discount/Promotion/queryActivityLists', { params: { req: {} } });
export const ApiDiscountSortActivity = params => service.post('/rms/overseas/operation/configuration/discount/Promotion/sortActivity', { params });
export const ApiQueryDiscountDetails: any = params => service.post('/rms/overseas/operation/configuration/discount/Promotion/queryDetails', { params });
export const ApiBatchDiscountArchivedActivity = params => service.post('/rms/overseas/operation/configuration/discount/Promotion/batchArchivedActivity', { params });
export const ApiBatchSaveDiscountActivitys = params => service.post('/rms/overseas/operation/configuration/discount/Promotion/batchSaveActivitys', { params });
// Product Mix Report
export const ApiQueryProductMixReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/queryProductMixReport', params);
// Cash Management
export const ApiQueryCashManagement: any = (params, config) => service.post('/rms/overseas/MerchantManagementConsole/reports/queryCashDrawerTransDetail', params, config);
export const ApiExportCashDrawerTransDetail: any = (params) => service.post('/rms/overseas/MerchantManagementConsole/reports/exportCashDrawerTransDetail', params);
export const ApiSortPaymentMethod: any = params => service.post('/rms/overseas/MerchantManagementConsole/FrontOfHouse/sortPaymentMethod', { params });

// Cash Drawer Overview
export const ApiQueryCashDrawerOverview: any = (params, config) => service.post('/rms/overseas/MerchantManagementConsole/reports/queryCashDrawerOverview', params, config);
export const ApiExportCashDrawerOverview: any = (params) => service.post('/rms/overseas/MerchantManagementConsole/reports/exportCashDrawerOverview', params);

// OnlineOrders
export const ApiOnlineOrderConfigSave: any = params => service.post('/rms/overseas/MerchantManagementConsole/OnlineOrderConfig/save', params);
export const ApiOnlineOrderConfigQuery: any = () => service.post('/rms/overseas/MerchantManagementConsole/OnlineOrderConfig/query');
export const ApiIsOnlineOrderingMerchant: any = (params) => pepprService.post('/api/peppr-employee/permission/isOnlineOrderingMerchant', params);
export const ApiIsOnlineOrderingDeviceList: any = (params?) => pepprService.post('/api/peppr-org/device/queryPosDevices', params);
export const ApiUpdatePickupShortUrl: any = (params?) => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/OnlineOrderUrl/save', params);
export const ApiThirdChannelConfigSave: any = (params?) => service.post('/rms/overseas/MerchantManagementConsole/ThirdChannelConfig/save', params);
export const ApiThirdChannelConfigQuery: any = (params?) => service.post('/rms/overseas/MerchantManagementConsole/ThirdChannelConfig/query', params);

// Net Sales Summary
export const ApiQuerySalesCompareReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/querySalesCompareReport', params);

// CashDiscountsProgramReport
export const ApiQueryCashDiscountReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/queryCashDiscountReport', params);
export const ApiExportCashDiscountReport: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/exportCashDiscountReport', params);

// ********* Guest book *********
export const ApiQueryGuestBookList: any = (params, config) => service.post('/rms/overseas/MerchantManagementConsole/reports/queryGuestBookList', params, config);
export const ApiExportGuestBookList: any = params => service.post('/rms/overseas/MerchantManagementConsole/reports/exportGuestBookList', params);
export const ApiSearchGuest: any = params => pepprService.post('/api/peppr-crm/guest/searchGuest', params);
export const ApiUpdateGuest: any = params => pepprService.post('/api/peppr-crm/guest/updateGuest', params)

// menuEvents
export const ApiSaveMenuEventConfig: any = params => service.post('/rms/overseas/MerchantManagementConsole/MenuEventConfig/save', params);
export const ApiQueryMenuEventConfig: any = params => service.post('/rms/overseas/MerchantManagementConsole/MenuEventConfig/query', params);

// WhiteList
export const ApiQueryWhiteList: any = (params) => service.post('/rms/overseas/MerchantManagementConsole/WhiteList/queryAllSatisfiedKeys', params);


// ********* Breaks *********
export const ApiCreateBreak: any = (params) => pepprService.post('/api/peppr-employee/break/create', params);
export const ApiUpdateBreak: any = (params) => pepprService.post('/api/peppr-employee/break/update', params);
export const ApiBreakList: any = (params) => pepprService.post('/api/peppr-employee/break/list', params);
export const ApiArchiveBreak: any = (params) => pepprService.post('/api/peppr-employee/break/archive', params);
export const ApiActiveBreak: any = (params) => pepprService.post('/api/peppr-employee/break/active', params);
export const ApiGetBreakList: any = (params) => pepprService.post('/api/peppr-employee/break/getBreakList', params);

// ********* overTime *********
export const ApiSaveOverTimeConfig:  any = (params) => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/OverTimeConfig/save', params);
export const ApiQueryOverTimeConfig:  any = (params) => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/OverTimeConfig/query', params);


// offline config
export const ApiSaveOfflineConfig: any = (params) => pepprService.post('/api/pay/config/saveOrUpdate', params);
export const ApiQueryOfflineConfig: any = (params) => pepprService.post('/api/pay/config/query', params);

// GiftCard Summary
export const ApiQueryGiftCardSummary: any = (params: { beginTime: string, endTime: string }) => service.post('/rms/overseas/MerchantManagementConsole/reports/giftcard/summary', params)
export const ApiTransactionDetail: any = (params) => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/giftcard/transactionDetail', params)
export const ApiBalanceDetail: any = (params) => pepprService.post('/apaas/api/rms/overseas/MerchantManagementConsole/reports/giftcard/balanceDetail', params)
export const ApiAdjustBalance: any = (params) => pepprService.post('/api/peppr-crm/giftCard/adjustBalance', params)
