export enum ActionLogIdEnum {
  REFUND_BY_TIP = 1,
  REFUND_BY_SERVICE_CHARGE = 2,
  REFUND_BY_TAX = 3,
  REFUND_BY_ITEM = 4,
  VOID_PAYMENT = 5,
  ADD_TIP = 6,
  PAY_CHECK = 7
}

export enum ActionLogMapStr {
  REFUND_BY_TIP = 'Dashboard_order_detail_operation_log_refund_by_tip',
  REFUND_BY_SERVICE_CHARGE = 'Dashboard_order_detail_operation_log_refund_by_service_charge',
  REFUND_BY_TAX = 'Dashboard_order_detail_operation_log_refund_by_tax',
  REFUND_BY_ITEM = 'Dashboard_order_detail_operation_log_refund_by_item',
  VOID_PAYMENT = 'Dashboard_order_detail_operation_log_void_payment',
  ADD_TIP = 'Dashboard_order_detail_operation_log_add_tip',
  PAY_CHECK = 'Dashboard_order_detail_operation_log_pay_check'
}

// detail payment类型的映射
export enum ActionLogPaymentType {
  CASH_PAYMENT = 'Dashboard_order_detail_operation_log_cash',
  CREDIT_CARD_PAYMENT = 'Dashboard_order_detail_operation_log_credit_card',
  GIFT_CARD_PAYMENT = 'Dashboard_order_detail_operation_log_giftcard',
  OTHER_PAYMENT = 'Dashboard_order_detail_operation_log_other_payment_method',
  TAX = 'Dashboard_order_detail_operation_log_tax',
  TIP = 'Dashboard_order_detail_operation_log_tips',
  SERVICE_CHARGE = 'Dashboard_order_detail_operation_log_service_charge'
}

