import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import AddModifierGroup from '../component/AddModifierGroup';
import { debounce, formatPrice } from '@/utils';
import './ModifierTree.scss'
import { CloseCircleOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons';
import { ApiFuzzyQueryMenuOptionGroup } from '@/request/Menus/CreateSubMenu';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ModalAddModifierGroup from '../component/ModalAddModifierGroup';
import { globalState } from '@/stores';

interface IProps {
  row: any
  onChange: (row: any, info: any) => void
}

function SortableItem (props: any) {
  const { id, item, removeItem, editItem } = props;
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({ id });
  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative' } : {}),
  };
  return (
    <div ref={ setNodeRef } style={ style } { ...attributes }>
      <div className='drag-item'>
        <Button
          className="drag-button"
          type="link"
          icon={<MenuOutlined />}
          ref={setActivatorNodeRef}
          style={{ touchAction: 'none', cursor: 'move' }}
          {...listeners}
        ></Button>
        <div className="modifier-item">
          <div className="li">
            <span className='name'>{item.name}</span>
            <span className='price'>{item.modifierPrice ? `$${formatPrice(item.modifierPrice)}` : ''}</span>
            <span className="actions">
              <Button type='link' icon={<EditOutlined />} onClick={() => editItem(item)}></Button>
              <Button type="link" icon={<CloseCircleOutlined />} onClick={() => removeItem(item)} ></Button>
            </span>
          </div>
          <div className="children">
            {item && item.options && item.options.map((opt, index) => {
              return (
                <div className="li" key={index}>
                  <span className='name'>{opt.name}</span>
                  <span className='price'>{opt.modifierPrice ? `$${opt.modifierPrice}` : ''}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ModifierTree (props: IProps) {
  const { row, onChange } = props
  const { i18n } = globalState;

  const [searchItemList, setSearchItemList] = useState([])
  const [tableData, setTableData] = useState<any>([])
  const [modifierGroupId, setModifierGroupId] = useState<string>('')
  const [isOpenModalAddModifierGroup, setIsOpenModalAddModifierGroup] = useState(false);
  const [subMenuId, setSubMenuId] = useState('')
  const inputItem = (e: any) => {
    debounceInput(e)
  }
  useEffect(() => {
    console.log(row, 'row')
    setTableData(row?.menuItem?.modifierGroups || [])
  }, [row])
  const searchItem = async (value) => {
    console.log(value)
    const params = {
      req:{
        combo: false,
        name: value.searchQuery,
      }
    }
    const res = await ApiFuzzyQueryMenuOptionGroup({ params })
    if (res.code === 0) {
      setSearchItemList(res.data)
    }
  }
  const debounceInput = debounce(searchItem, 500)
  const onSelectedInfo = (selectedModifierGroup) => {
    console.log(selectedModifierGroup, 'onSelectedInfo')
    // 直接找出新增的数据项
    const added = selectedModifierGroup.filter(r => !tableData.some(t => t.uuid === r.uuid));
    const addedData = added.map(ele => {
      return {
        ...ele,
        nameType: 2,
        type: 'existingModifier'
      }
    });

    // 直接找出被删除的数据项
    const deletedIds = tableData.filter(t => !selectedModifierGroup.some(r => r.uuid === t.uuid)).map(m => m.uuid);

    // 更新tableData，添加新增的数据项，移除被删除的数据项
    const newTableData = tableData.filter(item => !deletedIds.includes(item.uuid)).concat(addedData);
    setTableData(newTableData);
    onChange(row, newTableData);
  }

  const addNestedModifierGroup = (val) => {
    console.log(val, 'val')
    setModifierGroupId(val.uuid || '')
    setIsOpenModalAddModifierGroup(true);
  }
  const handleCancelModifierGroupList = () => {
    setModifierGroupId('')
    setIsOpenModalAddModifierGroup(false);
  }
  const handleSaveModifierGroupList = (res) => {
    if (!modifierGroupId) {
      setTableData([...tableData, res])
      onChange(row, [...tableData, res])

    } else {
      const newTableData = tableData.map(item => {
        if (item.uuid === modifierGroupId) {
          return res;
        }
        return item;
      });
      setTableData(newTableData)
      onChange(row, [...newTableData])
      setModifierGroupId('')
    }
    setIsOpenModalAddModifierGroup(false);
  }
  const removeItem = (item) => {
    const filterArr = tableData.filter(t => t.uuid !== item.uuid);
    setTableData(filterArr);
    onChange(row, filterArr)
  }
  const onDragChange = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = tableData.findIndex((i) => i.uuid === active.id); // 使用uuid来查找
      const overIndex = tableData.findIndex((i) => i.uuid === over?.id); // 使用uuid来查找
      console.log(activeIndex, overIndex);
      setTableData((previous) => {
        const newList = arrayMove(previous, activeIndex, overIndex);
        console.log(newList);
        onChange(row, newList);
        return newList;
      });
    }
  };
  return (
    <div className="modifier-tree">
      <div className="modifier-box">
        <div className="drag-list">
          <DndContext modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={onDragChange}>
            <SortableContext items={tableData?.map(i => i.uuid) || []} strategy={verticalListSortingStrategy}>
              <div className='drag-box'>
                {tableData && tableData.map(item => (
                  <SortableItem
                    key={item.uuid}
                    id={item.uuid}
                    item={item}
                    editItem={addNestedModifierGroup}
                    removeItem={removeItem}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        </div>
      </div>
      <div className="search-box">
        <AddModifierGroup
          type="modifierGroup"
          dataList={searchItemList}
          rowKey={'uuid'}
          isShowSearchType={false}
          defaultSelectionRow={tableData}
          onGetSearchInfo={inputItem}
          onSelectedInfo={onSelectedInfo} />
        <Button type='link' onClick={() => addNestedModifierGroup('')}>+ {i18n.t('Dashboard_Menu_NestedModGroup')}</Button>
      </div>
      <ModalAddModifierGroup
        title={`${modifierGroupId ? i18n.t('create_modifier_edit_modifier_group') : i18n.t('pc_new_modifier_groups')}`}
        isModalOpen={isOpenModalAddModifierGroup}
        sourceType={row.type}
        modifierGroupId={modifierGroupId}
        handleOk={async (res) => handleSaveModifierGroupList(res)}
        handleCancel={() => handleCancelModifierGroupList()}
      />
    </div>
  )
}