import React, { useEffect, useRef, useState } from 'react';
import { useBus } from 'react-bus';
import { Segmented } from 'antd';
// @assets
import '@/assets/MenuIcon/icon.js';
// @request
import { getLoginInfo } from '@/request/Login/index';

// @stores
import { globalState } from '@/stores';
import store from '@/stores/userInfo';

// @utils
import { parseHash } from '@/utils';
import GlobalDrawer from '@/utils/GlobalDrawer';

// @router
import { routes } from '@/router/routes';
import { useNavigate, Outlet } from 'react-router-dom';
import './index.scss';
import { set } from 'mobx';

export default function LayoutPOS (props: any) {

  const navigate = useNavigate();
  const [path, setPath] = useState<string>(window.location.pathname);
  const bus = useBus();
  // Product Mix/Labor Summary/Sales Summary
  const [options, setOptions] = useState([
    { label: 'pc_product_mix_title', value: '/pos/reports/productMix' },
    { label: 'overseas_peppr_entrance_labor', value: '/pos/reports/laborSummary' },
    { label: 'overseas_peppr_entrance_sales', value: '/pos/reports/sales' },
  ]);

  const findRouteItem = (path) => {
    for (const route of routes) {
      if (route.path === path) return route;
      if (route.children) {
        for (const child of route.children) {
          if (child.path === path) return child;
        }
      }
    }
    return null;
  };
  const handleRouteChangeDrawerControl = () => {
    const hash = window.location.hash;
    if (!hash && GlobalDrawer.drawerContainer) {
      GlobalDrawer.close();
      return;
    }
    const { route, params } = parseHash(hash);
    const matchRoute = findRouteItem(route);
    // routers.ts 文件
    // const matchRoutes = routers.find(x => x.url === route);
    if (!matchRoute) return;
    GlobalDrawer.open({
      component: matchRoute.element,
      props: { ...params, bus },
      navigate,
    });
  };
  const getUserInfo = async () => {
    const res = await getLoginInfo();
    // 处理res的逻辑
    if (res) {
      const { curOrgInfo, empInfo, orgList } = res;
      const { timeZone } = curOrgInfo;
      const shopInfo = {
        timeZoneId: timeZone ?? 'America/Los_Angeles',
        createdTime: empInfo?.createTime,
        name: curOrgInfo?.orgName,
        modifiedTime: empInfo?.modifiedTime,
        nodeId: curOrgInfo?.orgId,
        ...curOrgInfo,
      };
      store.setUserInfo(empInfo);
      localStorage.setItem('shopInfo', JSON.stringify(shopInfo));
      store.setShopInfo(shopInfo);
    }
  };
  useEffect(() => {
    handleRouteChangeDrawerControl();
    getUserInfo();
  }, [window.location.hash]);

  return (
    <div className='layout-pos'>
      <div className="tab-nav">
        <Segmented
          value={path}
          options={
            options.map(item => {
              return {
                label: <div>{globalState.i18n.t(item.label)}</div>,
                value: item.value
              }
            })
          }
          onChange={(value) => {
            setPath(value); // string
            navigate(value);
          }}
          block
        />
      </div>
      <div className="main">
        {!!globalState.i18n && <Outlet />}
      </div>
    </div>
  );
}
