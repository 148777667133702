import React from 'react';
import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { BalanceTableData } from '@/types/giftCard';
import type { TableColumns } from '@/types/table';
import { formatTime, formatFullPrice } from '@/utils';


export const getColumns = (
  i18n,
  handleViewDetail: (record: BalanceTableData) => void
): TableColumns<BalanceTableData> => [
  {
    key: 'cardNumber',
    title: i18n.t('dashboard_report_card_number'),
    dataIndex: 'cardNumberView',
  },
  {
    key: 'firstTranTime',
    title: i18n.t('dashboard_report_first_transaction_time'),
    dataIndex: 'firstTranTime',
    sorter: true,
    render: (value) => value ? formatTime(value, 'MM/dd/yyyy hh:mm:ss tt') : '-',
  },
  {
    key: 'initAmount',
    title: i18n.t('dashboard_report_initial_amount'),
    dataIndex: 'initAmount',
    render: (value) => value ? formatFullPrice(value) : '-',
  },
  {
    key: 'lastTranTime',
    title: i18n.t('dashboard_report_last_transaction_time'),
    dataIndex: 'lastTranTime',
    sorter: true,
    render: (value) => value ? formatTime(value, 'MM/dd/yyyy hh:mm:ss tt') : '-',
  },
  {
    key: 'currentBalance',
    title: i18n.t('dashboard_report_current_balance'),
    dataIndex: 'currentBalance',
    render: (value) => value ? formatFullPrice(value) : '-',
  },
  {
    key: 'action',
    title: i18n.t('action'),
    dataIndex: 'action',
    render: (_, record) => (
      <Button
        type="link"
        style={{ paddingLeft: 0, fontSize: 14 }}
        onClick={() => handleViewDetail(record)}
      >
        { i18n.t('dashboard_report_view_detail') }
      </Button>
    ),
  },
];