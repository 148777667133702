import { service } from '@/request/index'
// 获取
export const getPrintSetting: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/getPrintSetting', { params });
// 保存设置
export const savePrintSetting: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/savePrintSetting', { params: { req: params } });
// 列表
export const listPrinter: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/listPrinter', { params: { req: params } })



// 查询print详情
export const getPrinterInfo: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/getPrinterInfo', { params });

// 查询所有Model的值
export const listPrinterModel: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/listPrinterModel', { params });

// 查询所有Link to POS Station下拉的值
export const queryDevices: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/queryDevices', { params: { req: params } })

// 查询所有Kitchen
export const listPrepStations: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/listPrepStations', { params: { req: params } });

// 保存
export const savePrinter: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/savePrinter', { params: { req: params } });

// 新增
export const addPrinter: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/addPrinter', { params: { req: params } });

// cash drawer list in print
export const getQueryCashDrawerUnbindList: any = (params) => service.post('rms/overseas/MerchantManagementConsole/CashDrawer/queryCashDrawerUnbindList', params);

// 获取配置的详情
export const getPrepStation: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/getPrepStation', { params: { req: params } })

// 归档
export const archivePrinter: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/archivePrinter', { params: { req: params } });

// 取消归档
export const unarchivePrinter: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/unarchivePrinter', { params: { req: params } });


// cash drawer 列表
export const getCashDrawerList: any = () => service.post('rms/overseas/MerchantManagementConsole/CashDrawer/queryCashDrawerList')


// 保存 cash drawer
export const saveCashDrawer: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/CashDrawer/updateCashDrawer', params );
export const saveAddCashDrawer: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/CashDrawer/addCashDrawer', params );

// 取消归档 cash drawer
export const unarchiveCashDrawer: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/CashDrawer/updateCashDrawerArchivedStatusByIds', params );

// 校验 cash drawer name
export const checkCashDrawerName: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/CashDrawer/checkCashDrawerName', params );