import React from 'react'
import { formatFullPrice } from '@/utils';
import './index.scss'
import { Empty } from 'antd';

interface IProps {
  infoData: any,
  title?: string
  setInfoKey: {
    title: string,
    info: any,
    content: any,
    key?: string,
  }
}

export default function Comps (props: IProps) {
  const { infoData = [], title = '', setInfoKey } = props;
  return (
    <div className='comps'>
      <div className="title">{title}</div>
      <div className="info-list">
        {infoData.length > 0 && <div className="info-item">
          <div className="info-item-title"></div>
          <div className="info-item-content">Qty</div>
          <div className="info-item-content">Amount</div>
        </div>}
        {infoData.length > 0 ? infoData.map((item: any, index: number) => (
          <div className={`info-item ${item[setInfoKey.title] === 'Total' ? 'bold' : ''}`} key={item[setInfoKey.key] || index}>
            <div className="info-item-title">{item[setInfoKey.title] || ''}</div>
            <div className="info-item-content">{item[setInfoKey.info] || ''}</div>
            <div className="info-item-content">{formatFullPrice(item[setInfoKey.content], '')}</div>
          </div>
        )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>
    </div>
  )
}