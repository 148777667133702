import { globalState } from '@/stores';
import { Button, message, Radio, Space, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import './index.scss'
import { EditOutlined } from '@ant-design/icons';
import { ApiQueryOverTimeConfig, ApiSaveOverTimeConfig } from '@/request/api';


enum OverTimeStatus {
  NONE = 1,
  OVERTIME = 2,
}


export default function OverTime () {
  const { i18n } = globalState;

  const [initData, setInitData] = useState<{
    overTime: OverTimeStatus,
    }>({
      overTime: OverTimeStatus.NONE,
    });

  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [overTime, setOverTime] = useState<OverTimeStatus>(1);

  const onChangeOverTime = (e) => {
    setOverTime(e.target.value)
  }


  const cancelEdit = () => {
    setIsEdit(false);
    setOverTime(initData.overTime)
  };


  const saveOverTimeConfig = async () => {
    setLoading(true);

    const params = {
      OVER_TIME_CONFIG: overTime,
    };

    const { data } = await ApiSaveOverTimeConfig(params);

    if (data) {
      message.success('success', 2)
      setIsEdit(false)
      setInitData({ overTime })
    }

    setLoading(false);

  }

  const getOverTimeDetail = async () => {
    const { data } = await ApiQueryOverTimeConfig();
    if (data) {
      setOverTime(data.OVER_TIME_CONFIG)
      setInitData({
        overTime: data.OVER_TIME_CONFIG,
      })
    }
  }

  useEffect(() => {
    getOverTimeDetail()
  }, [])

  return (
    <Spin spinning={ loading }>
      <div className='overTime-container'>
        <div className="m-title">
          <div className="title">{i18n.t('dashboard_overtime')}</div>
        </div>
        <div className="top">
          <div className="btn-box" style={{ textAlign: 'right' }}>
            {isEdit ? (
              <>
                <Button
                  onClick={() => cancelEdit()}
                  style={{ marginRight: '16px' }}
                >
                  {i18n.t('cancel')}
                </Button>
                <Button
                  onClick={() => saveOverTimeConfig()}
                  type="primary">
                  {i18n.t('save')}
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setIsEdit(true)}
                type="primary"
                icon={<EditOutlined />}
              >
                {i18n.t('edit_1')}
              </Button>
            )}
          </div>
        </div>

        <div>
          <div className='title'>{i18n.t('dashboard_overtime')}</div>

          <Radio.Group onChange={onChangeOverTime} value={overTime} disabled={!isEdit}>
            <Space direction="vertical">
              <Radio value={1}>{i18n.t('dashboard_tip_level_none')}</Radio>
              <Radio value={2}>{i18n.t('dashboard_over_40_hrs_wk')}</Radio>
            </Space>
          </Radio.Group>
        </div>

      </div>
    </Spin>

  )
}