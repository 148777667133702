import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { toRes } from '@/utils';
import { ApiActiveBreak, ApiArchiveBreak } from '@/request/api';
import { Modal } from 'antd';
import { message } from 'antd';
import Loading from '@/utils/loading';

export default function ModalArchiveConfirm (props) {
  const { i18n } = globalState;
  const { item, fetchData, close } = props;

  const [visible] = useState(true);
  const [isArchived, setIsArchived] = useState();

  useEffect(() => {
    setIsArchived(item?.archived);
  }, []);

  const handleOk = async () => {
    // 归档
    Loading.show();
    let error
    if (isArchived) {
      const [err] = await toRes(ApiActiveBreak({ breakId: item.breakId }));
      error = err;
    } else {
      const [err] = await toRes(ApiArchiveBreak({ breakId: item.breakId }));
      error = err;
    }
    Loading.hide();
    if (error) return;
    message.success(i18n?.t('message_success'));
    fetchData();
    close();

  };

  const handleCancel = () => {
    close();
  };

  return (
    visible && (
      <Modal
        width={600}
        title={`${i18n.t('dashboard_teams_break_would_you_like', {
          status: isArchived
            ? i18n.t('employeeList_pc_unarchived')
            : i18n.t('employeeList_pc_archive'),
          name: item.name,
        })}`}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={i18n.t('dashboard_teams_break_would_you_like_ok')}
        cancelText={i18n.t('dashboard_teams_breaks_would_you_like_cancel')}
        style={{ top: '20%' }}
      >
      </Modal>
    )
  );
}
