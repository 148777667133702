import { pepprService, service } from '../index';

// 获取所有数据
export const getPrintSetting: any = (params = {}) => pepprService.post('/api/peppr-org/device/queryPosDevices', params);

// 查询单条数据
export const queryPosDeviceInfo: any = (params = {}) => pepprService.post('/api/peppr-org/device/queryPosDeviceInfo', params);

// 保存编辑
export const updatePosDeviceInfo: any = (params = {}) => pepprService.post('/api/peppr-org/device/updatePosDeviceInfo', params)

// 删除
export const deletePosDevice: any = (params = {}) => pepprService.post('/api/peppr-org/device/delete', params);

// 获取被允许的CardReaderModel
export const ApiQueryPosDeviceConfig: any = () => service.post('/rms/overseas/MerchantManagementConsole/devices/queryPosDeviceConfig');
