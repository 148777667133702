import { service } from '@/request/index'

export const queryMenuList: any = params => service.post('rms/merchant/menu/list', params);
export const archiveMenu: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/archiveMenu', params);
export const restoreMenuArchive: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/restoreMenuArchive', params);

export const queryMenuGroupList: any = (params) => service.post('rms/merchant/menu/menugroup/list', params);
export const queryMenuOptionGroupList: any = params => service.post('rms/merchant/menu/modifiergroup/list', params);

// export const queryMenuItemList: any = params => service.post('rms/overseas/operation/configuration/menu/MenuItem/list', paramsFormat(MenuItemList, params))
export const queryMenuItemList: any = params => service.post('rms/merchant/menu/item/list', params)
export const viewArchiveUsage: any = params => service.post('/rms/overseas/MerchantManagementConsole/Menu/viewArchiveUsage', params)

// export const queryMenuOptionGroupList: any = params => service.post('rms/overseas/operation/configuration/menu/MenuOptionGroup/list', paramsFormat(MenuOptionGroupList, params));

export const queryMenuOptionList: any = params => service.post('rms/overseas/operation/configuration/menu/MenuOption/list',  params);