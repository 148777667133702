import { Dayjs } from 'dayjs';

export type RangeValueType = [
  start: Dayjs | null | undefined,
  end: Dayjs | null | undefined
];

export interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

export enum WHITE_KEY {
  MENU_EVENT = 'menuEvent'
}

export enum EReport_Status {
  CSV = 1,
  XLS = 2
}