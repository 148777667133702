import React, { ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// 定义 SortableItem 组件的属性接口
interface SortableItemProps {
   // 组件的 id
  id: string;
   // 接收插槽内容
  children: ReactNode;
}

// 定义 SortableItem 组件
const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {
  // 使用 useSortable 钩子获取拖拽相关的属性和方法
  const {
    // 可拖拽元素的属性
    attributes,
    // 事件监听器
    listeners,
    // 设置节点引用
    setNodeRef,
    // 是否正在拖拽
    isDragging,
    // 拖拽时的变换
    transform,
    // 拖拽时的过渡效果
    transition,
  } = useSortable({
    id, // 使用传入的 id
  });

  // 返回渲染的 JSX 元素
  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        padding: isDragging ? 8 : 0,
        marginBottom: isDragging ? 0 : 15,
        borderRadius: isDragging ? 4 : 0,
        backgroundColor: isDragging ? 'pink' : '#fff',
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
    >
      {children} {/* 渲染传入的插槽内容 */}
    </div>
  );
};

export default SortableItem;
