import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd'
import { globalState } from '@/stores';
import './Header.scss'
import { IUpdatePrintConfigParams, ApiUpdatePrintConfig } from '@/request/PrintOutDecorations';
import printOutDecorationStore from '../store'
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { getQuerys } from '@/utils';
import { IBaseDefaultMapData, IPreviewSectionAttributes, ITag } from '../interface';
import { ConfigTypeValue } from '@/Pages/PrintOutDecoration/interface';
import { useNavigate } from 'react-router-dom';
import { fnValidatePreviewSchema, fnValidateSettingConfigSchema } from '../utils/fnValidatePreviewSchema';



export default observer(function Header () {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);

  const isDragTypeList: ConfigTypeValue[] = ['ShiftReport']

  const handleOk = () => {
    setIsModalOpen(false);
  }
  const handleCancel = () => {
    setIsModalOpen(false);
    navigate('/device/printer?from=printOutDecoration');
  }

  const cancelPrintOutDecoration = () => {
    setIsModalOpen(true);
  }
  const savePrintOutPreviewData = async () => {
    // 从当前页面的 URL 中获取查询参数中的 type 值
    const { type } = getQuerys(location.href) as { type: ConfigTypeValue };

    // ***** get previewData *****
    const previewData: Array<IPreviewSectionAttributes>  = toJS(printOutDecorationStore.getPreviewData)

    // ***** get settingConfigDetailsData *****
    const settingConfigData: Array<IBaseDefaultMapData<ITag>>  = toJS(printOutDecorationStore.settingConfigData)


    // 先校验数据的合法性
    const validateData = () => {
      const isPreviewDataValid = fnValidatePreviewSchema(previewData);
      const isSettingConfigDataValid = isDragTypeList.includes(type) ? fnValidateSettingConfigSchema(settingConfigData) : true;

      return isPreviewDataValid && isSettingConfigDataValid;
    };
    if (!validateData())  return;


    // 设置保存按钮的加载状态为 true，表示正在进行保存操作
    setSaveButtonLoading(true);

    const params: IUpdatePrintConfigParams = {
      configType: (type || 'Check') as ConfigTypeValue,
      configDetails: previewData,
      settingConfigDetails: isDragTypeList.includes(type) ? settingConfigData : null,
      version: toJS(printOutDecorationStore.getConfigVersion)
    }
    // ** API FETCH **
    const res = await ApiUpdatePrintConfig(params)

    setSaveButtonLoading(false);
    if (res.code === 0 && res.data) {
      const { version } = res.data
      printOutDecorationStore.saveConfigVersion(version)
      printOutDecorationStore.saveIsEditPrintOutConfig(false);
      message.success(i18n.t('message_success'));
    }
  }
  return (
    <div className='print-out-header'>
      <div>{ i18n?.t('print_decoration_printout_configuration') }</div>
      <div>
        <Button
          style={{ marginRight: 10 }}
          onClick={cancelPrintOutDecoration}
        >{i18n.t('cancel')}</Button>
        <Button
          type="primary"
          onClick={savePrintOutPreviewData}
          loading={saveButtonLoading}
        >{i18n.t('save')}</Button>
      </div>
      <Modal
        title='Unsaved Changes'
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleOk}
        okText={'Discard'}
        cancelText={'Cancel'}>
        <p>Any changes you made will be lost.</p>
      </Modal>
    </div>
  )
})