import { useState, useEffect, useRef } from 'react';

export function useCountDown (duration: number) {
  const [count, setCount] = useState(duration);
  const [flag, setFlag] = useState<boolean>(false);
  // @ts-expect-error ignore
  const timeIdRef = useRef<number>();

  useEffect(() => {
    if (flag) {
      timeIdRef.current = window.setInterval(() => {
        setCount((prevCount) => {
          if (prevCount <= 0) {
            clearInterval(timeIdRef.current);
            setFlag(false);
            return duration;
          } else {
            return prevCount - 1;
          }
        });
      }, 1000);
    }

    return () => {
      if (timeIdRef.current) {
        clearInterval(timeIdRef.current);
      }
    };
  }, [flag]);

  const handleClearTimeIdRef = () => {
    clearInterval(timeIdRef.current);
  }
  const handleStartCountDown = () => {
    setFlag(true);
  }
  return {
    count,
    handleStartCountDown,
    handleClearTimeIdRef
  };
}