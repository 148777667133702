import React, { useEffect, useState } from 'react';
import { Checkbox, Divider } from 'antd';
import { IRenderConfigProps } from '../../interface';
import { observer } from 'mobx-react';
import './RenderCheckBoxGroup.scss'
import { toJS } from 'mobx';
import printOutDecorationStore from '../../store'
import { configDataOptions } from '../../mapData/typeMapConfigDataOptions';
import { handleParentVisibilityBasedOnChildren, handleToggleChildrenVisibility } from '../../utils/fnRenderCheckBoxGroup';
import { globalState } from '@/stores';

export default observer(function RenderCheckBoxGroup (props: IRenderConfigProps<'checkboxGroup'>)  {
  const { i18n } = globalState

  const { content, sectionId } = props
  const { defaultValue, defaultSort, modifier } = content;

  const [defaultValueOptions, setDefaultValueOptions] = useState<Array<any>>([])
  const [currentValueOptions, setCurrentValueOptions] = useState<Array<string>>([])
  const [defaultSortList, setDefaultSortList] = useState<Array<string[]>>([])
  const [checkboxGroupIds, setCheckboxGroupIds]  = useState<Array<string>>([])

  const onChange = (checkedValuesList) => {
    const filteredValues = checkboxGroupIds.filter(value => !checkedValuesList.includes(value));

    // ***** update ConfigUI *****
    // ***** get previewData *****
    let previewData = toJS(printOutDecorationStore.getPreviewData)

    // ***** modifier previewData *****
    previewData = previewData.map(item => {
      if (item.id === sectionId) {

        const updatedSort = defaultSortList.map(group => group.map(item => filteredValues.includes(item) ? '' : item).filter(f => !!f));

        Object.keys(item.children).forEach(key => {
          if (filteredValues.includes(key)) {
            item.children[key].hide = true;
          } else {
            item.children[key].hide = false;
          }
        });

        // 处理是否全部hide的情况，则父级的disabled为false -> hide：true。若父级的disabled为true -> hide不变
        const previewItem = handleParentVisibilityBasedOnChildren(item)

        // 更新item数组的状态
        return { ...previewItem, sort: updatedSort };
      }
      return item;
    });

    // ***** update previewData *****
    printOutDecorationStore.savePreviewData(previewData)
  };

  // 是modifier属性的checkbox的change
  const onChangeModifierCheckbox = (value) => {

    // TODO: 目前只考虑一个modifier里面只有一个的情况
    const modifierKey = defaultValue[0].id

    // ***** update ConfigUI *****
    // ***** get previewData *****
    let previewData = toJS(printOutDecorationStore.getPreviewData)

    // ***** modifier previewData *****
    previewData = previewData.map(item => {
      if (item.id === sectionId) {
        item.modifier[modifierKey] = !item.modifier[modifierKey]
      }
      return item
    })

    // ***** update previewData *****
    printOutDecorationStore.savePreviewData(previewData)
  }


  useEffect(() => {
    // 存sortList值
    // TODO: 要统一改为模版的default中的值，不然需要维护两套
    if (defaultSort) {
      setDefaultSortList(defaultSort)
    }

  }, [defaultSort])

  useEffect(() => {
    // preview 当前值
    const previewData = toJS(printOutDecorationStore.getPreviewData);
    let visibleChildrenKeys;


    previewData.forEach(item => {
      if (item.id === sectionId) {
        visibleChildrenKeys = Object.keys(item.children).filter(key => !item.children[key].hide);
      }
    })

    // config 默认值
    if (defaultValue) {
      // TODO: 目前只有一个，后续modifier多的话，这里在优化
      if (defaultValue[0]?.modifier?.isModifier) {
        const mapModifierId = defaultValue[0]?.id || ''
        previewData.forEach(item => {
          if (item.id === sectionId) {
            // 若section的hide被隐藏，则modifier对应的checkbox置灰
            defaultValue[0].disabled = item.hide
            if (item?.modifier?.[mapModifierId]) {
              visibleChildrenKeys.push(mapModifierId)
            }
          }
        })
      }

      // 转多语言
      const translateMultipleOptions = defaultValue.map(option => ({
        ...option,
        label: i18n?.t(option.label) ? i18n?.t(option.label) : option.label
      }));

      setCurrentValueOptions(visibleChildrenKeys);
      setDefaultValueOptions(translateMultipleOptions)
    }





    // 存当前section的全量默认值checkBoxGroup的值
    const typeParamsInUrl = toJS(printOutDecorationStore.getUrlParamsType)
    const checkboxGroupIdsList = [];
    configDataOptions[typeParamsInUrl].forEach(item => {
      if (item.id === sectionId && item.children) {
        item.children.forEach(child => {
          if (child.tag === 'checkboxGroup') {
            // 将符合条件的child的id添加到数组中
            child.defaultValue.forEach(dItem => {
              checkboxGroupIdsList.push(dItem.id);
            });
          }
        });
        setCheckboxGroupIds(checkboxGroupIdsList)
      }
    })

  }, [printOutDecorationStore.getPreviewData, sectionId, defaultValue]);


  return (
    <div className="render-check-box-group">
      {
        modifier?.dividerTop && <Divider />
      }

      <Checkbox.Group
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
        value={currentValueOptions}
        options={defaultValueOptions}
        // TODO:modifier 的change逻辑需要做差异化
        onChange={values =>
          defaultValueOptions[0]?.modifier?.isModifier
            ? onChangeModifierCheckbox(values)
            : onChange(values)}
      />

      {
        modifier?.dividerBottom && <Divider />
      }
    </div>
  );
})