import Ajv from 'ajv';
const ajv = new Ajv({ allErrors: true });

import { previewSchema } from '../Schema/previewSchema';
import { IBaseDefaultMapData, IPreviewSectionAttributes, ITag } from '../interface';
import { message } from 'antd';
import { settingConfigSchema } from '../Schema/settingConfigSchema';


// 将 validate.errors 拼接成指定格式的错误提示字符串
function formatErrors (errors) {
  // 初始化一个空数组，用于存储格式化的错误信息
  const formattedErrors = [];

  // 遍历错误数组
  errors.forEach((error, index) => {
    // 获取错误的路径和消息
    const path = error.instancePath.replace(/^\/|\/$/g, '').replace(/\//g, '-');
    const message = error.message;

    // 拼接成指定格式的字符串，并添加到数组中
    formattedErrors.push(`error ${index + 1}: "${path}" ${message}`);
  });

  // 返回拼接后的错误提示字符串
  return formattedErrors.join('\n');
}

// 验证previewData数据
export const fnValidatePreviewSchema = (previewData?: Array<IPreviewSectionAttributes>): boolean => {
  const validate = ajv.compile(previewSchema);

  const valid = validate(previewData);

  if (valid) {
    console.log('success validate previewSchema');
    return true
  } else {
    const errorMessage = formatErrors(validate.errors)
    console.log('validate previewSchema fail:', errorMessage);
    message.error(errorMessage);
    return false
  }
}

// 验证 settingConfig 数据
export const fnValidateSettingConfigSchema = (settingConfigData?: Array<IBaseDefaultMapData<ITag>>): boolean => {
  const validate = ajv.compile(settingConfigSchema);

  const valid = validate(settingConfigData);

  if (valid) {
    console.log('success validate settingConfigSchema');
    return true
  } else {
    const errorMessage = formatErrors(validate.errors)
    console.log('validate settingConfigSchema fail:', errorMessage);
    message.error(errorMessage);
    return false
  }
}
