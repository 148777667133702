import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AmplitudeWrapper from '../utils/amplitudeWrapper';

const useRouteListener = (amplitude: AmplitudeWrapper) => {
  const location = useLocation();

  useEffect(() => {
    amplitude.logPageView(location.pathname); // 上报页面访问事件
  }, [location, amplitude]);
};

export default useRouteListener;
