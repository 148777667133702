import service from '@/request';
import { _i18n } from '@/utils/language';
export const defaultAndInheritsData = {
  defaultModifierGroup: [],
  defaultPrepStations: [],
  prepStations: [],
  defaultTaxRateConfigs: [],
  taxRateConfigs: [],
  defaultSalesCategoryConfigs: [],
  salesCategoryConfigs: [],
  serviceAreas: []
}

export const colorFilter = {
  defaultList: [
    {
      color: '#ffffff',
      id: '#ffffff'
    }
  ],
  normalList: [
    '#F4E1EB',
    '#F0C7DD',
    '#D9BACC',
    '#D0CBE0',
    '#DCC1E9',
    '#BBADBD',
    '#D8E4F1',
    '#B9D6ED',
    '#C4CDE7',
    '#FAECBA',
    '#FAD88D',
    '#F4D2B3',
    '#DEE2C0',
    '#CFE5B1',
    '#BEDFD4'].map(ele => ({ color: ele, id: ele }))
}
export const dayOptions = [
  { label: _i18n?.t('mon'), value: 1 },
  { label: _i18n?.t('tue'), value: 2 },
  { label: _i18n?.t('wed'), value: 3 },
  { label: _i18n?.t('thu'), value: 4 },
  { label: _i18n?.t('fri'), value: 5 },
  { label: _i18n?.t('sat'), value: 6 },
  { label: _i18n?.t('sun'), value: 7 },
];
export const DAYS_MAP = {
  1: _i18n?.t('mon'),
  2: _i18n?.t('tue'),
  3: _i18n?.t('wed'),
  4: _i18n?.t('thu'),
  5: _i18n?.t('fri'),
  6: _i18n?.t('sat'),
  7: _i18n?.t('sun')
}