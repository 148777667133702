import React from 'react'
import { Button, Result } from 'antd';
import store from '@/stores/userInfo'
import { getCookie } from '@/utils';

const App: React.FC = () => {
  return (
    getCookie('appCode') !== '1' ? <Result
      status="500"
      title="Sorry, there was an error with the page."
      subTitle=" Please contact the system administrator."
      extra={<Button type="primary" href="/">Back Home</Button>}
    /> : <Result
      status="500"
      title="Sorry, there was an error with the page."
      subTitle="Please click the button to Reload and try again."
      extra={<Button type="primary" onClick={() => window.history.go(-1)}>Reload</Button>}
    />
  )};

export default App;