import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd'
import { globalState } from '@/stores';
import DoorDash from './DoorDash'
import './index.scss'
import store from '@/stores/userInfo';
import { checkPermission } from '@/utils/permissionUtils';
import { reaction } from 'mobx';
export default function OnlineOrders () {
  const { i18n } = globalState;
  const [tabValue, setTabValue] = useState('ONLINE_DOORDASH')
  const [tabsItems, setTabsItems] = useState(null)
  const getTabsItems = () => {
    const items = [];
    items.push({
      key: 'ONLINE_DOORDASH',
      label: 'DoorDash',
      children: <DoorDash />,
    });

    return items;
  }
  useEffect(() => {
    // 如果当前选中的tab不在可用的tabs中，则选择第一个可用的tab
    if (tabsItems && tabsItems.length > 0 && !tabsItems.some(item => item.key === tabValue)) {
      setTabValue(tabsItems[0].key);
    }
  }, [tabsItems, tabValue]);
  useEffect(() => {
    const disposer = reaction(
      () => store.getPermissionIds,
      () => {
        const items = getTabsItems();
        console.log('items', items);
        setTabsItems(items);
        console.log('ONLINE_PICK_UP permission:', checkPermission('ONLINE_PICK_UP'));
        console.log('ONLINE_DOORDASH permission:', checkPermission('ONLINE_DOORDASH'));
      },
      { fireImmediately: true } // 这将使反应立即触发一次
    );
    return () => disposer();
  }, [tabValue]);
  const changeTab = (value) => {
    setTabValue(value);
  }

  const tabBarExtraContent = {
    left: <div className="main-title">{ i18n.t('dashboard_3po') }</div>
  }

  return (
    <div className="online-orders-wrap">
      <Tabs
        tabBarExtraContent={tabBarExtraContent}
        onChange={changeTab}
        activeKey={tabValue}
        items={getTabsItems()}
      />
    </div>
  )
}