import { makeAutoObservable, toJS } from 'mobx';
import { ConfigTypeValue, IBaseDefaultMapData, IPreviewSectionAttributes, ITag } from './interface';
import { handleRestaurantInfoFusionPreview } from './utils/fnPreviewDataFusion';

interface ActiveStore {
  previewData: Array<IPreviewSectionAttributes>;
  version: number;
  urlParamsType: ConfigTypeValue;
  restaurantInfo: IRestaurantRealInfoData
  isEditPrintOutConfig: boolean,
  defaultConfigTemplateData: Array<IPreviewSectionAttributes>;
  settingConfigData: Array<IBaseDefaultMapData<ITag>>;
}

export type IRestaurantRealInfoData = typeof restaurantRealInfoBaseData;

const restaurantRealInfoBaseData = {
  name: '',
  contactPhone: '',
  streetAddress: '',
  city: '',
  state: '',
  zipcode: ''
}

class PrintOutDecorationStore {
  activeStore: ActiveStore = {
    previewData: [],
    version: 0,
    urlParamsType: 'Check',
    restaurantInfo: restaurantRealInfoBaseData,
    isEditPrintOutConfig: false,
    defaultConfigTemplateData: [],
    settingConfigData: []
  }

  constructor () {
    makeAutoObservable(this);
  }

  // 保存preiview的数值
  /**
   * @parmas source: 修改preview的来源
   * 'fetchApi': 来源于接口
  */
  savePreviewData (previewData: Array<IPreviewSectionAttributes>, source?: string) {
    // 处理一些动态获取融合到preview的数据
    const fusionPreviewData = handleRestaurantInfoFusionPreview(toJS(this.getRestaurantInfo), previewData);
    this.activeStore.previewData = [...fusionPreviewData] ;

    console.log('savePreviewData==>', previewData)

    if (source !== 'fetchApi') {
      this.saveIsEditPrintOutConfig(true)
    }
  }

  get getPreviewData () {
    return this.activeStore.previewData;
  }

  /**
   * 保存 defaultConfigTemplate 的数值
  */
  saveDefaultConfigTemplateData (defaultConfigTemplate: Array<IPreviewSectionAttributes>) {
    this.activeStore.defaultConfigTemplateData = defaultConfigTemplate
  }

  get defaultConfigTemplateData () {
    return this.activeStore.defaultConfigTemplateData;
  }


  /**
   * 保存 settingConfigDetails 的数值
  */
  saveSettingConfigData (settingConfigData: Array<IBaseDefaultMapData<ITag>>) {
    console.log('saveSettingConfigData==>', settingConfigData)
    this.activeStore.settingConfigData = settingConfigData
  }

  get settingConfigData () {
    return this.activeStore.settingConfigData;
  }




  // 保存get preview返回的verson的数值
  saveConfigVersion (version: number) {
    this.activeStore.version = version;
  }

  get getConfigVersion () {
    return this.activeStore.version;
  }

  // 保存url链接上面的type值，去请求后端的时候需要发送对应type
  saveUrlParamsType (type: ConfigTypeValue) {
    this.activeStore.urlParamsType = type;
  }

  get getUrlParamsType () {
    return this.activeStore.urlParamsType;
  }

  // 动态获取restaurantInfo的数据
  saveRestaurantInfo (data: any) {
    this.activeStore.restaurantInfo = { ...data };
  }

  get getRestaurantInfo () {
    return this.activeStore.restaurantInfo;
  }

  // 保存页面是否有修改的动作痕迹
  saveIsEditPrintOutConfig (isEdit: boolean) {
    this.activeStore.isEditPrintOutConfig = isEdit;
  }

  get getIsEditPrintOutConfig () {
    return this.activeStore.isEditPrintOutConfig;
  }

}


const printOutDecorationStore = new PrintOutDecorationStore();
export default printOutDecorationStore;
