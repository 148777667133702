import React, { useMemo, useCallback, useEffect } from 'react';
import { Space, Form, Input, Select } from 'antd';
import type { FilterContainerProps, SelectFilterItem } from './types';
import { useFilterOptions } from './useFilterOptions';
import { SearchOutlined } from '@ant-design/icons';
import PepprDatePicker from '@/Components/DatePicker';
import { SelectEmptyContent } from '@/Components/EmptyContent';
import { formatDate } from '@/utils';

const DEFAULT_NAME = {
  dateRange: 'dateRange',
  search: 'search',
};

import './index.scss';

/**
 * 过滤器容器
 * @param filters 过滤器
 * @param onFilterChange 过滤器变化
 * @param initialValues 初始值
 * @param className 类名
 * @param style 样式
 */
const FilterContainer: React.FC<FilterContainerProps> = ({
  filters,
  onFilterChange,
  initialFilterValues,
  className,
  style,
}) => {
  const [form] = Form.useForm();
  const { optionsMap, loadingMap } = useFilterOptions(filters);

  const filterItems = useMemo(() => {
    return filters.map((filter) => {
      const options = filter.type === 'select'
        ? (filter as SelectFilterItem).options || optionsMap[filter.name]
        : undefined;
      const commonProps = {
        style: { width: filter.width || 250 },
        placeholder: filter.placeholder,
      }
      let filterComponent;
      switch (filter.type) {
      case 'select':
        filterComponent = (
          <Select
            {...commonProps}
            allowClear
            notFoundContent={ SelectEmptyContent }
            options={options}
            loading={loadingMap[filter.name]}
            labelInValue={filter.labelInValue}
          />
        );
        break;
      case 'dateRange':
        filterComponent = (
          <PepprDatePicker today={ filter.today } />
        );
        break;
      case 'search':
      default:
        filterComponent = (
          <Input
            {...commonProps}
            allowClear
            prefix={<SearchOutlined />}
          />
        );
        break;
      }

      return (
        <Form.Item
          key={filter.name}
          name={filter.name || DEFAULT_NAME[filter.type]}
          label={filter.label}
          tooltip={filter.tooltip}
        >
          {filterComponent}
        </Form.Item>
      );
    });
  }, [filters, optionsMap, loadingMap]);

  const handleValuesChange = useCallback((_: any, allValues: any) => {
    if (allValues.dateRange) {
      allValues.startTime = formatDate(allValues.dateRange[0]);
      allValues.endTime = formatDate(allValues.dateRange[1]?.endOf('day'));
      delete allValues.dateRange;
    }
    onFilterChange({ ...allValues });
  }, [onFilterChange]);

  useEffect(() => {
    initialFilterValues?.dateRange && form.setFieldsValue({
      dateRange: initialFilterValues.dateRange
    })
  }, [initialFilterValues?.dateRange])

  return (
    <div
      className={`filter-container ${className || ''}`}
      style={style}
    >
      <Form
        form={form}
        layout="inline"
        onValuesChange={handleValuesChange}
        initialValues={ initialFilterValues }
      >
        <Space wrap size={[16, 16]}>
          {filterItems}
        </Space>
      </Form>
    </div>
  );
};

export default React.memo(FilterContainer);