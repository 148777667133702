import { Divider, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import Price from '../components/Price';
import './index.scss'
import { IReportData } from '@/Pages/SalesSummary/interface';
import { globalState } from '@/stores';

interface IProps {
  reportData: Partial<IReportData>
}

export default function NetSalesSummary (props: IProps) {
  const { i18n } = globalState;
  const { reportData } = props;
  const { revenueSummaryDTO = {}, cashSummaryDTO = {}, tipSummaryDTO = {}, refundSummaryDTO = {} } = reportData;

  const dataList = [
    {
      mainItem: {
        title: i18n.t('pc_sales_summary_net_sales'), value: revenueSummaryDTO.netSales,
        tip: (
          <>
            <div>{ i18n.t('pc_sales_summary_net_sales_tooltip_1') }</div>
            <div> · { i18n.t('pc_sales_summary_net_sales_tooltip_2') }</div>
          </>
        )
      },
      subItems: [
        revenueSummaryDTO.totalSales !== null && { title: 'Total Sales', value: revenueSummaryDTO.totalSales },
        { title: i18n.t('pc_sales_summary_gross_sales'), value: revenueSummaryDTO.grossSales },
        (Number(revenueSummaryDTO.cashDiscount) !== 0) && { title: i18n.t('pc_sales_summary_cash_discount'), value: revenueSummaryDTO.cashDiscount },
        { title: i18n.t('pc_employee_report_tabs_discount'), value: revenueSummaryDTO.discounts },
        { title: i18n.t('pc_sales_summary_sales_discounts'), value: revenueSummaryDTO.salesComps },
        { title: i18n.t('pc_sales_summary_sales_refunds'), value: revenueSummaryDTO.refunds },
      ].filter(x => x)
    },
    {
      mainItem: {
        title: i18n.t('pc_sales_summary_cash_summary'),
        value: cashSummaryDTO.cashAfterRefund,
      },
      subItems: [
        { title: i18n.t('pc_sales_summary_total_cash_payments'), value: cashSummaryDTO.cashPayment },
        { title: i18n.t('pc_sales_summary_cash_refunds'), value: cashSummaryDTO.cashRefunds },
        { title: i18n.t('pc_sales_summary_declared_cash_tips'), value: cashSummaryDTO.cashTips },
      ]
    },
    {
      mainItem: {
        title: i18n.t('pc_sales_summary_tip_summary'),
        value: tipSummaryDTO.tipsAfterRefund,
        tip: (
          <div>{i18n.t('Dashboard_reports_sales_summary_tip_summary_information')}</div>
        )
      },
      subItems: [
        { title: i18n.t('pc_sales_summary_declared_cash_tips'), value: tipSummaryDTO.cashTips },
        { title: i18n.t('pc_sales_summary_card_tips_collected'), value: tipSummaryDTO.cardTips },
        { title: i18n.t('pc_sales_summary_card_tips_refunded'), value: tipSummaryDTO.cardTipRefunds },
      ]
    },
    {
      mainItem: {
        title: 'Refund Summary',
        value: refundSummaryDTO.refundTotalAmount,
      },
      subItems: [
        { title: i18n.t('pc_sales_summary_sales_refunds'), value: refundSummaryDTO.salesRefund },
        { title: 'Tax Refunds', value: refundSummaryDTO.taxRefundAmount },
        { title: 'Tip Refunds', value: refundSummaryDTO.refundTipsAmount },
        { title: 'Gratuity Refund', value: refundSummaryDTO.gratuityRefunds },
        { title: 'Gift Card Refund', value: refundSummaryDTO.giftCardRefund },
      ]
    }
  ]
  const itemRender = ({ mainItem, subItems }, key) => {
    return (
      <div className="net-sales-summary-item-wrap" key={ key }>
        <div className="net-sales-summary-title-wrap">
          <div className="net-sales-summary-main-title">{ mainItem.title }</div>
          {
            mainItem.tip && <Tooltip placement="top" title={ <div>{ mainItem.tip }</div> }>
              <InfoCircleOutlined className="net-sales-summary-tip-icon"/>
            </Tooltip>
          }
        </div>
        <div className="net-sales-summary-main-amount">
          <Price value={ mainItem.value }/>
        </div>
        <div className="sub-items-wrap">
          {
            subItems.map(x => (
              <div className="sub-item" key={ x.title }>
                <div className="sub-title">{ x.title }</div>
                <div className="sub-amount"><Price value={ x.value }/></div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
  return (
    <div className="net-sales-summary">
      { dataList.map((x, i) => itemRender(x, i)) }
    </div>
  )
}