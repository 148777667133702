import './index.scss'
import { globalState } from '@/stores';
import { Button, Modal, Form, Switch, InputNumber, message } from 'antd';
import { EditOutlined, ReloadOutlined, SettingOutlined, CheckOutlined } from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { isEmpty } from '@/utils';
import { getPrintSetting, savePrintSetting, listPrinter, archivePrinter, unarchivePrinter } from '@/request/PrintersAndCashDrawers';
import Table, { ColumnsType } from 'antd/es/table';
import { ActiveIcon, ActivePointIcon, ArchivedPointIcon } from '../DiningOptions/component/IconComponent';
import { OperationType } from './enum'
import PrintDrawer from './components';
import { TabType } from '../Printer/enum';
interface ISettingDetail {
    archived?: boolean
    autoRetry?: boolean
    displayOrder?: any
    id?: string
    retryTime?: number
    uuid?: string
}
interface IProps{
    activeKey: TabType
}
export default function PrintersAndCashDrawers (props: IProps) {
  const i18n = globalState.i18n;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [settingDetail, setSettingDetail] = useState<ISettingDetail>({});
  const [tableData, setTableData] = useState([])
  const [params, setParams] = useState(
    {
      req: {}
    }
  );
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [settingForm] = Form.useForm();
  const DEFAULT_FILTER_TYPE = {
    'STATUS': ['1'],
  }
  const STATUS_FILTER = [{ text: i18n.t('active'), value: '1' }, { text: i18n.t('archived'), value: '0' }]
  const [statusFilter] = useState(STATUS_FILTER);
  const [filteredValue, setFilteredValue] = useState([]);
  const [printerId, setPrinterId] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<OperationType>(OperationType.EDIT); // 0代表编辑 1代表新增

  const addNew = () => {
    setOpen(prevState => !prevState);
    setPrinterId(null);
    setIsEdit(OperationType.ADD);
  }
  const layout = {
    labelCol: { span: 6 },
  };
  const columns: any = [
    {
      title: i18n?.t('name'),
      align: 'center',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i18n?.t('print_gearOpening_bill_pc_prepStation'),
      align: 'center',
      dataIndex: 'prepStations',
      key: 'prepStations',
      render: (_, row) => {
        return row?.prepStations?.map(x => x.name)?.join(',') || ''
      }
    },
    {
      title: i18n?.t('status'),
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      defaultFilteredValue: DEFAULT_FILTER_TYPE.STATUS,
      filteredValue: filteredValue,
      filters: statusFilter,
      render: (_, row) => {
        return (
          <div>
            { row?.archived.value === '1' ? <span><ActivePointIcon/> { i18n?.t('active') }</span> : <span><ArchivedPointIcon/> { i18n?.t('archived') }</span> }
          </div>
        )
      }
    },
    {
      title: i18n?.t('print_gearOpening_bill_pc_cashDrawer'),
      align: 'center',
      dataIndex: 'cashDrawer',
      key: 'cashDrawer',
      render: (_, row) => {
        return (
          <div style={ { 'textAlign': 'center' } }>{ row?.cashDrawer?.value === '2' && <CheckOutlined style={ { color: '#2563eb' } }/> }</div>
        )
      }
    },
    {
      title: i18n?.t('table_actions'),
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        return (
          <div className="btn-group">
            <Button type="link" icon={ <EditOutlined/> } onClick={ () => {
              setOpen(prevState => !prevState);
              setIsEdit(OperationType.EDIT);
              setPrinterId(row.id);
            } }>{ i18n?.t('edit_1') }</Button>
            {
              row.archived.value === '1' && <Button type="link" icon={ <ActiveIcon/> } style={ { 'display': 'flex', 'alignItems': 'center' } }
                onClick={ async () => {
                  const { code } = await archivePrinter({ id: row.id });
                  if (code === 0) {
                    message.success(i18n?.t('message_success'));
                    fetchData();
                  }
                } }>
                { i18n?.t('archive') }
              </Button>
            }
            {
              row.archived.value === '0' && <Button type="link" icon={ <ReloadOutlined/> }
                onClick={ async () => {
                  const { code } = await unarchivePrinter({ id: row.id });
                  if (code === 0) {
                    message.success(i18n?.t('message_success'));
                    fetchData();
                  }
                } }>
                { i18n?.t('unarchive') }
              </Button>
            }
          </div>
        )
      }
    }
  ];
  const handleOk = async () => {
    const info = await settingForm.validateFields();
    const params = {
      id: settingDetail.id,
      ...info
    };
    const { code } = await savePrintSetting(params);
    if (code === 0) {
      settingForm.resetFields();
      setIsModalOpen(false);
    }
  }
  const handleCancel = () => {
    settingForm.resetFields();
    setIsModalOpen(false);
  }
  const openSetting = async () => {
    setIsModalOpen(true);
    try {
      const { code, data } = await getPrintSetting();
      if (code === 0) {
        setSettingDetail(data);
        settingForm.setFieldsValue(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchData = async () => {
    setTableLoading(true);
    const { data } = await listPrinter(params);
    console.log('filteredValue', filteredValue);
    if (filteredValue.length) {
      const list = data.filter(fil => filteredValue?.includes(fil?.archived?.value));
      console.log('list', list);
      setTableData(list);
    } else {
      setTableData(data);
    }
    setTableLoading(false);
  }

  const handleTableChange = (_, filters, sorter) => {
    setFilteredValue(filters.status);
    const status = filters.status;
    const filterData = async () => {
      setTableLoading(true);
      const { data } = await listPrinter(params);
      const list = data.filter(fil => status?.includes(fil?.archived?.value));
      setTableData(isEmpty(status) ? data : list);
      setTableLoading(false);
    }
    filterData();
  }


  useEffect(() => {
    if (props.activeKey === TabType.PRINTERSANDCASHDRAWERS) {
      fetchData();
    }
  }, [props.activeKey])
  return (
    <div className="printers-and-cash-drawers">
      <div className="printers-and-cash-drawers-head">
        <div className="printers-and-cash-drawers-head-title">
          { i18n?.t('print_gearOpening_bill_pc_printersAndCashDrawers') }
        </div>
        <div className="printers-and-cash-drawers-head-setting">
          <Button type="link" icon={ <SettingOutlined/> } onClick={ openSetting }>{ i18n?.t('settings') }</Button>
          <Button type="primary" icon={ <PlusCircleOutlined/> } onClick={ addNew }>{ i18n?.t('add_new_1') }</Button>
        </div>
      </div>
      <div className="printers-and-cash-drawers-body">
        <Table
          columns={ columns }
          dataSource={ tableData }
          loading={ tableLoading }
          rowKey={'uuid'}
          pagination={
            {
              showQuickJumper: true,
              showTotal: () => i18n?.t('table_total_items', { num: tableData.length })
            }
          }
          onChange={ handleTableChange }/>
      </div>
      <Modal title={ i18n?.t('frontOfHouse_pc_setting') } open={ isModalOpen } onOk={ handleOk } onCancel={ handleCancel } okText={ i18n?.t('save') } cancelText={ i18n?.t('cancel') }>
        <Form
          { ...layout }
          form={ settingForm }
          validateTrigger="onSubmit"
          labelAlign="left"
        >
          <Form.Item
            label={
              <>
                <div>{ i18n?.t?.('automatic_retry') }</div>
              </>
            }
            name="autoRetry"
          >
            { <Switch onChange={ () => {
              setSettingDetail({ ...settingDetail, autoRetry: !settingDetail?.autoRetry })
            } }/> }
          </Form.Item>
          {
            settingDetail?.autoRetry && <Form.Item
              label={
                <>
                  <div>{ i18n?.t('retry_internal') }</div>
                </>
              }
              name="retryTime"

            >
              <InputNumber min={ 0 } max={ 600 } defaultValue={ 600 } step={ 1 } style={ { width: '60%' } }/>
            </Form.Item>
          }
        </Form>
      </Modal>

      <PrintDrawer
        open={ open }
        setOpen={ setOpen }
        reloadTableData={ () => {
          setOpen(prevState => !prevState);
          fetchData();
        } }
        type={ isEdit }
        printId={ printerId }
      />

    </div>
  )
}