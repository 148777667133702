import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ApiSearchGuest, ApiUpdateGuest } from '@/request/api';
import { Spin, Form, Input, Typography, Flex, Button, FormItemProps, InputProps } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react'
import { IGuestInfo } from './interface';
import './index.scss'
import { isValidEmail } from '@/utils/validate';
import { globalState } from '@/stores';

const { Text } = Typography;
const { Item } = Form
interface IProps {
  guestId?: string;
}

// 表单字段配置类型
interface FormFieldConfig {
  itemProps: FormItemProps;
  inputProps: InputProps;
}

const GuestInfoForm: React.FC<IProps> = ({ guestId }) => {
  const { i18n } = globalState
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const initialDataRef = useRef<IGuestInfo | null>(null);
  const [form] = Form.useForm();

  // 表单字段配置数组
  const formFields: FormFieldConfig[] = useMemo( () => {
    return [
      {
        itemProps: {
          label: i18n.t('myAccount_pc_frist_name'),
          name: 'firstName',
          rules: [{ required: true, message: i18n.t('required') }]
        },
        inputProps: {
          maxLength: 50
        }
      },
      {
        itemProps: {
          label: i18n.t('myAccount_pc_last_name'),
          name: 'lastName',
        },
        inputProps: {
          maxLength: 50
        }
      },
      {
        itemProps: {
          label: i18n.t('Dashboard_PrintConfig_PhoneNumber'),
          name: 'phoneNumber'
        },
        inputProps: {
          disabled: true
        }
      },
      {
        itemProps: {
          label: i18n.t('PC_GUEST_EMAIL'),
          name: 'emailList',
          rules:[{
            validator: (_, emailList) => {
              // 检查是否为数组，并取第一个元素作为有效值进行验证
              const value = Array.isArray(emailList) ? emailList[0] : emailList;
              // 如果值为空则验证通过
              if (!value) {
                return Promise.resolve();
              }
              // 验证邮箱格式是否有效
              if (isValidEmail(value)) {
                return Promise.resolve();
              }
              // 如果格式无效，则拒绝
              return Promise.reject(new Error());
            },
          },]
        },
        inputProps: {
          // 用户如果有邮箱则不允许编辑
          maxLength: 255, // 限制最长输入255个字符
          disabled: initialDataRef?.current?.emailList?.length > 0,
        }
      },
      {
        itemProps: {
          label: i18n.t('Dashboard_PrintConfig_GuestNotes'),
          name: 'guestNote'
        },
        inputProps: {
          maxLength: 100
        }
      }
    ]
  }, [initialDataRef.current])

  const fetchGuestInfo = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await ApiSearchGuest({ searchType: 4, searchKey: id });
      if (res?.code === 0 && res?.data?.[0]) {
        initialDataRef.current = res.data[0];
        form.setFieldsValue(res.data[0]);
      }
    } catch (error) {
      Sentry.captureEvent({
        message: 'Failed to fetch guestInfo',
        level: 'info',
        extra: {
          additionalInfo: error,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    guestId && fetchGuestInfo(guestId)
  }, [guestId]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { phoneNumber, emailList, ...restProps } = await form.getFieldsValue();
      // 用户无邮箱
      if (emailList?.length > 0 && initialDataRef?.current?.emailList?.length === 0) {
        restProps.email = emailList
      }
      const res = await ApiUpdateGuest({
        guestId,
        ...restProps
      });

      if (res?.code === 0) {
        initialDataRef.current = res.data;
        form.setFieldsValue(res.data);
        setIsEdit(false);
      }
    } catch (error) {
      Sentry.captureEvent({
        message: 'Failed to fetch updateGuest',
        level: 'info',
        extra: {
          additionalInfo: error,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    form.setFieldsValue(initialDataRef.current);
    setIsEdit(false);
  }
  const DisplayText = ({ value = '' }) => {
    const displayValue = Array.isArray(value) ? value.join(', ') : value;

    return <Text className='body-base' style={{ textAlign: 'right' }}>{displayValue}</Text>;
  }
  const renderFormItem = ({ itemProps, inputProps }: FormFieldConfig) => {
    return (
      <Flex justify='space-between' align='flex-start' gap="small" style={{ marginBottom: '8px' }} key={itemProps?.name}>
        <Text className='heading5' style={{ whiteSpace:'nowrap' }}>
          {itemProps?.label}
        </Text>
        <Item
          noStyle
          {...itemProps}
        >
          {isEdit ? (
            <Input {...inputProps} style={{ maxWidth: 435 }} />
          ) : (
            <DisplayText />
          )}
        </Item>
      </Flex>

    );
  };

  return (
    <div style={{ padding: 16 }}>
      <Spin spinning={isLoading}>
        <Form form={form} layout="horizontal" onFinish={handleSubmit}>
          <Flex justify="space-between" align="center" style={{ marginBottom: 24 }}>
            <Text className='heading4'>{i18n.t('PC_GUEST_INFORMATION')}</Text>
            {isEdit ? (
              <Flex gap={8}>
                <Button type="link" onClick={handleCancel}>{i18n.t('cancel')}</Button>
                <Button type="primary" htmlType='submit'>{i18n.t('save')}</Button>
              </Flex>
            ) : (
              <EditOutlined
                onClick={() => setIsEdit(true)}
                style={{ fontSize: 16, cursor: 'pointer' }}
              />
            )}
          </Flex>
          {formFields.map(renderFormItem)}
        </Form>
      </Spin>
    </div>
  );
};

export default GuestInfoForm;
