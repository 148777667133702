import React, { useImperativeHandle, forwardRef, useEffect } from 'react'

import { Card, Form, Input,  } from 'antd'
import { globalState } from '@/stores'
import { isStrictlyEmptyString } from '@/utils'
import ColorFilter from '../component/ColorFilter';
import './GeneralInfo.scss'

interface IProps {
  title?: string,
  colorFilter?: boolean
  editInfo: {
    name: string
    foreignName: string
    shortName: string
    color: string
  }

}

const GeneralInfo = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState
  const { title, editInfo } = props
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => {
    return {
      form
    };
  }, []);

  useEffect(() => {
    if (editInfo) {
      const { name, foreignName, shortName, color } = editInfo
      form.setFieldsValue({
        name,
        foreignName,
        shortName,
        color
      });
    }
  }, [editInfo])

  return (
    <div className='menus-general-info'>
      <Card title={title ?? i18n.t('general_info')} >
        <Form
          form={form}
          name="general_info_form"
          layout="vertical"
        >
          <Form.Item
            name="name"
            style={{ marginBottom: 15 }}
            label={
              <div>
                <span>{i18n.t('amount_pc_name')}</span>
                <span style={{ 'color': '#ff4d4f' }}> * </span>
              </div>
            }
            rules={[
              { validator: async (_, value) => {
                if (isStrictlyEmptyString(value)) {
                  return Promise.reject(i18n?.t('required_x', { msg: i18n?.t('createMenuGroup_pc_menu_group') }));
                }
                if (value?.length > 100) {
                  return Promise.reject(i18n?.t('frontOfHouse_pc_maximum', { length: 100 }))
                }
                return Promise.resolve()
              } },
            ]}>
            <Input onBlur={() => { form.validateFields(['name']) }} maxLength={100} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 15 }}
            name="foreignName"
            label={i18n.t('dashboard_menu_kitchen_display_name')}
            rules={[
              { validator: async (_, value) => {
                if (value?.length > 100) {
                  return Promise.reject(i18n?.t('frontOfHouse_pc_maximum', { length: 100 }))
                }
                return Promise.resolve()
              } },
            ]}
          >
            <Input onBlur={() => { form.validateFields(['foreignName']) }} maxLength={100} />
          </Form.Item>
          <Form.Item
            rules={[
              { validator: async (_, value) => {
                if (value?.length > 100) {
                  return Promise.reject(i18n?.t('frontOfHouse_pc_maximum', { length: 100 }))
                }
                return Promise.resolve()
              } },
            ]}
            name="shortName"
            label={i18n.t('dashboard_menu_pos_display_name')}>
            <Input onBlur={() => { form.validateFields(['shortName']) }} maxLength={100} />
          </Form.Item>
          {
            props.colorFilter && <Form.Item name='color' label={i18n?.t('menus_pos_button_color')} className='menus-general-info-color-filter'>
              <Input style={ { display: 'none' } }/>
            </Form.Item>
          }
        </Form>
        {
          props.colorFilter && <ColorFilter colorText={editInfo.color} handleChangeColorItem={(colorText: string) => {
            form.setFieldValue('color', colorText)
          }}/>
        }
      </Card>
    </div>
  )
})

export default GeneralInfo;