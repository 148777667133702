import React, { useEffect, useState } from 'react';
import { Table, Typography, Statistic, Divider, Card, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './UserTotal.scss';
const { Title } = Typography;
import { globalState } from '@/stores';
import { formatCurrency } from '@/utils'
import { EmployeeLaborGroupDTO, EmployeeLaborDetailDTO } from '../type'
import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

interface IUserTotalProps {
  restaurantInfo: EmployeeLaborGroupDTO;
  tableColumns: any;
}

const RestaurantTable: React.FC<IUserTotalProps> = (props) => {
  const { restaurantInfo, tableColumns = [] } = props;
  const { i18n } = globalState;
  const [showColumns, setShowColumns] = useState([]);
  const [topInfo, setTopInfo] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const columns = [
    {
      title: i18n?.t('dashboard_report_labor_summary_role'),
      dataIndex: 'roleName',
      headerId: 0,
      render: (_, record) =>  record.roleName || '-'
    },
    {
      title: i18n.t('dashboard_Regular_Hours'),
      dataIndex: 'regularHours',
      headerId: 1,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n.t('dashboard_overtime_hours'),
      dataIndex: 'overTimeHours',
      headerId: 2,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n?.t('laborSummary_totalLaborHours'),
      dataIndex: 'laborHours',
      headerId: 3,
      render: (val) => !!val ? `${parseFloat(val).toFixed(2)} hrs` : 'N/A',
    },
    {
      title: i18n?.t('laborSummary_estimatedTotalPay'),
      dataIndex: 'estimatedTotalPay',
      headerId: 6,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('dashboard_tippable_net_sales'),
      dataIndex: 'netSalesTippable',
      headerId: 7,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('laborSummary_cardTips'),
      dataIndex: 'cardTips',
      headerId: 8,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_declared_tips'),
      dataIndex: 'declareTips',
      headerId: 9,
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
    },
  ];
  const defaultTopInfo = [
    {
      title: i18n.t('laborSummary_totalLaborHours'),
      value: restaurantInfo.totalLaborHours || '-',
      headerId: 3,
      suffix: 'hrs'
    },
    {
      title: i18n.t('laborSummary_estimatedTotalPay'),
      value: restaurantInfo.totalEstimatedTotalPay || '-',
      headerId: 6,
      prefix: '$'
    },
    {
      title: <div>
        <span style={{ paddingRight: '5px' }}>{i18n.t('dashboard_tippable_net_sales')}</span>
        <Tooltip title={i18n.t('Dashboard_labor_summary_tippable_sales_info') || 'This displays the net sales of all tippable sale categories. If not configured, it reflects total net sales.'}>
          <InfoCircleOutlined />
        </Tooltip>
      </div>,
      value: restaurantInfo.employeeSalesTippable || '-',
      headerId: 7,
      prefix: '$'
    },
    {
      title: i18n.t('laborSummary_cardTips'),
      value: restaurantInfo.cardTips || '-',
      headerId: 8,
      prefix: '$'
    },
    {
      title: i18n.t('Dashboard_report_labor_summary_declared_tips'),
      value: restaurantInfo.declareTips || '-',
      headerId: 9,
      prefix: '$'
    },
  ]
  useEffect(() => {
    if (!Array.isArray(tableColumns)) return;
    const newColumns = columns.filter(item =>
      tableColumns.some(col => col.headerId === item.headerId)
    );
    const newTopInfo = defaultTopInfo.filter(item =>
      tableColumns.some(col => col.headerId === item.headerId)
    );
    setTopInfo(newTopInfo);
    setShowColumns(newColumns);
  }, [tableColumns]);
  useEffect(() => {
    const newDataSource = [...restaurantInfo.detailDTOList, {
      roleName: i18n.t('pc_transaction_total'),
      regularHours: restaurantInfo.totalRegularHours,
      overTimeHours: restaurantInfo.totalOverTimeHours,
      laborHours: restaurantInfo.totalLaborHours,
      estimatedTotalPay: restaurantInfo.totalEstimatedTotalPay,
      netSalesTippable: restaurantInfo.employeeSalesTippable,
      cardTips: restaurantInfo.cardTips,
      declareTips: restaurantInfo.declareTips,
    }];
    setDataSource(newDataSource);
  }, [restaurantInfo])
  return (
    <div className='user-info-total'>
      <Card key={restaurantInfo.employeeId} className='user-labor'>
        <div className='user-labor-top'>
          <div className='title'>
            <Title level={3} style={{ margin: 0 }}>
              {restaurantInfo.firstName} {restaurantInfo.lastName}
            </Title>
          </div>
          <div className='top-info'>
            {topInfo.map(item => (
              <div className='item' key={item.dataIndex}>
                <Divider type='vertical' />
                <Statistic
                  title={item.title}
                  value={item.value || '-'}
                  precision={2}
                  suffix={item.value ? item.suffix : ''}
                  prefix={item.value ? item.prefix : ''}
                />
              </div>
            ))}
          </div>
        </div>
        <Table
          columns={showColumns}
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
          pagination={false}
          rowClassName={ (_, index) => classNames('row', { 'sticky-row': index === dataSource.length - 1 }) }
          key={restaurantInfo.employeeId}
          // summary={() => (
          //   <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
          //     {showColumns.map((item, index) => {
          //       if (item.totalKey === 'roleName') {
          //         return (
          //           <Table.Summary.Cell key={item.totalKey + index} index={index}><b>{i18n.t('pc_transaction_total')}</b></Table.Summary.Cell>
          //         )
          //       } else {
          //         return <Table.Summary.Cell key={item.totalKey + index} index={index}><b>{restaurantInfo?.[item.totalKey] ? `${restaurantInfo?.[item.totalKey] } hrs` : '-'}</b></Table.Summary.Cell>
          //       }
          //     })}
          //     {/* <Table.Summary.Cell index={0}><b>{i18n.t('pc_transaction_total')}</b></Table.Summary.Cell>
          //     <Table.Summary.Cell index={1}><b>{restaurantInfo.totalRegularHours ? `${restaurantInfo.totalRegularHours } hrs` : '-'}</b></Table.Summary.Cell>
          //     <Table.Summary.Cell index={2}><b>{restaurantInfo.totalOverTimeHours ? `${restaurantInfo.totalOverTimeHours } hrs` : '-'}</b></Table.Summary.Cell>
          //     <Table.Summary.Cell index={3}><b>{restaurantInfo.totalLaborHours ? `${restaurantInfo.totalLaborHours } hrs` : '-'}</b></Table.Summary.Cell>
          //     <Table.Summary.Cell index={6}><b>{restaurantInfo.totalEstimatedTotalPay ? formatCurrency(restaurantInfo.totalEstimatedTotalPay) : '-'}</b></Table.Summary.Cell>
          //     <Table.Summary.Cell index={7}><b>{restaurantInfo.employeeSalesTippable ? formatCurrency(restaurantInfo.employeeSalesTippable) : '-'}</b></Table.Summary.Cell>
          //     <Table.Summary.Cell index={8}><b>{restaurantInfo.cardTips ? formatCurrency(restaurantInfo.cardTips) : '-'}</b></Table.Summary.Cell>
          //     <Table.Summary.Cell index={9}><b>{restaurantInfo.declareTips ? formatCurrency(restaurantInfo.declareTips) : '-'}</b></Table.Summary.Cell> */}
          //   </Table.Summary.Row>
          // )}
        />
      </Card>
    </div>
  );
}

export default RestaurantTable;