import { useState, useCallback, useRef, useEffect } from 'react';
import { Method } from 'axios';
import { RESPONSE_CODE } from '@/constants/http';
import { service } from '@/request'
import type { Pagination, TableSorter, TableResponse } from '@/types/table';
interface Sorter {
  field: string | null;
  order: 'ASC' | 'DESC' | null;
}
interface Response<T> {
  data: { list: T[]; total: number };
  code: number;
}

const ORDER_MAP: Record<string, 'DESC' | 'ASC'> = {
  'descend': 'DESC',
  'ascend': 'ASC',
}
interface UseTableOptions<T> {
  defaultPageSize?: number;
  defaultFilters?: Record<string, any>;
  defaultSorter?: Sorter;
  fetchMethod?: Method;  // 添加请求方法选项
  requestData?: Record<string, any>;  // 添加额外请求数据
  onError?: (error: Error) => void;
  transform?: (data: { list: T[]; total: number; }) => any[];
}

/**
 * 表格
 * @param apiUrl 请求地址
 * @param options 选项
 */
export const useTable = <T>(
  apiUrl: string,
  options: UseTableOptions<T> = {}
) => {
  const {
    defaultPageSize = 10,
    defaultFilters = {},
    defaultSorter = { field: null, order: null },
    onError,
    transform,
    fetchMethod = 'POST',
    requestData = {},
  } = options;

  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
  });
  const [filters, setFilters] = useState<Record<string, any>>(defaultFilters);
  const [sorter, setSorter] = useState<Sorter>(defaultSorter);

  // 使用 useRef 存储最新的请求标识
  const requestIdRef = useRef(0);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const currentRequestId = ++requestIdRef.current;
    try {
      const params = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        ...filters,
        orderBy: sorter.field ? {
          field: sorter.field,
          order: sorter.order,
        } : null
      };

      const response: TableResponse<T> = await service({
        method:fetchMethod,
        url: apiUrl,
        [fetchMethod.toLowerCase() === 'get' ? 'params' : 'data']: {
          ...params,
          ...requestData,
        },
      });

      // 确保只处理最新的请求响应
      if (currentRequestId !== requestIdRef.current) return;

      const { data, code, } = response;

      if (code === RESPONSE_CODE.SUCCESS_CODE && data) {
        const transformedData = transform ? transform(data) : data.list;
        setData(transformedData);
        setPagination(prev => ({ ...prev, total: data.total }));
      }
    } catch (err) {
      onError?.(err);
    } finally {
      if (currentRequestId === requestIdRef.current) {
        setLoading(false);
      }
    }
  }, [apiUrl, pagination.current, pagination.pageSize, filters, sorter, transform, onError]);

  // 重置表格
  const reset = useCallback(() => {
    setPagination(prev => ({ ...prev, current: 1 }));
    setFilters(defaultFilters);
    setSorter(defaultSorter);
  }, [defaultFilters, defaultSorter]);

  // 刷新表格
  const refresh = useCallback(() => {
    fetchData();
  }, [fetchData]);

  // 处理表格变化
  const handleTableChange = useCallback(
    (
      newPagination: Pagination,
      _filters: Record<string, any>,
      newSorter: TableSorter
    ) => {
      setPagination(newPagination);
      newSorter.field && setSorter({
        field: newSorter.field,
        order: ORDER_MAP[newSorter.order],
      });
    },
    []
  );

  // 更新筛选条件
  const setParams = useCallback((newFilters: Record<string, any>) => {
    setPagination(prev => ({ ...prev, current: 1 }));
    setFilters(newFilters);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    pagination,
    params: filters,
    sorter,
    setParams,
    handleTableChange,
    refresh,
    reset,
  };
};
