import { toJS } from 'mobx';
import printOutDecorationStore from '../store';
import { ConfigTypeValue, IPreviewSectionAttributes } from '../interface';
import { configDataOptions } from '../mapData/typeMapConfigDataOptions';
import { fnSettingConfigArrayAreEqual } from './fnSettingConfigArrayAreEqual';

// case1: old 有，new 没有（删掉children Key, sort 对应 key）
// case2: old 有，new 有（不关心）
// case2: old 没有，new 有（老商家取自身当前的配置oldPreviewData，新上架取newPreviewData）
// case3: old 没有，new 没有（不关心）

// 如果config添加了新字段的处理逻辑函数
export const handleDataAndFieldChanges = (
  configType: ConfigTypeValue,
  source: string = 'fetchApi'
) => {

  const oldPreviewData: Array<IPreviewSectionAttributes> = toJS(
    printOutDecorationStore.getPreviewData
  );

  const currentTemplateData: Array<IPreviewSectionAttributes> = toJS(
    printOutDecorationStore.defaultConfigTemplateData
  );

  const oldSectionIds = new Set(oldPreviewData.map((section) => section.id));

  const defaultConfigDataOption = configDataOptions[configType];

  // 特殊逻辑统一处理逻辑，Radio的情况下需要默认勾上的section
  // 或者新增的key，默认强制变为{hide: false}
  const specialChildItemRadioDefaultKeyArr = [
    'service-detail-section-diningOption',
    'item-info-section-groupByItem',
    'item-info-section-itemDish',
    'item-info-section-itemDishModifier'
  ];

  // 更新 oldData 中的对象
  const updateConfigData = oldPreviewData.map((section) => {
    const newSection = currentTemplateData.find(
      (newSec) => newSec.id === section.id
    );

    if (newSection) {
      // 将老的sort，全部赋值成新的sort
      section.sort = newSection.sort;

      const newChildren = newSection.children;
      const oldChildren = section.children;
      const updatedChildren = { ...oldChildren };
      // 判断如果新数据的section删除了旧数据section的选项，更新旧数据
      for (const key in oldChildren) {
        if (oldChildren[key].hide === true) {
          // 删除 section.sort 中对应的 key
          for (let i = 0; i < section.sort.length; i++) {
            const sortIndex = section.sort[i].indexOf(key);
            if (sortIndex > -1) {
              section.sort[i].splice(sortIndex, 1);
              // 如果内层数组为空，则删除该数组
              if (section.sort[i].length === 0) {
                section.sort.splice(i, 1);
                i--; // 调整索引以继续检查下一个元素
              }
            }
          }
        }

        if (!newChildren[key]) {
          // 删除 children 中对应的key
          delete updatedChildren[key];

          // 删除 section.sort 中对应的 key
          for (let i = 0; i < section.sort.length; i++) {
            const sortIndex = section.sort[i].indexOf(key);
            if (sortIndex > -1) {
              section.sort[i].splice(sortIndex, 1);
              // 如果内层数组为空，则删除该数组
              if (section.sort[i].length === 0) {
                section.sort.splice(i, 1);
                i--; // 调整索引以继续检查下一个元素
              }
            }
          }
        }
      }
      for (const key in newChildren) {
        if (!oldChildren[key]) {
          updatedChildren[key] = {
            ...newChildren[key],
            hide: specialChildItemRadioDefaultKeyArr.includes(key)
              ? false
              : true,
          };
        }
      }

      return {
        ...section,
        // TODO: modifier 后面也需要对新老数据的兼容 & 新老的section的modifier的新老数据的兼容
        modifier: section.modifier ? section.modifier : newSection?.modifier,
        children: updatedChildren,
      };
    } else {
      // 如果 oldData 中的 section 在 newData 中不存在，则设置 hide 为 true
      section.hide = true;
      for (const key in section.children) {
        if (section.children.hasOwnProperty(key)) {
          section.children[key].hide = true;
        }
      }
      return section;
    }
  });


  // 添加 newData 中有但 oldData 中没有的部分
  currentTemplateData.forEach((newSection) => {
    if (!oldSectionIds.has(newSection.id)) {
      const updatedChildren = {};
      // 处理逻辑：若新newData中，而oldData没有。
      // case1: newData需要必须选择✅，则通过defaultConfigData 的 disabled去控制
      // case2: newData存在，在config没有配置项，但在preview必须显示的话，则使用attr中的isHideConfig去控制
      const defaultConfigSection = defaultConfigDataOption.find(
        (item) => item.id === newSection.id
      );
      const sectionHide =
        defaultConfigSection?.disabled === true ||
        defaultConfigSection?.modifier?.isHideConfig
          ? false
          : true;

      for (const key in newSection.children) {
        // todo: S19 先上线，后续统一处理逻辑
        updatedChildren[key] = {
          ...newSection.children[key],
          hide: !specialChildItemRadioDefaultKeyArr.includes(key),
        };
      }

      const sectionIndex = currentTemplateData.findIndex(
        (fin) => fin.id === newSection.id
      );
      updateConfigData.splice(sectionIndex, 0, {
        ...newSection,
        hide: sectionHide,
        children: updatedChildren,
      });
    }
  });


  const settingConfigData = toJS(printOutDecorationStore.settingConfigData);
  const isSettingConfigEqual = fnSettingConfigArrayAreEqual(settingConfigData, defaultConfigDataOption);
  /**
   * isSettingConfigEqual === true 有值，说明是采用了排序功能。那么就需要按照自己本身的顺序即可
   * */
  if (isSettingConfigEqual) {

    /**
     * 预览部分的排列顺序需要按照模版的来，只是section级别的排序
     * */
    const sortMap = new Map(
      currentTemplateData.map(
        (item: IPreviewSectionAttributes, index: number) => [item.id, index]
      )
    );
    updateConfigData.sort((a, b) => {
      const sortA = sortMap.get(a.id);
      const sortB = sortMap.get(b.id);
      return sortA - sortB;
    });
  }
  updateConfigData.forEach((item) => {
    if (item.id === 'item-info-section') {
      item.children['item-info-section-checkNotes'].hide = false;
    }
  });
  printOutDecorationStore.savePreviewData(updateConfigData, source);
};
