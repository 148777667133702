import {
  Button,
  Form,
  Input,
  Space,
  Drawer,
  Radio,
  Spin,
  message,
  InputNumber,
} from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { isEmpty } from '@/utils';
import { checkCashDrawerName, saveAddCashDrawer, saveCashDrawer } from '@/request/PrintersAndCashDrawers';
import './index.scss';
import { OperationType, startBalanceConfigOpts, StartBalanceConfigTypeEnum } from '../enum';
import { IDrawerPropsData } from '../interface';
interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<any>>;
  reloadTableData: () => void;
  pageType: OperationType, // 0代表编辑 1代表新增
  rowData: IDrawerPropsData;
  orderNumber: number // add的时候顺序
}

export default function CashConfigDrawer (props: IProps) {
  const i18n = globalState.i18n;
  const layout = {
    labelCol: { span: 8 },
  };

  const [cashDrawerInfoForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [startBalanceConfigType, setStartBalanceConfigType] =
    useState<StartBalanceConfigTypeEnum>(StartBalanceConfigTypeEnum.LAST_END_BALANCE);
  // 校验名字
  const [isValidName, setIsValidName] = useState<boolean>(true);

  // 初始化数据
  const initData = async () => {
    setLoading(true);
    const {
      name = '',
      startBalanceConfigType = 1,
      startBalanceConfigAmount = null,
      printName = '',
    } = props.rowData || {};
    cashDrawerInfoForm.setFieldsValue({
      name: props.pageType === OperationType.ADD ?
        `cash drawer #${String(props.orderNumber + 1).padStart(3, '0')}` : name,
      startBalanceConfigType,
      startBalanceConfigAmount,
      printName
    });

    setStartBalanceConfigType(startBalanceConfigType);
    setLoading(false);
  };

  const onClose = () => {
    cashDrawerInfoForm.resetFields();
    props.setOpen((prevState) => !prevState);
  };

  const onSave = async () => {
    const params = await cashDrawerInfoForm.validateFields();
    const { name } = params

    let data;

    if (!isValidName) return;

    switch (props.pageType) {
    case OperationType.ADD:
      data = await saveAddCashDrawer({ ...params, name: name.trim() });
      break;

    case OperationType.EDIT:
      data = await saveCashDrawer({ ...params, name: name.trim(), id: props?.rowData?.id });
      break;

    default:
      break;
    }

    if (data && data.code === 0) {
      cashDrawerInfoForm.resetFields();
      props.reloadTableData();
      message.success(i18n?.t('message_success'));
    }
  };

  const closeDrawer = () => {
    props.setOpen((prevState) => !prevState);
  };

  const handleCheckCashDrawerName = async (name) => {
    const { code, data } = await checkCashDrawerName({ name: name.trim(), id: props?.rowData?.id });
    if (code === 0 ) {
      setIsValidName(data)
      cashDrawerInfoForm.validateFields(['name']);
    }
  };

  useEffect(() => {
    if (props.open) {
      initData();
    }
  }, [props.open]);

  return (
    <Drawer
      title={i18n.t('dashboard_devices_printer_cashdrawer')}
      placement={'right'}
      width={650}
      onClose={closeDrawer}
      open={props.open}
      closeIcon={false}
      style={{ backgroundColor: '#F5F5F5' }}
      size="large"
      extra={
        <Space>
          <Button onClick={onClose}>{i18n.t('cancel')}</Button>
          <Button type="primary" onClick={onSave}>
            {i18n.t('save')}
          </Button>
        </Space>
      }
    >
      <div className="pos-monitor-content-card">
        <Spin spinning={loading}>
          <Form
            {...layout}
            form={cashDrawerInfoForm}
            validateTrigger="onSubmit"
            labelAlign="left"
          >
            <Form.Item
              label={
                <div>
                  <span className="printers-and-cash-drawers-required-symobol">
                    *
                  </span>
                  {i18n?.t('dashboard_devices_printer_cashdrawer_edit_name')}
                </div>
              }
              name="name"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (isEmpty(value)) {
                      return Promise.reject(
                        i18n?.t('please_enter_x', {
                          msg: i18n?.t(
                            'dashboard_devices_printer_cashdrawer_edit_name'
                          ),
                        })
                      );
                    }

                    // 调用接口校验名字的唯一性
                    if (!isValidName) {
                      return Promise.reject(i18n.t('dashboard_cash_drawer_duplicate'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                required
                maxLength={30}
                placeholder={i18n?.t(
                  'dashboard_devices_printer_cashdrawer_edit_name'
                )}
                onBlur={(e) => {
                  handleCheckCashDrawerName(e.target.value)
                }}
              />
            </Form.Item>

            <Form.Item
              label={
                <div>
                  {i18n?.t(
                    'dashboard_devices_printer_cashdrawer_edit_start_balance'
                  )}
                </div>
              }
              name="startBalanceConfigType"
            >
              <Radio.Group
                onBlur={() => {
                  cashDrawerInfoForm.validateFields(['startBalanceConfigType']);
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  setStartBalanceConfigType(value);
                  if (value === 1) {
                    cashDrawerInfoForm.setFieldsValue({
                      startBalanceConfigAmount: null,
                    });
                  }
                }}
              >
                {(startBalanceConfigOpts || []).map((ele) => (
                  <Radio value={ele.value} key={ele.label}>
                    {' '}
                    {ele.label}{' '}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {startBalanceConfigType === 2 && (
              <Form.Item
                name="startBalanceConfigAmount"
                rules={[
                  {
                    required: true,
                    message: i18n?.t('please_enter_x', {
                      msg: 'Starting balance amount',
                    }),
                  }
                ]}
                style={{ marginLeft: '190px' }}
              >
                <InputNumber
                  required
                  onBlur={() => {
                    cashDrawerInfoForm.validateFields([
                      'startBalanceConfigAmount',
                    ]);
                  }}
                  prefix="$"
                  min={0}
                  max={9999999}
                  precision={2}
                  style={{ width: 380 }}
                />
              </Form.Item>
            )}


            {
              props.pageType === OperationType.EDIT && <Form.Item
                label={
                  <div>
                    {i18n.t('dashboard_devices_printer_cashdrawer_edit_printer')}
                  </div>
                }
                name="printName"
              >
                <div>{props?.rowData?.printName || '-'}</div>
              </Form.Item>
            }

          </Form>
        </Spin>
      </div>
    </Drawer>
  );
}
