import { Button, Form, Input, Table, Tag } from 'antd';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TableEmptyContent } from '@/Components/EmptyContent';
import React, { useEffect, useRef, useState } from 'react';
import { toRes } from '@/utils';
import { ApiRoleListQuery } from '@/request/api';
import { ReactComponent as SearchIcon } from '@/assets/svg/search.svg';
import { ReactComponent as UnarchivedIcon } from '@/assets/svg/unarchived.svg';
import { ReactComponent as ArchiveIcon } from '@/assets/svg/archive.svg';
import { globalState } from '@/stores';
import { useListener } from 'react-bus';
import { EDIT_JOBS } from '@/utils/BUS_TYPE';
import createModal from '@/utils/createModal';
import ModalRoleRelateEmployeeList from '@/Pages/EmployeeList/Jobs/ModalRoleRelateEmployeeList';
import ModalRoleArchiveConfirm from '@/Pages/EmployeeList/Jobs/ModalRoleArchiveConfirm';
import { useNavigate } from 'react-router-dom';
import { checkPermission } from '@/utils/permissionUtils';
import './index.scss';

export default function Jobs () {
  const { i18n } = globalState;
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const paramsRef = useRef({ roleName: '' });

  const [tableData, setTableData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({ status: undefined });
  const [pager, setPager] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
  });

  useListener(EDIT_JOBS, () => {
    fetchTableData();
  });

  useEffect(() => {
    fetchTableData();
  }, []);

  const tableColumns = [
    {
      dataIndex: 'role',
      key: 'role',
      title: i18n.t('job_title'),
      width: 100,
      render: (_, row) => row.roleName || '-',
    },
    {
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      title: i18n.t('pc_employees'),
      width: 50,
      render: (_, row) => (
        <div className="colEmployees">
          <Button
            type="link"
            disabled={row.employeeCount === 0}
            onClick={() => handleShowEmployeeList(row)}
          >
            {row.employeeCount}
          </Button>
        </div>
      ),
    },
    {
      dataIndex: 'breaks',
      key: 'breaks',
      title: i18n.t('dashboard_team_breaks'),
      width: 300,
      render: (_, row) =>
        row?.breakList?.length > 0
          ? row.breakList
            .filter((item) => item?.breakId && item?.name)
            .map((item) => (
              <Tag
                bordered={false}
                color="processing"
                key={item.breakId}
                style={{ margin: '3px' }}
              >
                {item.name}
              </Tag>
            ))
          : '-',
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: i18n.t('status'),
      width: 50,
      filteredValue: filteredInfo.status || undefined,
      filterMultiple: false,
      filters: [
        { text: i18n.t('active'), value: 0 },
        { text: i18n.t('archived'), value: 1 },
      ],
      render: (_, row) => {
        const _isActive = !row?.archived;
        return (
          <div className="colStatus">
            <div className={_isActive ? 'greenPoint' : 'greyPoint'}></div>
            {_isActive ? i18n.t('active') : i18n.t('archived')}
          </div>
        );
      },
    },
    checkPermission('EDIT_ROLE')
      ? {
        key: 'actions',
        title: i18n.t('table_actions'),
        width: 50,
        render: (_, row) => {
          const _isArchived = row?.archived;
          return (
            <div className="colActions">
              <Button
                type="link"
                className="btnEdit"
                onClick={() => handleEdit(row)}
              >
                <EditOutlined />
                <span className="text">{i18n.t('edit')}</span>
              </Button>
              {_isArchived ? (
                <Button
                  type="link"
                  onClick={() => handleArchiveRole(row)}
                  disabled={row?.roleTag === 1}
                >
                  <ArchiveIcon />
                  <span className="text">{i18n.t('unarchived')}</span>
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => handleArchiveRole(row)}
                  disabled={row?.roleTag === 1}
                >
                  <UnarchivedIcon />
                  <span className="text">{i18n.t('archive')}</span>
                </Button>
              )}
            </div>
          );
        },
      }
      : null,
  ].filter(Boolean);

  const fetchTableData = async ({
    current,
    pageSize,
    filters,
  }: { current?: number; pageSize?: number; filters?: object } = {}) => {
    const filterObj = Object.entries(filters ?? filteredInfo).reduce(
      (result, [key, value]) => {
        result[key] = value?.[0];
        return result;
      },
      {}
    );
    const params = {
      request: {
        roleName: paramsRef.current.roleName,
        ...filterObj,
      },
      current: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize,
    };
    const [_, data] = await toRes(ApiRoleListQuery(params));
    setTableData(data?.list ?? []);
    setPager((prev) => ({
      ...prev,
      pageSize: pageSize ?? pager.pageSize,
      total: data?.total ?? 0,
      current: data?.current,
    }));
  };

  const handleEdit = (row?) => {
    if (row) {
      navigate({ ...location, hash: `#/jobCreate?id=${ row.roleId }` });
    } else {
      navigate({ ...location, hash: '#/jobCreate' });
    }
  };

  const handleTableChange = (_pagination, filters) => {
    setFilteredInfo(filters);
    fetchTableData({
      current: _pagination.current,
      pageSize: _pagination.pageSize,
      filters,
    });
  };

  const handleSearch = () => {
    updateParamsRef();
    fetchTableData({ current: 1 });
  };

  const handleShowEmployeeList = (item) => {
    createModal(ModalRoleRelateEmployeeList, {
      roleId: item.roleId,
      fetchData: fetchTableData,
      item,
    }).open();
  };

  const handleArchiveRole = (item) => {
    createModal(ModalRoleArchiveConfirm, { item, fetchData: fetchTableData }).open();
  };

  const updateParamsRef = () => {
    paramsRef.current = form.getFieldsValue();
  };

  return (
    <div className="employeeListJobs">
      <div className="m-title">
        <div className="title">{i18n.t('menu_Roles')}</div>
      </div>
      <div className="filterWrap">
        <Form form={form} layout="inline">
          <Form.Item name={'roleName'}>
            <Input
              prefix={<SearchIcon />}
              className="inputName"
              placeholder={i18n.t('roles_search_placeholder')}
            />
          </Form.Item>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            {i18n.t('search')}
          </Button>
        </Form>
        {checkPermission('EDIT_ROLE') && (
          <Button
            className="add-btn"
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              handleEdit();
            }}
          >
            {i18n.t('employeeList_pc_add_newjob')}
          </Button>
        )}
      </div>
      <div className="tableWrap">
        <Table
          columns={tableColumns}
          pagination={pager}
          dataSource={tableData.map((x, i) => ({ ...x, key: i }))}
          rowKey={'key'}
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
          locale={{ emptyText: TableEmptyContent }}
        />
      </div>
    </div>
  );
}
