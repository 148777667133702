import React, { useState } from 'react'
import { Table } from 'antd'
import { globalState } from '@/stores';
import classNames from 'classnames';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import './index.scss'
import { IParams, ITopStatsTable } from '../interface';
import { formatFullPrice, removeNullUndefinedFields } from '@/utils';
import { EReport_Status } from '@/types';
import { ApiExportProductMixTopItemReport } from '@/request/api';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { IDropMenuExportParams, IExportResult } from '@/Components/Export/interface';
import { getCookie } from '@/utils';

interface IProps {
  tableData: Array<ITopStatsTable>,
  title: string
  getParams?:  () => IParams
}

export default function TopSubMenusList (props: IProps) {
  const { i18n } = globalState;

  const [sortedInfo, setSortedInfo] = useState<SorterResult<ITopStatsTable>>({ columnKey: 'sales', order: 'descend' });

  const { tableData = [], title } = props;

  const tableColumns = [
    { title: i18n.t('pc_product_mix_name'), dataIndex: 'name', width: 250 },
    {
      title: i18n.t('pc_product_mix_quantity'), dataIndex: 'actualQuantity', key: 'actualQuantity', width: 100,
      sorter: true, sortOrder: sortedInfo.columnKey === 'actualQuantity' ? sortedInfo.order : null, sortDirections: ['descend', 'descend', 'descend']
    },
    {
      title: i18n.t('pc_product_mix_sales'), dataIndex: 'sales', key: 'sales', width: 100, render: val => formatFullPrice(val),
      sorter: true, sortOrder: sortedInfo.columnKey === 'sales' ? sortedInfo.order : null, sortDirections: ['descend', 'descend', 'descend']
    },
  ]

  const handleTableChange = (_, __, sorter) => {
    setSortedInfo(sorter);
  }

  const getSortFn = (a, b) => {
    const { columnKey, order } = sortedInfo
    // 如果没有指定排序顺序，则按照名称的字母顺序进行比较
    try {
      if (!order) return a.name.localeCompare(b.name);
      if (columnKey === 'quantity' && a.quantity === b.quantity) return a.name.localeCompare(b.name);
      if (columnKey === 'sales' && a.sales === b.sales) return a.name.localeCompare(b.name);
      if (order === 'ascend') return a[columnKey as keyof typeof a] - b[columnKey as keyof typeof b]
      if (order === 'descend') return b[columnKey as keyof typeof b] - a[columnKey as keyof typeof a]
    } catch (error) {
      console.log(error);
      return null
    }
  }


  const getRequestParams = () => {
    const _params = props.getParams()
    return {
      beginTime: _params.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: _params.date[1].format('YYYY-MM-DD 23:59:59')
    }
  }

  // 导出相关
  const fetchApiExport = async (exportType: IDropMenuExportParams) => {
    const params = {
      ...getRequestParams(),
      exportType: EReport_Status[exportType],
    }

    const res: any = await ApiExportProductMixTopItemReport(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult;
  }

  return (
    <div className="top-sub-menus-list">
      <div className="top-sub-menus-list-header-wrap">
        <div className="top-sub-menus-list-title-wrap">
          <div>{title}</div>
        </div>
        {
          props?.getParams && getCookie('appCode') !== '1' &&
          <PepprExportComponent
            type='icon'
            isDropDown={true}
            fetchApiExport={exportType => fetchApiExport(exportType)}
          />
        }
      </div>
      <div className={ classNames('top-sub-menus-list-table-wrap', { 'no-data': tableData.length === 0 }) }>
        <Table
          columns={ tableColumns as ColumnsType }
          onChange={ handleTableChange }
          dataSource={ tableData.sort(getSortFn).map((x, i) => ({ ...x, key: `${ x.name }${ i }` })) }
          rowKey={ 'key' }
          pagination={ false }
        />
      </div>
    </div>
  )
}