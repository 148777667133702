import './index.scss'
import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { Tabs, TabsProps } from 'antd';
import GuestDisplay from './component/guestDisplay';
import OrderScreen from './component/orderScreen';
import ShiftScreen from './component/shiftScreen';


export default function UiOptionalConfig () {
  const { i18n } = globalState;

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: 'orderScreen',
      label: i18n.t('dashboard_front_of_house_order_screen'),
      children: <OrderScreen />,
    },
    {
      key: 'shiftScreen',
      label: 'Shift Screen',
      children: <ShiftScreen />,
    },
    {
      key: 'guestFaceDisplay',
      label: i18n.t('guest_display'),
      children: <GuestDisplay />,
    },

  ];

  return (
    <div className='uiOptionalConfig'>
      <Tabs defaultActiveKey="orderScreen" items={items} onChange={onChange} />
    </div>
  )
}
