import React, { useEffect, useState } from 'react';
import { Tabs, Result } from 'antd'
import { globalState } from '@/stores';
import './index.scss'
import { TAB_TYPE } from './enum'
import Pickup from './Pickup'
// import ThirdPartyOrdering from './ThirdPartyOrdering'
import store from '@/stores/userInfo';
import { checkPermission } from '@/utils/permissionUtils';
import Page403 from '@/Components/Page403';
import { reaction } from 'mobx';
export default function OnlineOrders () {
  const { i18n } = globalState;

  return (
    <div className="online-orders-wrap">
      <div className="m-title">
        <div className="title">{i18n.t('pc_online_orders_title')}</div>
      </div>
      <Pickup />
    </div>
  )
}