import React from 'react';
import { Result } from 'antd';

const Page403: React.FC = () => (
  <Result
    status='403'
    title='403'
    subTitle={
      <div>
        <p>You do not have access to any submenus.</p>
        <p>If you have any questions, please contact the restaurant administrator.</p>
      </div>
    }
  />
);

export default Page403;